import React from 'react';

/* Context */
import { useCharacterContext } from '../../contexts/characterContext';

/* Components */
import Board from '../common/Board.js';
import CharacterCard from './CharacterCard.js';
import CharacterCardAdder from './CharacterCardAdder.js';

const CharacterBoard = () => {
  const { characterState } = useCharacterContext();

  return (
    <Board>
      { characterState.characterData.map(character => (
        <CharacterCard
          key={character.id}
          character={character}
        />
      )) }
      <CharacterCardAdder />
    </Board>
  );
}

export default CharacterBoard;
