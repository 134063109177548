import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './summaryState.js';

/* Reducer */
import summaryReducer from './summaryReducer.js';

const SummaryContext = createContext();

export const SummaryProvider = (props) => {
  const [summaryState, summaryDispatch] = useReducer(summaryReducer, initialState);

  return (
    <SummaryContext.Provider value={{summaryState, summaryDispatch}}>
      {props.children}
    </SummaryContext.Provider>
  )
}

export const useSummaryContext = () => useContext(SummaryContext);
