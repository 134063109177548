import './Navbar.css';
import React from 'react';

/* Components */
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showNavbar, setShowNavbar] = React.useState(false);

  const toggleNavbar = (e) => {
    setShowNavbar(!showNavbar);
  }

  const closeNavbar = (e) => {
    setShowNavbar(false);
  }

  return (
    <header className="navbar">
      <nav>

        <div className="navbar-main">
          <span className="navbar-toggle" onClick={toggleNavbar}>
            <i className="fas fa-bars"></i>
          </span>

          <Link to="/" className="navbar-logo" onClick={closeNavbar}>genshin<br />planner</Link>
        </div>

        { showNavbar &&
        <ul className="navbar-contents">
          <Link to="/" className="navbar-links" onClick={closeNavbar}>
            <li>home</li>
          </Link>
          <Link to="/summary" className="navbar-links" onClick={closeNavbar}>
            <li>summary</li>
          </Link>
          <Link to="/characters" className="navbar-links" onClick={closeNavbar}>
            <li>characters</li>
          </Link>
          <Link to="/weapons" className="navbar-links" onClick={closeNavbar}>
            <li>weapons</li>
          </Link>
          <Link to="/settings" className="navbar-links" onClick={closeNavbar}>
            <li>settings</li>
          </Link>
        </ul> }

        { showNavbar &&
        <div className="navbar-shadow" onClick={closeNavbar}></div> }

      </nav>
    </header>
  );
}

export default Navbar;
