const CHARACTER_ASCENSION_EXCEPTIONS = {
  eula: {
    ASCENSION: {
      1: {
        ADVENTURE_RANK: 15,
        MORA: 20000,
        MATERIALS: [
          {
            MATERIAL: 'shivada_jade_sliver',
            QUANTITY: 1,
          },
          {
            MATERIAL: 'dandelion_seed',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'damaged_mask',
            QUANTITY: 3,
          },
        ],
      },
      2: {
        ADVENTURE_RANK: 25,
        MORA: 40000,
        MATERIALS: [
          {
            MATERIAL: 'shivada_jade_fragment',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'crystalline_bloom',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'dandelion_seed',
            QUANTITY: 10,
          },
          {
            MATERIAL: 'damaged_mask',
            QUANTITY: 15,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 60000,
        MATERIALS: [
          {
            MATERIAL: 'shivada_jade_fragment',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'crystalline_bloom',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'dandelion_seed',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'stained_mask',
            QUANTITY: 12,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 35,
        MORA: 80000,
        MATERIALS: [
          {
            MATERIAL: 'shivada_jade_chunk',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'crystalline_bloom',
            QUANTITY: 8,
          },
          {
            MATERIAL: 'dandelion_seed',
            QUANTITY: 30,
          },
          {
            MATERIAL: 'stained_mask',
            QUANTITY: 18,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 40,
        MORA: 100000,
        MATERIALS: [
          {
            MATERIAL: 'shivada_jade_chunk',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'crystalline_bloom',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'dandelion_seed',
            QUANTITY: 45,
          },
          {
            MATERIAL: 'ominous_mask',
            QUANTITY: 12,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 50,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'shivada_jade_gemstone',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'crystalline_bloom',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'dandelion_seed',
            QUANTITY: 60,
          },
          {
            MATERIAL: 'ominous_mask',
            QUANTITY: 24,
          },
        ],
      },
    },
  },
  huTao: {
    ASCENSION: {
      1: {
        ADVENTURE_RANK: 15,
        MORA: 20000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_sliver',
            QUANTITY: 1,
          },
          {
            MATERIAL: 'silk_flower',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'whopperflower_nectar',
            QUANTITY: 3,
          },
        ],
      },
      2: {
        ADVENTURE_RANK: 25,
        MORA: 40000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_fragment',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'silk_flower',
            QUANTITY: 10,
          },
          {
            MATERIAL: 'whopperflower_nectar',
            QUANTITY: 15,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 60000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_fragment',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'silk_flower',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'shimmering_nectar',
            QUANTITY: 12,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 35,
        MORA: 80000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_chunk',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 8,
          },
          {
            MATERIAL: 'silk_flower',
            QUANTITY: 30,
          },
          {
            MATERIAL: 'shimmering_nectar',
            QUANTITY: 18,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 40,
        MORA: 100000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_chunk',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'silk_flower',
            QUANTITY: 45,
          },
          {
            MATERIAL: 'energy_nectar',
            QUANTITY: 12,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 50,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_gemstone',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'silk_flower',
            QUANTITY: 60,
          },
          {
            MATERIAL: 'energy_nectar',
            QUANTITY: 24,
          },
        ],
      },
    },
  },
  travelerAnemo: {
    ASCENSION: {
      1: {
        ADVENTURE_RANK: 15,
        MORA: 20000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_sliver',
            QUANTITY: 1,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'damaged_mask',
            QUANTITY: 3,
          },
        ],
      },
      2: {
        ADVENTURE_RANK: 25,
        MORA: 40000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_fragment',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 10,
          },
          {
            MATERIAL: 'damaged_mask',
            QUANTITY: 15,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 60000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_fragment',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'stained_mask',
            QUANTITY: 12,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 35,
        MORA: 80000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_chunk',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 30,
          },
          {
            MATERIAL: 'stained_mask',
            QUANTITY: 18,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 40,
        MORA: 100000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_chunk',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 45,
          },
          {
            MATERIAL: 'ominous_mask',
            QUANTITY: 12,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 50,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_gemstone',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 60,
          },
          {
            MATERIAL: 'ominous_mask',
            QUANTITY: 24,
          },
        ],
      },
    },
  },
  travelerGeo: {
    ASCENSION: {
      1: {
        ADVENTURE_RANK: 15,
        MORA: 20000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_sliver',
            QUANTITY: 1,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'damaged_mask',
            QUANTITY: 3,
          },
        ],
      },
      2: {
        ADVENTURE_RANK: 25,
        MORA: 40000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_fragment',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 10,
          },
          {
            MATERIAL: 'damaged_mask',
            QUANTITY: 15,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 60000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_fragment',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'stained_mask',
            QUANTITY: 12,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 35,
        MORA: 80000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_chunk',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 30,
          },
          {
            MATERIAL: 'stained_mask',
            QUANTITY: 18,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 40,
        MORA: 100000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_chunk',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 45,
          },
          {
            MATERIAL: 'ominous_mask',
            QUANTITY: 12,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 50,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'brilliant_diamond_gemstone',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'windwheel_aster',
            QUANTITY: 60,
          },
          {
            MATERIAL: 'ominous_mask',
            QUANTITY: 24,
          },
        ],
      },
    },
  },
  xiao: {
    ASCENSION: {
      1: {
        ADVENTURE_RANK: 15,
        MORA: 20000,
        MATERIALS: [
          {
            MATERIAL: 'vayuda_turquoise_sliver',
            QUANTITY: 1,
          },
          {
            MATERIAL: 'qingxin',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'slime_condensate',
            QUANTITY: 3,
          },
        ],
      },
      2: {
        ADVENTURE_RANK: 25,
        MORA: 40000,
        MATERIALS: [
          {
            MATERIAL: 'vayuda_turquoise_fragment',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'qingxin',
            QUANTITY: 10,
          },
          {
            MATERIAL: 'slime_condensate',
            QUANTITY: 15,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 60000,
        MATERIALS: [
          {
            MATERIAL: 'vayuda_turquoise_fragment',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'qingxin',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'slime_secretions',
            QUANTITY: 12,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 35,
        MORA: 80000,
        MATERIALS: [
          {
            MATERIAL: 'vayuda_turquoise_chunk',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 8,
          },
          {
            MATERIAL: 'qingxin',
            QUANTITY: 30,
          },
          {
            MATERIAL: 'slime_secretions',
            QUANTITY: 18,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 40,
        MORA: 100000,
        MATERIALS: [
          {
            MATERIAL: 'vayuda_turquoise_chunk',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'qingxin',
            QUANTITY: 45,
          },
          {
            MATERIAL: 'slime_concentrate',
            QUANTITY: 12,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 50,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'vayuda_turquoise_gemstone',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'qingxin',
            QUANTITY: 60,
          },
          {
            MATERIAL: 'slime_concentrate',
            QUANTITY: 24,
          },
        ],
      },
    },
  },
  yanfei: {
    ASCENSION: {
      1: {
        ADVENTURE_RANK: 15,
        MORA: 20000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_sliver',
            QUANTITY: 1,
          },
          {
            MATERIAL: 'noctilucous_jade',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'treasure_hoarder_insignia',
            QUANTITY: 3,
          },
        ],
      },
      2: {
        ADVENTURE_RANK: 25,
        MORA: 40000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_fragment',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'noctilucous_jade',
            QUANTITY: 10,
          },
          {
            MATERIAL: 'treasure_hoarder_insignia',
            QUANTITY: 15,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 60000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_fragment',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'noctilucous_jade',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'silver_raven_insignia',
            QUANTITY: 12,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 35,
        MORA: 80000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_chunk',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 8,
          },
          {
            MATERIAL: 'noctilucous_jade',
            QUANTITY: 30,
          },
          {
            MATERIAL: 'silver_raven_insignia',
            QUANTITY: 18,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 40,
        MORA: 100000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_chunk',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'noctilucous_jade',
            QUANTITY: 45,
          },
          {
            MATERIAL: 'golden_raven_insignia',
            QUANTITY: 12,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 50,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'agnidus_agate_gemstone',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'juvenile_jade',
            QUANTITY: 20,
          },
          {
            MATERIAL: 'noctilucous_jade',
            QUANTITY: 60,
          },
          {
            MATERIAL: 'golden_raven_insignia',
            QUANTITY: 24,
          },
        ],
      },
    },
  },
}

export default CHARACTER_ASCENSION_EXCEPTIONS;
