import './WeaponsPage.css';

/* Components */
import { Link } from 'react-router-dom';
import Page from '../common/Page';
import WeaponBoard from './WeaponBoard.js';
import Button from '../input/Button';

const WeaponsPage = () => {
  return (
    <Page>
      <div className="weapons-page">
        <h1>Weapons</h1>
        <hr className="hr-fade" />
        <div className="weapons-nav-buttons row">
          <Link to="/summary">
            <Button>check summary</Button>
          </Link>
          <Link to="/characters">
            <Button>add characters</Button>
          </Link>
        </div>
        <WeaponBoard />
      </div>
    </Page>
  );
}

export default WeaponsPage
