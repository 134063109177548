/* Reducers */
import {
  addWeapon,
  removeWeapon,
  updateWeapon,
  completeWeaponTask,
  completeWeaponItem,
  updateDisplayFlag,
} from './weaponReducers.js';

const weaponReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "ADD_WEAPON":
      addWeapon(newState, action.payload);
      break;
    case "REMOVE_WEAPON":
      removeWeapon(newState, action.payload);
      break;
    case "UPDATE_WEAPON":
      updateWeapon(newState, action.payload);
      break;
    case "COMPLETE_TASK":
      completeWeaponTask(newState, action.payload);
      break;
    case "COMPLETE_ITEM":
      completeWeaponItem(newState, action.payload);
      break;
    case "UPDATE_DISPLAY_FLAG":
      updateDisplayFlag(newState, action.payload);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  localStorage.setItem("weaponState", JSON.stringify(newState));
  return newState;
}

export default weaponReducer;
