import './CharactersPage.css';

/* Components */
import { Link } from 'react-router-dom';
import Page from '../common/Page';
import CharacterBoard from './CharacterBoard.js';
import Button from '../input/Button';

const CharactersPage = () => {
  return (
    <Page>
      <div className="characters-page">
        <h1>Characters</h1>
        <hr className="hr-fade" />
        <div className="characters-nav-buttons row">
          <Link to="/summary">
            <Button>check summary</Button>
          </Link>
          <Link to="/weapons">
            <Button>add weapons</Button>
          </Link>
        </div>
        <CharacterBoard />
      </div>
    </Page>
  );
}

export default CharactersPage
