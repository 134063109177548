import './ItemDisplay.css';

/* Data */
import ITEM_DATA from '../../data/itemData';
import cabbage from '../../assets/img/cabbage.png';

/* Contexts */
import { useCharacterContext } from '../../contexts/characterContext';
import { useWeaponContext } from '../../contexts/weaponContext';

/* Components */
import Tooltip from '../common/Tooltip';

const ItemDisplay = ({item}) => {
  const { characterDispatch } = useCharacterContext();
  const { weaponDispatch } = useWeaponContext();

  const completeItem = () => {
    const action = {
      type: "COMPLETE_ITEM",
      payload: {
        taskGroupID: item.taskGroupID,
        taskGroupOwner: item.taskGroupOwner,
        taskID: item.taskID,
        itemID: item.id,
      },
    }

    switch (item.taskGroupType) {
      case "CHARACTER":
        characterDispatch(action);
        break;
      case "WEAPON":
        weaponDispatch(action);
        break;
      default:
        break;
    }
  }

  return (
    <div className="item-display" onClick={completeItem}>
      <img
        src={ITEM_DATA[item.id]?.image || cabbage}
        alt={item.id || "item"}
        width="48px"
        height="48px"
        loading="lazy"
      />
      <div className="item-amount">
        <p>x{item.quantity || 0}</p>
      </div>
      {/* <div className="item-tooltip">
        {ITEM_DATA[item.id]?.name || "Unknown Item"}
      </div> */}
      <Tooltip text={ITEM_DATA[item.id]?.name || "Unknown Item"} />
      { item.complete ?
      <div className="item-complete">
        <i className="fas fa-check"></i>
      </div> : null }
    </div>
  );
}

export default ItemDisplay;
