export const DAY_LIST = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const SHORT_DAY = {
  SUNDAY: "SUN",
  MONDAY: "MON",
  TUESDAY: "TUES",
  WEDNESDAY: "WED",
  THURSDAY: "THURS",
  FRIDAY: "FRI",
  SATURDAY: "SAT",
  ANY_DAY: "ANY DAY",
};
