import './App.css';
import './styles/animations.css';
import './styles/colors.css';
import './styles/interactions.css'
import './styles/layout.css';
import './styles/text.css';

/* Firebase */
import firebase from 'firebase/app';
import 'firebase/analytics';

/* React */
import React from 'react';

/* Contexts */
import { ThemeProvider } from './contexts/themeContext';
import { CharacterProvider } from './contexts/characterContext';
import { WeaponProvider } from './contexts/weaponContext';
import { SummaryProvider } from './contexts/summaryContext';

/* Components */
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer'

/* Pages */
import HomePage from './components/HomePage';
import SummaryPage from './components/summary/SummaryPage';
import CharactersPage from './components/character/CharactersPage';
import WeaponsPage from './components/weapon/WeaponsPage';
import SettingsPage from './components/settings/SettingsPage';

const firebaseConfig = {
  apiKey: "AIzaSyBEFzfafavEdl83e-InLydjihq3nMQnSbU",
  authDomain: "genshin-planner.firebaseapp.com",
  projectId: "genshin-planner",
  storageBucket: "genshin-planner.appspot.com",
  messagingSenderId: "568187184937",
  appId: "1:568187184937:web:f03f823b9a5670be3df938",
  measurementId: "G-SR7ZSGS9W3"
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

function App() {
  return (
    <ThemeProvider>
    <CharacterProvider>
    <WeaponProvider>
    <SummaryProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/summary" exact>
              <SummaryPage />
            </Route>
            <Route path="/characters" exact>
              <CharactersPage />
            </Route>
            <Route path="/weapons" exact>
              <WeaponsPage />
            </Route>
            <Route path="/settings" exact>
              <SettingsPage />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </SummaryProvider>
    </WeaponProvider>
    </CharacterProvider>
    </ThemeProvider>
  );
}

export default App;
