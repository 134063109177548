const CHARACTER_TALENT_COSTS = {
  2: {
    ADVENTURE_RANK: 25,
    resin: '20/run',
    MORA: 12500,
    MATERIALS: [
      {
        ALIAS: 'talentBook1',
        QUANTITY: 3,
      },
      {
        ALIAS: 'COMMON_MAT_1',
        QUANTITY: 6,
      },
    ],
  },
  3: {
    ADVENTURE_RANK: 30,
    resin: '20/run',
    MORA: 17500,
    MATERIALS: [
      {
        ALIAS: 'talentBook2',
        QUANTITY: 2,
      },
      {
        ALIAS: 'COMMON_MAT_2',
        QUANTITY: 3,
      },
    ],
  },
  4: {
    ADVENTURE_RANK: 30,
    resin: '20/run',
    MORA: 25000,
    MATERIALS: [
      {
        ALIAS: 'talentBook2',
        QUANTITY: 4,
      },
      {
        ALIAS: 'COMMON_MAT_2',
        QUANTITY: 4,
      },
    ],
  },
  5: {
    ADVENTURE_RANK: 35,
    resin: '20/run',
    MORA: 30000,
    MATERIALS: [
      {
        ALIAS: 'talentBook2',
        QUANTITY: 6,
      },
      {
        ALIAS: 'COMMON_MAT_2',
        QUANTITY: 6,
      },
    ],
  },
  6: {
    ADVENTURE_RANK: 35,
    resin: '20/run',
    MORA: 37500,
    MATERIALS: [
      {
        ALIAS: 'talentBook2',
        QUANTITY: 9,
      },
      {
        ALIAS: 'COMMON_MAT_2',
        QUANTITY: 9,
      },
    ],
  },
  7: {
    ADVENTURE_RANK: 40,
    resin: '20/run',
    MORA: 120000,
    MATERIALS: [
      {
        ALIAS: 'talentBook3',
        QUANTITY: 4,
      },
      {
        ALIAS: 'COMMON_MAT_3',
        QUANTITY: 4,
      },
      {
        ALIAS: 'weeklyBossMat',
        QUANTITY: 1,
      },
    ],
  },
  8: {
    ADVENTURE_RANK: 40,
    resin: '20/run',
    MORA: 260000,
    MATERIALS: [
      {
        ALIAS: 'talentBook3',
        QUANTITY: 6,
      },
      {
        ALIAS: 'COMMON_MAT_3',
        QUANTITY: 6,
      },
      {
        ALIAS: 'weeklyBossMat',
        QUANTITY: 1,
      },
    ],
  },
  9: {
    ADVENTURE_RANK: 50,
    resin: '20/run',
    MORA: 450000,
    MATERIALS: [
      {
        ALIAS: 'talentBook3',
        QUANTITY: 12,
      },
      {
        ALIAS: 'COMMON_MAT_3',
        QUANTITY: 9,
      },
      {
        ALIAS: 'weeklyBossMat',
        QUANTITY: 2,
      },
    ],
  },
  10: {
    ADVENTURE_RANK: 50,
    resin: '20/run',
    MORA: 700000,
    MATERIALS: [
      {
        ALIAS: 'talentBook3',
        QUANTITY: 16,
      },
      {
        ALIAS: 'COMMON_MAT_3',
        QUANTITY: 12,
      },
      {
        ALIAS: 'weeklyBossMat',
        QUANTITY: 2,
      },
      {
        ALIAS: 'crown',
        QUANTITY: 1,
      },
    ],
  },
}

export default CHARACTER_TALENT_COSTS
