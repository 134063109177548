/* Reducers */
import {
  toggleDayVisibility,
  showAllDays,
  hideAllDays,
  showTodayOnly,
  toggleTypeVisibility
} from './summaryReducers.js';

const summaryReducer = (state, action) => {
  const newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "TOGGLE_DAY_VISIBILITY":
      toggleDayVisibility(newState, action.payload);
      break;
    case "SHOW_ALL_DAYS":
      showAllDays(newState);
      break;
    case "HIDE_ALL_DAYS":
      hideAllDays(newState);
      break;
    case "SHOW_TODAY_ONLY":
      showTodayOnly(newState);
      break;
    case "TOGGLE_TYPE_VISIBILITY":
      toggleTypeVisibility(newState, action.payload);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default summaryReducer;
