/* Reducers */
import {
  addCharacter,
  removeCharacter,
  updateCharacter,
  completeCharacterTask,
  completeCharacterItem,
  updateDisplayFlag,
} from './characterReducers.js';

const characterReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "ADD_CHARACTER":
      addCharacter(newState, action.payload);
      break;
    case "REMOVE_CHARACTER":
      removeCharacter(newState, action.payload);
      break;
    case "UPDATE_CHARACTER":
      updateCharacter(newState, action.payload);
      break;
    case "COMPLETE_TASK":
      completeCharacterTask(newState, action.payload);
      break;
    case "COMPLETE_ITEM":
      completeCharacterItem(newState, action.payload);
      break;
    case "UPDATE_DISPLAY_FLAG":
      updateDisplayFlag(newState, action.payload);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  localStorage.setItem("characterState", JSON.stringify(newState));
  return newState;
}

export default characterReducer;


