import './CostDisplay.css';

const CostDisplay = ({name, image, amount}) => {
  return (
    <div className="cost-display capitalize">
      { image && <img src={image} alt={name} />}
      <p>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
    </div>
  )
}

export default CostDisplay;
