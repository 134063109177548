const CHARACTER_LEVEL_COSTS = {
  20: {
    ADVENTURE_RANK: 1,
    resin: '20/run',
    MORA: 24000,
    MATERIALS: [
      {
        ALIAS: 'xpBook3',
        QUANTITY: 6,
      },
      {
        ALIAS: 'cExp',
        QUANTITY: 175,
      },
    ],
  },
  40: {
    ADVENTURE_RANK: 15,
    resin: '20/run',
    MORA: 115600,
    MATERIALS: [
      {
        ALIAS: 'xpBook1',
        QUANTITY: 3,
      },
      {
        ALIAS: 'xpBook2',
        QUANTITY: 3,
      },
      {
        ALIAS: 'xpBook3',
        QUANTITY: 28,
      },
      {
        ALIAS: 'cExp',
        QUANTITY: 325,
      },
    ],
  },
  50: {
    ADVENTURE_RANK: 25,
    resin: '20/run',
    MORA: 115800,
    MATERIALS: [
      {
        ALIAS: 'xpBook1',
        QUANTITY: 4,
      },
      {
        ALIAS: 'xpBook2',
        QUANTITY: 3,
      },
      {
        ALIAS: 'xpBook3',
        QUANTITY: 28,
      },
      {
        ALIAS: 'cExp',
        QUANTITY: 100,
      },
    ],
  },
  60: {
    ADVENTURE_RANK: 30,
    resin: '20/run',
    MORA: 170800,
    MATERIALS: [
      {
        ALIAS: 'xpBook1',
        QUANTITY: 4,
      },
      {
        ALIAS: 'xpBook2',
        QUANTITY: 2,
      },
      {
        ALIAS: 'xpBook3',
        QUANTITY: 42,
      },
      {
        ALIAS: 'cExp',
        QUANTITY: 125,
      },
    ],
  },
  70: {
    ADVENTURE_RANK: 35,
    resin: '20/run',
    MORA: 239000,
    MATERIALS: [
      {
        ALIAS: 'xpBook1',
        QUANTITY: 1,
      },
      {
        ALIAS: 'xpBook2',
        QUANTITY: 3,
      },
      {
        ALIAS: 'xpBook3',
        QUANTITY: 59,
      },
    ],
  },
  80: {
    ADVENTURE_RANK: 40,
    resin: '20/run',
    MORA: 322200,
    MATERIALS: [
      {
        ALIAS: 'xpBook1',
        QUANTITY: 2,
      },
      {
        ALIAS: 'xpBook2',
        QUANTITY: 2,
      },
      {
        ALIAS: 'xpBook3',
        QUANTITY: 80,
      },
    ],
  },
  90: {
    ADVENTURE_RANK: 50,
    resin: '20/run',
    MORA: 684600,
    MATERIALS: [
      {
        ALIAS: 'xpBook1',
        QUANTITY: 3,
      },
      {
        ALIAS: 'xpBook3',
        QUANTITY: 171,
      },
      {
        ALIAS: 'cExp',
        QUANTITY: 125,
      },
    ],
  },
}

export default CHARACTER_LEVEL_COSTS
