import './WeaponCardAdder.css';
import React from 'react';

/* Data */
import WEAPON_DATA from '../../data/weaponData';
import CHARACTER_DATA from '../../data/characterData';

/* Context */
import { useWeaponContext } from '../../contexts/weaponContext';

/* Components */
import Card from '../common/Card';
import Select from '../input/Select';

const WeaponCardAdder = () => {
  const { weaponDispatch } = useWeaponContext();
  const [selectedWeaponOwner, setSelectedWeaponOwner] = React.useState("NONE");
  const [selectedWeaponType, setSelectedWeaponType] = React.useState("SELECT_WEAPON_TYPE");
  const [selectedWeapon, setSelectedWeapon] = React.useState("SELECT_WEAPON");

  const weaponIDList = [];
  for (const [key, value] of Object.entries(WEAPON_DATA)) {
    if (value.TYPE === selectedWeaponType)
      weaponIDList.push(key);
  }

  const handleWeaponOwnerChange = (e) => {
    const value = e.target.value
    if (value !== "NONE")
      setSelectedWeaponType(CHARACTER_DATA[value].weaponType)
    setSelectedWeaponOwner(value);
  }

  const handleWeaponTypeChange = (e) => {
    setSelectedWeaponType(e.target.value);
  }

  const handleWeaponChange = (e) => {
    setSelectedWeapon(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedWeapon === "SELECT_WEAPON") return;
    setSelectedWeaponOwner("NONE");
    setSelectedWeaponType("SELECT_WEAPON_TYPE");
    setSelectedWeapon("SELECT_WEAPON");
    weaponDispatch({type: "ADD_WEAPON", payload: {
      id: selectedWeapon,
      owner: selectedWeaponOwner,
    }});
  }

  return (
    <Card classNames={["weapon-card-adder weapon-card"]}>
      <h2>Add Weapon</h2>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="column">
          <Select
            value={selectedWeaponOwner}
            onChange={handleWeaponOwnerChange}
          >
            <option value="NONE">Select Weapon Owner (None)</option>
            { Object.keys(CHARACTER_DATA).map(id => (
              <option key={id} value={id}>
                {CHARACTER_DATA[id].name}
              </option>
            )) }
          </Select>
          <Select
            value={selectedWeaponType}
            onChange={handleWeaponTypeChange}
          >
            <option value="SELECT_WEAPON_TYPE">Select Weapon Type</option>
            <option value="SWORD">Sword</option>
            <option value="CLAYMORE">Claymore</option>
            <option value="POLEARM">Polearm</option>
            <option value="CATALYST">Catalyst</option>
            <option value="BOW">Bow</option>
          </Select>
          <Select
            value={selectedWeapon}
            onChange={handleWeaponChange}
          >
            <option value="SELECT_WEAPON">Select Weapon</option>
            { weaponIDList.map(id => (
              <option key={id} value={id}>
                {WEAPON_DATA[id].NAME}
              </option>
            )) }
          </Select>
        </div>
        <button className="clickable" type="submit">
          <i className="fas fa-plus"></i>
        </button>
        <div></div>
        <div></div>
      </form>
    </Card>
  );
}

export default WeaponCardAdder;
