import './Checkbox.css';

const Checkbox = ({text, state, setState}) => {
  return (
    <label className="checkbox clickable">
      <input type="checkbox" checked={state} onChange={() => setState?.(!state)} name={text}></input>
      <span className="box"><i className="fas fa-check"></i></span>
      { text && <p>{text}</p>}
    </label>
  );
}

export default Checkbox;
