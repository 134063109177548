//#region imports

/* Data */
import WEAPON_DATA from '../data/weaponData';
import WEAPON_ASCENSION_COSTS from '../data/costs/weaponAscensionCosts';
import WEAPON_LEVEL_COSTS from '../data/costs/weaponLevelCosts';

/* Helpers */
import {
  ENUM_TO_DISPLAY,
  NEXT_LEVEL,
  DOMAIN_DAYS,
  translateAlias,
  getMaterialListFromEntries,
} from './costUtils';
import createWeaponInput from '../dataStructures/weaponInput';
import createTaskGroup from '../dataStructures/taskGroup';
import createTask from '../dataStructures/task';

//#endregion

//#region top level reducers

export const addWeapon = (newState, payload) => {
  if (!Object.keys(WEAPON_DATA).includes(payload.id)) return;
  if (newState.weaponData.some(
    e => e.id === payload.id && e.owner === payload.owner)) return;

  newState.weaponData.push(
    createWeaponInput(payload.id, payload.owner));
  newState.taskGroups.push(createTaskGroup(
    payload.id,
    payload.owner,
    "WEAPON",
    WEAPON_DATA[payload.id].SHORT_NAME,
    []
  ));
}

export const removeWeapon = (newState, payload) => {
  if (!newState.weaponData.some(e => e.id === payload.id)) return;

  newState.weaponData = newState.weaponData.filter(e => e.id !== payload.id || e.owner !== payload.owner);
  newState.taskGroups = newState.taskGroups.filter(e => e.id !== payload.id || e.owner !== payload.owner);
}

export const updateWeapon = (newState, payload) => {
  const weapon = newState.weaponData.find(
    e => e.id === payload.id && e.owner === payload.owner);
  if (!weapon) return;

  weapon[payload.stat][payload.field] = payload.value;
  switch (payload.field) {
    case "current":
      if (weapon[payload.stat].target < payload.value)
        weapon[payload.stat].target = payload.value;
      break;
    case "target":
      if (weapon[payload.stat].current > payload.value)
        weapon[payload.stat].current = payload.value;
      break;
    default:
      break;
  }

  refreshWeaponTasks(newState, weapon);
}

export const completeWeaponTask = (newState, payload) => {
  const weapon = newState.weaponData.find(
    e => e.id === payload.id && e.owner === payload.owner);
  if (!weapon) return;

  switch (payload.updateSignal.stat) {
    case "ASCENSION":
      weapon.ascension.current = payload.updateSignal.value;
      break;
    case "LEVEL":
      weapon.level.current = payload.updateSignal.value;
      break;
    default:
      break;
  }

  refreshWeaponTasks(newState, weapon);
}

export const completeWeaponItem = (newState, payload) => {
  // NOT IMPLEMENTED
}

export const updateDisplayFlag = (newState, payload) => {
  newState.taskGroups.forEach(taskGroup => {
    if (taskGroup.id === payload.id && taskGroup.owner === payload.owner)
      taskGroup.displayFlag = payload.displayFlag;
  });
}

//#endregion

//#region weapon cost calculation

export const refreshWeaponTaskGroups = (newState) => {
  newState?.weaponData.forEach(weapon => {
    refreshWeaponTasks(newState, weapon);
  });
}

const refreshWeaponTasks = (newState, weapon) => {
  const taskGroup = newState.taskGroups.find(
    e => e.id === weapon.id && e.owner === weapon.owner);
  if (!taskGroup) return;

  // add series of tasks as needed
  let newTasks = [];
  if (weapon.ascension.target !== weapon.ascension.current)
    newTasks = newTasks.concat(getTasks(weapon, "ASCENSION"));
  if (weapon.level.target !== weapon.level.current)
    newTasks = newTasks.concat(getTasks(weapon, "LEVEL"));

  // add parent references to all task items
  newTasks.forEach(task => {
    task.items.forEach(item => {
      item.taskID = task.id;
      item.taskGroupID = weapon.id;
      item.taskGroupOwner = weapon.owner;
      item.taskGroupType = taskGroup.type;
    });
  });

  // assign newly created tasks to task group
  taskGroup.tasks = newTasks;
}

const getTasks = (weapon, taskType) => {
  // context setup
  let currentLevel = -1;
  let targetLevel = -1;
  switch (taskType) {
    case "ASCENSION":
      currentLevel = weapon.ascension.current;
      targetLevel  = weapon.ascension.target;
      break;
    case "LEVEL":
      currentLevel = weapon.level.current;
      targetLevel  = weapon.level.target;
      break;
    default:
      break;
  }

  // make combined task
  const tasks = [];
  tasks.push(getTask(
    weapon, taskType, currentLevel, targetLevel, ["COMBINED"]));

  // make individual tasks
  let i = currentLevel;
  while (i < targetLevel) {
    const flags = ["CONSECUTIVE"];
    if (i === currentLevel) flags.push("PRIORITY");
    tasks.push(getTask(
      weapon, taskType, i, NEXT_LEVEL[taskType][i], flags));
    i = NEXT_LEVEL[taskType][i];
  }

  return tasks;
}

const getTask = (
  weapon,
  taskType,
  currentLevel,
  targetLevel,
  displayFlags
) => {
  // context setup
  let dataFile = undefined;
  let talentDays = ["ANY_DAY"];
  switch (taskType) {
    case "ASCENSION":
      dataFile = WEAPON_ASCENSION_COSTS;
      talentDays = DOMAIN_DAYS.WEAPON[WEAPON_DATA[weapon.id].DOMAIN_MAT_TYPE];
      break;
    case "LEVEL":
      dataFile = WEAPON_LEVEL_COSTS;
      break;
    default:
      break;
  }

  // compile material entries from currentLevel to targetLevel
  const rarity = WEAPON_DATA[weapon.id]?.RARITY;
  let i = currentLevel;
  let adventureRank = 1;
  let mora = 0;
  const materialEntries = {};
  while (i < targetLevel) {
    const nextLevel = NEXT_LEVEL[taskType][i];
    adventureRank = dataFile[rarity][nextLevel].ADVENTURE_RANK;
    mora += dataFile[rarity][nextLevel].MORA;
    dataFile[rarity][nextLevel].MATERIALS.forEach(e => {
      const materialName = translateAlias(weapon, "WEAPON", e.ALIAS);
      materialName in materialEntries ?
      materialEntries[materialName] += e.QUANTITY :
      materialEntries[materialName] = e.QUANTITY;
    });
    i = nextLevel;
  }

  // convert material entries to material list
  const materials = getMaterialListFromEntries(materialEntries);

  // create task
  const task = createTask(
    `${weapon.id}_${taskType}_${currentLevel}_TO_${targetLevel}`,
    ENUM_TO_DISPLAY[taskType],
    `Level ${currentLevel} to ${targetLevel}`,
    adventureRank,
    mora,
    materials,
    talentDays,
    displayFlags,
    {
      stat: taskType,
      field: "CURRENT",
      value: targetLevel,
    }
  );

  return task;
}

//#endregion
