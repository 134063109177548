import WEAPON_DATA from '../data/weaponData.js';

const createWeaponInput = (id, owner) => {
  if (!Object.keys(WEAPON_DATA).includes(id)) return null;
  return {
    id: id,
    owner: owner,
    ascension: {
      current: 0,
      target: 0,
    },
    level: {
      current: 1,
      target: 1,
    },
  };
}

export default createWeaponInput;
