const initialState = {
  visibleDays: [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ],
  visibleTypes: ["CHARACTER", "WEAPON"],
};

export default initialState;
