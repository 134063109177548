const ELITE_MATERIAL_MAP = {
  HORN: {
    ELITE_MAT_1: 'heavy_horn',
    ELITE_MAT_2: 'black_bronze_horn',
    ELITE_MAT_3: 'black_crystal_horn',
  },
  LEY_LINE: {
    ELITE_MAT_1: 'dead_ley_line_branch',
    ELITE_MAT_2: 'dead_ley_line_leaves',
    ELITE_MAT_3: 'ley_line_sprouts',
  },
  BONE_SHARD: {
    ELITE_MAT_1: 'fragile_bone_shard',
    ELITE_MAT_2: 'sturdy_bone_shard',
    ELITE_MAT_3: 'fossilized_bone_shard',
  },
  MIST_GRASS: {
    ELITE_MAT_1: 'mist_grass_pollen',
    ELITE_MAT_2: 'mist_grass',
    ELITE_MAT_3: 'mist_grass_wick',
  },
  KNIFE: {
    ELITE_MAT_1: 'hunters_sacrificial_knife',
    ELITE_MAT_2: 'agents_sacrificial_knife',
    ELITE_MAT_3: 'inspectors_sacrificial_knife',
  },
  CHAOS: {
    ELITE_MAT_1: 'chaos_device',
    ELITE_MAT_2: 'chaos_circuit',
    ELITE_MAT_3: 'chaos_core',
  },
}

export default ELITE_MATERIAL_MAP
