import './HomePage.css'

/* Assets */
import genshinStar from '../assets/img/genshin_star.svg';

/* Data */
import {
  MY_EMAIL,
  CONTACT_EMAIL,
  PROFESSIONAL_EMAIL,
} from '../data/constants';

/* Components */
import Page from './common/Page';
import { Link } from 'react-router-dom';
import CharacterCard from './character/CharacterCard';
import WeaponCard from './weapon/WeaponCard';
import Button from './input/Button';

const HomePage = () => {
  return (
    <Page>
      <div className="home-page">
        <div className="page-block">
          <img
            className="genshin-planner-logo"
            src={genshinStar}
            alt="Genshin Star"
            width="96"
            height="96"
          />
          <h1>Genshin Planner</h1>
          <p>A small online planner to visualize resource costs for ascension, level, and talents in Genshin Impact.</p>
          <div className="row">
            <Link to="/characters">
              <Button>add characters</Button>
            </Link>
            <Link to="/weapons">
              <Button>add weapons</Button>
            </Link>
            <Link to="/summary">
              <Button>check summary</Button>
            </Link>
          </div>
        </div>
        <hr className="hr-fade" />
        <div className="page-block">
          <div className="column">
            <p>Genshin planner calculates the resource cost for your goals based on the input cards that you add.</p>
            <CharacterCard
              character={{
                id: "EXAMPLE_CHARACTER",
                ascension: {
                  current: 0,
                  target: 0,
                },
                level: {
                  current: 1,
                  target: 1,
                },
                attack: {
                  current: 1,
                  target: 1,
                },
                skill: {
                  current: 1,
                  target: 1,
                },
                burst: {
                  current: 1,
                  target: 1,
                },
              }}
            />
            <WeaponCard
              weapon={{
                id: "EXAMPLE_WEAPON",
                owner: "NONE",
                ascension: {
                  current: 0,
                  target: 0,
                },
                level: {
                  current: 1,
                  target: 1,
                },
              }}
            />
            <p>Image credits to <a
              href="https://genshin-impact.fandom.com/wiki/Genshin_Impact_Wiki"
              target="_blank"
              rel="noopener noreferrer">
              https://genshin-impact.fandom.com/wiki/Genshin_Impact_Wiki</a>
            </p>
          </div>
        </div>
        <hr className="hr-fade" />
        <div className="page-block">
          <p>Have some you want to tell me? Contact me at <a href={`mailto:${MY_EMAIL}`}>{MY_EMAIL}</a></p>
          <p>Have feedback or suggestions? Contact me at <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a></p>
          <p>Looking for 2022 summer SWE interns? Contact me at <a href={`mailto:${PROFESSIONAL_EMAIL}`}>{PROFESSIONAL_EMAIL}</a></p>
        </div>
      </div>
    </Page>
  );
}

export default HomePage
