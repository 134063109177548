const CHARACTER_ASCENSION_COSTS = {
  1: {
    ADVENTURE_RANK: 15,
    resin: '20/run',
    MORA: 20000,
    MATERIALS: [
      {
        ALIAS: 'sliver',
        QUANTITY: 1,
      },
      {
        ALIAS: 'localSpecialty',
        QUANTITY: 3,
      },
      {
        ALIAS: 'COMMON_MAT_1',
        QUANTITY: 3,
      },
    ],
  },
  2: {
    ADVENTURE_RANK: 25,
    resin: '20/run',
    MORA: 40000,
    MATERIALS: [
      {
        ALIAS: 'fragment',
        QUANTITY: 3,
      },
      {
        ALIAS: 'worldBossMat',
        QUANTITY: 2,
      },
      {
        ALIAS: 'localSpecialty',
        QUANTITY: 10,
      },
      {
        ALIAS: 'COMMON_MAT_1',
        QUANTITY: 15,
      },
    ],
  },
  3: {
    ADVENTURE_RANK: 30,
    resin: '20/run',
    MORA: 60000,
    MATERIALS: [
      {
        ALIAS: 'fragment',
        QUANTITY: 6,
      },
      {
        ALIAS: 'worldBossMat',
        QUANTITY: 4,
      },
      {
        ALIAS: 'localSpecialty',
        QUANTITY: 20,
      },
      {
        ALIAS: 'COMMON_MAT_2',
        QUANTITY: 12,
      },
    ],
  },
  4: {
    ADVENTURE_RANK: 35,
    resin: '20/run',
    MORA: 80000,
    MATERIALS: [
      {
        ALIAS: 'chunk',
        QUANTITY: 3,
      },
      {
        ALIAS: 'worldBossMat',
        QUANTITY: 8,
      },
      {
        ALIAS: 'localSpecialty',
        QUANTITY: 30,
      },
      {
        ALIAS: 'COMMON_MAT_2',
        QUANTITY: 18,
      },
    ],
  },
  5: {
    ADVENTURE_RANK: 40,
    resin: '20/run',
    MORA: 100000,
    MATERIALS: [
      {
        ALIAS: 'chunk',
        QUANTITY: 6,
      },
      {
        ALIAS: 'worldBossMat',
        QUANTITY: 12,
      },
      {
        ALIAS: 'localSpecialty',
        QUANTITY: 45,
      },
      {
        ALIAS: 'COMMON_MAT_3',
        QUANTITY: 12,
      },
    ],
  },
  6: {
    ADVENTURE_RANK: 50,
    resin: '20/run',
    MORA: 120000,
    MATERIALS: [
      {
        ALIAS: 'gemstone',
        QUANTITY: 6,
      },
      {
        ALIAS: 'worldBossMat',
        QUANTITY: 20,
      },
      {
        ALIAS: 'localSpecialty',
        QUANTITY: 60,
      },
      {
        ALIAS: 'COMMON_MAT_3',
        QUANTITY: 24,
      },
    ],
  },
}

export default CHARACTER_ASCENSION_COSTS
