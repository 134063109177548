import './Board.css';

const Board = ({children}) => {
  return (
    <div className="board">
      {children}
    </div>
  );
}

export default Board;
