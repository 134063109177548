import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './themeState.js';

/* Reducer */
import themeReducer from './themeReducer';

/* Helpers */
import { setTheme } from './themeReducers';

const ThemeContext = createContext();

export const ThemeProvider = (props) => {
  const savedState = JSON.parse(localStorage.getItem("themeState"));
  if (savedState) {
    setTheme(savedState, savedState.currentTheme);
  }
  else {
    setTheme(initialState, initialState.currentTheme);
  }
  const [themeState, themeDispatch] = useReducer(themeReducer, savedState || initialState);

  return (
    <ThemeContext.Provider value={{themeState, themeDispatch}}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => useContext(ThemeContext);
