const CHARACTER_TALENT_EXCEPTIONS = {
  travelerAnemo: {
    ATTACK: {
      2: {
        ADVENTURE_RANK: 25,
        MORA: 12500,
        MATERIALS: [
          {
            MATERIAL: 'teachings_of_freedom',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'divining_scroll',
            QUANTITY: 6,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 17500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_resistance',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 3,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 30,
        MORA: 25000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_ballad',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 4,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 35,
        MORA: 30000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_freedom',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 6,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 35,
        MORA: 37500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_resistance',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 9,
          },
        ],
      },
      7: {
        ADVENTURE_RANK: 40,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_ballad',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 1,
          },
        ],
      },
      8: {
        ADVENTURE_RANK: 40,
        MORA: 260000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_freedom',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 1,
          },
        ],
      },
      9: {
        ADVENTURE_RANK: 50,
        MORA: 450000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_resistance',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 2,
          },
        ],
      },
      10: {
        ADVENTURE_RANK: 50,
        MORA: 700000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_ballad',
            QUANTITY: 16,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'crown_of_insight',
            QUANTITY: 1,
          },
        ],
      },
    },
    SKILL: {
      2: {
        ADVENTURE_RANK: 25,
        MORA: 12500,
        MATERIALS: [
          {
            MATERIAL: 'teachings_of_freedom',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'divining_scroll',
            QUANTITY: 6,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 17500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_resistance',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 3,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 30,
        MORA: 25000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_ballad',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 4,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 35,
        MORA: 30000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_freedom',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 6,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 35,
        MORA: 37500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_resistance',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 9,
          },
        ],
      },
      7: {
        ADVENTURE_RANK: 40,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_ballad',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 1,
          },
        ],
      },
      8: {
        ADVENTURE_RANK: 40,
        MORA: 260000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_freedom',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 1,
          },
        ],
      },
      9: {
        ADVENTURE_RANK: 50,
        MORA: 450000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_resistance',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 2,
          },
        ],
      },
      10: {
        ADVENTURE_RANK: 50,
        MORA: 700000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_ballad',
            QUANTITY: 16,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'crown_of_insight',
            QUANTITY: 1,
          },
        ],
      },
    },
    BURST: {
      2: {
        ADVENTURE_RANK: 25,
        MORA: 12500,
        MATERIALS: [
          {
            MATERIAL: 'teachings_of_freedom',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'divining_scroll',
            QUANTITY: 6,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 17500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_resistance',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 3,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 30,
        MORA: 25000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_ballad',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 4,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 35,
        MORA: 30000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_freedom',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 6,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 35,
        MORA: 37500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_resistance',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 9,
          },
        ],
      },
      7: {
        ADVENTURE_RANK: 40,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_ballad',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 1,
          },
        ],
      },
      8: {
        ADVENTURE_RANK: 40,
        MORA: 260000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_freedom',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 1,
          },
        ],
      },
      9: {
        ADVENTURE_RANK: 50,
        MORA: 450000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_resistance',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 2,
          },
        ],
      },
      10: {
        ADVENTURE_RANK: 50,
        MORA: 700000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_ballad',
            QUANTITY: 16,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'crown_of_insight',
            QUANTITY: 1,
          },
        ],
      },
    },
  },
  travelerGeo: {
    ATTACK: {
      2: {
        ADVENTURE_RANK: 25,
        MORA: 12500,
        MATERIALS: [
          {
            MATERIAL: 'teachings_of_freedom',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'divining_scroll',
            QUANTITY: 6,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 17500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_resistance',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 3,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 30,
        MORA: 25000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_ballad',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 4,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 35,
        MORA: 30000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_freedom',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 6,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 35,
        MORA: 37500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_resistance',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'sealed_scroll',
            QUANTITY: 9,
          },
        ],
      },
      7: {
        ADVENTURE_RANK: 40,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_ballad',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 1,
          },
        ],
      },
      8: {
        ADVENTURE_RANK: 40,
        MORA: 260000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_freedom',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 1,
          },
        ],
      },
      9: {
        ADVENTURE_RANK: 50,
        MORA: 450000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_resistance',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 2,
          },
        ],
      },
      10: {
        ADVENTURE_RANK: 50,
        MORA: 700000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_ballad',
            QUANTITY: 16,
          },
          {
            MATERIAL: 'forbidden_curse_scroll',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'dvalins_sigh',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'crown_of_insight',
            QUANTITY: 1,
          },
        ],
      },
    },
    SKILL: {
      2: {
        ADVENTURE_RANK: 25,
        MORA: 12500,
        MATERIALS: [
          {
            MATERIAL: 'teachings_of_prosperity',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'firm_arrowhead',
            QUANTITY: 6,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 17500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_diligence',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'sharp_arrowhead',
            QUANTITY: 3,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 30,
        MORA: 25000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_gold',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'sharp_arrowhead',
            QUANTITY: 4,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 35,
        MORA: 30000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_prosperity',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'sharp_arrowhead',
            QUANTITY: 6,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 35,
        MORA: 37500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_diligence',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'sharp_arrowhead',
            QUANTITY: 9,
          },
        ],
      },
      7: {
        ADVENTURE_RANK: 40,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_gold',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'weathered_arrowhead',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'tail_of_boreas',
            QUANTITY: 1,
          },
        ],
      },
      8: {
        ADVENTURE_RANK: 40,
        MORA: 260000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_prosperity',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'weathered_arrowhead',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'tail_of_boreas',
            QUANTITY: 1,
          },
        ],
      },
      9: {
        ADVENTURE_RANK: 50,
        MORA: 450000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_diligence',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'weathered_arrowhead',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'tail_of_boreas',
            QUANTITY: 2,
          },
        ],
      },
      10: {
        ADVENTURE_RANK: 50,
        MORA: 700000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_gold',
            QUANTITY: 16,
          },
          {
            MATERIAL: 'weathered_arrowhead',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'tail_of_boreas',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'crown_of_insight',
            QUANTITY: 1,
          },
        ],
      },
    },
    BURST: {
      2: {
        ADVENTURE_RANK: 25,
        MORA: 12500,
        MATERIALS: [
          {
            MATERIAL: 'teachings_of_prosperity',
            QUANTITY: 3,
          },
          {
            MATERIAL: 'firm_arrowhead',
            QUANTITY: 6,
          },
        ],
      },
      3: {
        ADVENTURE_RANK: 30,
        MORA: 17500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_diligence',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'sharp_arrowhead',
            QUANTITY: 3,
          },
        ],
      },
      4: {
        ADVENTURE_RANK: 30,
        MORA: 25000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_gold',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'sharp_arrowhead',
            QUANTITY: 4,
          },
        ],
      },
      5: {
        ADVENTURE_RANK: 35,
        MORA: 30000,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_prosperity',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'sharp_arrowhead',
            QUANTITY: 6,
          },
        ],
      },
      6: {
        ADVENTURE_RANK: 35,
        MORA: 37500,
        MATERIALS: [
          {
            MATERIAL: 'guide_to_diligence',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'sharp_arrowhead',
            QUANTITY: 9,
          },
        ],
      },
      7: {
        ADVENTURE_RANK: 40,
        MORA: 120000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_gold',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'weathered_arrowhead',
            QUANTITY: 4,
          },
          {
            MATERIAL: 'tail_of_boreas',
            QUANTITY: 1,
          },
        ],
      },
      8: {
        ADVENTURE_RANK: 40,
        MORA: 260000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_prosperity',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'weathered_arrowhead',
            QUANTITY: 6,
          },
          {
            MATERIAL: 'tail_of_boreas',
            QUANTITY: 1,
          },
        ],
      },
      9: {
        ADVENTURE_RANK: 50,
        MORA: 450000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_diligence',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'weathered_arrowhead',
            QUANTITY: 9,
          },
          {
            MATERIAL: 'tail_of_boreas',
            QUANTITY: 2,
          },
        ],
      },
      10: {
        ADVENTURE_RANK: 50,
        MORA: 700000,
        MATERIALS: [
          {
            MATERIAL: 'philosophies_of_gold',
            QUANTITY: 16,
          },
          {
            MATERIAL: 'weathered_arrowhead',
            QUANTITY: 12,
          },
          {
            MATERIAL: 'tail_of_boreas',
            QUANTITY: 2,
          },
          {
            MATERIAL: 'crown_of_insight',
            QUANTITY: 1,
          },
        ],
      },
    },
  },
}

export default CHARACTER_TALENT_EXCEPTIONS;
