const TALENT_BOOK_MATERIAL_MAP = {
  freedom: {
    talentBook1: 'teachings_of_freedom',
    talentBook2: 'guide_to_freedom',
    talentBook3: 'philosophies_of_freedom',
  },
  resistance: {
    talentBook1: 'teachings_of_resistance',
    talentBook2: 'guide_to_resistance',
    talentBook3: 'philosophies_of_resistance',
  },
  ballad: {
    talentBook1: 'teachings_of_ballad',
    talentBook2: 'guide_to_ballad',
    talentBook3: 'philosophies_of_ballad',
  },
  prosperity: {
    talentBook1: 'teachings_of_prosperity',
    talentBook2: 'guide_to_prosperity',
    talentBook3: 'philosophies_of_prosperity',
  },
  diligence: {
    talentBook1: 'teachings_of_diligence',
    talentBook2: 'guide_to_diligence',
    talentBook3: 'philosophies_of_diligence',
  },
  gold: {
    talentBook1: 'teachings_of_gold',
    talentBook2: 'guide_to_gold',
    talentBook3: 'philosophies_of_gold',
  },
}

export default TALENT_BOOK_MATERIAL_MAP
