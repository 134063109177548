const WORLD_BOSS_MATERIAL_MAP = {
  anemo: {
    sliver: 'vayuda_turquoise_sliver',
    fragment: 'vayuda_turquoise_fragment',
    chunk: 'vayuda_turquoise_chunk',
    gemstone: 'vayuda_turquoise_gemstone',
    worldBossMat: 'hurricane_seed',
  },
  cryo: {
    sliver: 'shivada_jade_sliver',
    fragment: 'shivada_jade_fragment',
    chunk: 'shivada_jade_chunk',
    gemstone: 'shivada_jade_gemstone',
    worldBossMat: 'hoarfrost_core',
  },
  electro: {
    sliver: 'vajrada_amethyst_sliver',
    fragment: 'vajrada_amethyst_fragment',
    chunk: 'vajrada_amethyst_chunk',
    gemstone: 'vajrada_amethyst_gemstone',
    worldBossMat: 'lightning_prism',
  },
  geo: {
    sliver: 'prithiva_topaz_sliver',
    fragment: 'prithiva_topaz_fragment',
    chunk: 'prithiva_topaz_chunk',
    gemstone: 'prithiva_topaz_gemstone',
    worldBossMat: 'basalt_pillar',
  },
  hydro: {
    sliver: 'varunada_lazurite_sliver',
    fragment: 'varunada_lazurite_fragment',
    chunk: 'varunada_lazurite_chunk',
    gemstone: 'varunada_lazurite_gemstone',
    worldBossMat: 'cleansing_heart',
  },
  pyro: {
    sliver: 'agnidus_agate_sliver',
    fragment: 'agnidus_agate_fragment',
    chunk: 'agnidus_agate_chunk',
    gemstone: 'agnidus_agate_gemstone',
    worldBossMat: 'everflame_seed',
  },
  traveler: {
    sliver: 'brilliant_diamond_sliver',
    fragment: 'brilliant_diamond_fragment',
    chunk: 'brilliant_diamond_chunk',
    gemstone: 'brilliant_diamond_gemstone',
    worldBossMat: 'none',
  },
  xiao: {
    sliver: 'vayuda_turquoise_sliver',
    fragment: 'vayuda_turquoise_fragment',
    chunk: 'vayuda_turquoise_chunk',
    gemstone: 'vayuda_turquoise_gemstone',
    worldBossMat: 'juvenile_jade',
  },
}

export default WORLD_BOSS_MATERIAL_MAP
