import './Tooltip.css';

const Tooltip = ({text}) => {
  return (
    <div className="tooltip">
      <div className="tooltip-text">
        {text}
      </div>
    </div>
  )
}

export default Tooltip;
