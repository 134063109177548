/* Reducers */
import { setTheme } from './themeReducers.js';

const themeReducer = (state, action) => {
  const newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "SET_THEME":
      setTheme(newState, action.payload);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  localStorage.setItem("themeState", JSON.stringify(newState));
  return newState;
}

export default themeReducer;
