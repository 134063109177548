/* Data */
import { DAY_LIST } from './summaryUtils';

export const toggleDayVisibility = (newState, day) => {
  let newVisibleDays = [...newState.visibleDays];
  if (newVisibleDays.includes(day)) {
    newVisibleDays = newVisibleDays.filter(e => e !== day);
  }
  else {
    newVisibleDays.push(day);
  }
  newState.visibleDays = newVisibleDays;
}

export const showAllDays = (newState) => {
  newState.visibleDays = [...DAY_LIST];
}

export const hideAllDays = (newState) => {
  newState.visibleDays = [];
}

export const showTodayOnly = (newState) => {
  let newVisibleDays = []
  const date = new Date();
  newVisibleDays.push(DAY_LIST[date.getDay()]);
  newState.visibleDays = newVisibleDays;
}

export const toggleTypeVisibility = (newState, type) => {
  let newVisibleTypes = [...newState.visibleTypes];
  if (newVisibleTypes.includes(type)) {
    newVisibleTypes = newVisibleTypes.filter(e => e !== type);
  }
  else {
    newVisibleTypes.push(type);
  }
  newState.visibleTypes = newVisibleTypes;
}
