import './SummaryGroupHeader.css';
import { useState } from 'react';

/* Data */
import CHARACTER_DATA from '../../data/characterData';
import WEAPON_DATA from '../../data/weaponData';

/* Contexts */
import { useCharacterContext } from '../../contexts/characterContext.js';
import { useWeaponContext } from '../../contexts/weaponContext.js';

/* Components */
import ToggleSwitch from '../input/ToggleSwitch';
import Tooltip from '../common/Tooltip';

const SummaryGroupHeader = ({taskGroup, allowCompletion, setAllowCompletion}) => {
  const [showOptions, setShowOptions] = useState(false);
  const {characterDispatch} = useCharacterContext();
  const {weaponDispatch} = useWeaponContext();

  const setDisplayFlag = (displayFlag) => {
    const action = {
      type: "UPDATE_DISPLAY_FLAG",
      payload: {
        id: taskGroup.id,
        owner: taskGroup.owner,
        displayFlag,
      }
    }

    switch (taskGroup.type) {
      case "CHARACTER":
        characterDispatch(action);
        break;
      case "WEAPON":
        weaponDispatch(action);
        break;
      default:
        break;
    }
  }

  let shortTitle = undefined;
  let title = undefined;
  let image = undefined;
  switch (taskGroup.type) {
    case "CHARACTER":
      shortTitle = CHARACTER_DATA[taskGroup.id]?.name;
      title = CHARACTER_DATA[taskGroup.id]?.name;
      image = CHARACTER_DATA[taskGroup.id]?.portrait;
      break;
    case "WEAPON":
      shortTitle = taskGroup.owner === "NONE" ?
      WEAPON_DATA[taskGroup.id]?.NAME :
      WEAPON_DATA[taskGroup.id]?.SHORT_NAME;
      title = WEAPON_DATA[taskGroup.id]?.NAME;
      image = WEAPON_DATA[taskGroup.id]?.IMAGE;
      break;
    default:
      break;
  }

  return (
    <div className="summary-group-header">
      <div className="summary-group-header-main">
        <img
          src={image}
          alt={taskGroup.shortTitle}
          loading="lazy"
          width="64px"
          height="64px"
        />
        { taskGroup.owner in CHARACTER_DATA ?
        <h2>{`${CHARACTER_DATA[taskGroup.owner].name}'s ${shortTitle}`}
          {/* <Tooltip text={`${CHARACTER_DATA[taskGroup.owner].name}'s ${title}`} /> */}
        </h2> :
        <h2>{title}</h2> }
        <i className="fas fa-cog clickable" onClick={() => setShowOptions(!showOptions)}></i>
      </div>
      { showOptions &&
      <div className="summary-group-header-options column">
        <div className="column">
          <h3>Display Options</h3>
          <ToggleSwitch
            text="Combine tasks"
            state={taskGroup.displayFlag === "COMBINED"}
            setState={() => setDisplayFlag("COMBINED")}
          />
          <ToggleSwitch
            text="Show first task in series"
            state={taskGroup.displayFlag === "PRIORITY"}
            setState={() => setDisplayFlag("PRIORITY")}
          />
          <ToggleSwitch
            text="Show all tasks in series"
            state={taskGroup.displayFlag === "CONSECUTIVE"}
            setState={() => setDisplayFlag("CONSECUTIVE")}
          />
        </div>
        <div className="column">
          <h3>Editing Options</h3>
          <ToggleSwitch
            text="Allow completing tasks"
            state={allowCompletion}
            setState={setAllowCompletion}
          />
        </div>
      </div> }
    </div>
  );
}

export default SummaryGroupHeader;
