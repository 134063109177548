//#region imports

/* Data */
import CHARACTER_DATA from '../data/characterData.js';
import CHARACTER_ASCENSION_COSTS from '../data/costs/characterAscensionCosts.js';
import CHARACTER_ASCENSION_EXCEPTIONS from '../data/exceptions/characterAscensionExceptions.js';
import CHARACTER_LEVEL_COSTS from '../data/costs/characterLevelCosts.js';
import CHARACTER_TALENT_COSTS from '../data/costs/characterTalentCosts.js';
import CHARACTER_TALENT_EXCEPTIONS from '../data/exceptions/characterTalentExceptions';

/* Helpers */
import {
  ENUM_TO_DISPLAY,
  NEXT_LEVEL,
  TALENT_DAYS,
  translateAlias,
  getMaterialListFromEntries,
} from './costUtils';
import createCharacterInput from '../dataStructures/characterInput.js';
import createTaskGroup from '../dataStructures/taskGroup.js';
import createTask from '../dataStructures/task.js';

//#endregion

//#region top level reducers

export const addCharacter = (newState, payload) => {
  if (newState.characterData.map(e => e.id).includes(payload.id)) return;
  if (!Object.keys(CHARACTER_DATA).includes(payload.id)) return;

  newState.characterData.push(
    createCharacterInput(payload.id));
  newState.taskGroups.push(createTaskGroup(
    payload.id,
    "SELF",
    "CHARACTER",
    CHARACTER_DATA[payload.id].NAME,
    []
  ));
}

export const removeCharacter = (newState, payload) => {
  if (!newState.characterData.some(e => e.id === payload.id)) return;

  newState.characterData = newState.characterData.filter(e => e.id !== payload.id);
  newState.taskGroups = newState.taskGroups.filter(e => e.id !== payload.id);
}

export const updateCharacter = (newState, payload) => {
  const character = newState.characterData.find(
    e => e.id === payload.id);
  if (!character) return;

  character[payload.stat][payload.field] = payload.value;
  switch (payload.field) {
    case "current":
      if (character[payload.stat].target < payload.value)
        character[payload.stat].target = payload.value;
      break;
    case "target":
      if (character[payload.stat].current > payload.value)
        character[payload.stat].current = payload.value;
      break;
    default:
      break;
  }

  refreshCharacterTasks(newState, character);
}

export const completeCharacterTask = (newState, payload) => {
  const character = newState.characterData.find(
    e => e.id === payload.id);
  if (!character) return;

  switch (payload.updateSignal.stat) {
    case "ASCENSION":
      character.ascension.current = payload.updateSignal.value;
      break;
    case "LEVEL":
      character.level.current = payload.updateSignal.value;
      break;
    case "ATTACK":
      character.attack.current = payload.updateSignal.value;
      break;
    case "SKILL":
      character.skill.current = payload.updateSignal.value;
      break;
    case "BURST":
      character.burst.current = payload.updateSignal.value;
      break;
    default:
      break;
  }

  refreshCharacterTasks(newState, character);
}

export const completeCharacterItem = (newState, payload) => {
  // NOT IMPLEMENTED
}

export const updateDisplayFlag = (newState, payload) => {
  newState.taskGroups.forEach(taskGroup => {
    if (taskGroup.id === payload.id)
      taskGroup.displayFlag = payload.displayFlag;
  });
}

//#endregion

//#region character cost calculation

export const refreshCharacterTaskGroups = (newState) => {
  newState?.characterData.forEach(character => {
    refreshCharacterTasks(newState, character);
  });
}

const refreshCharacterTasks = (newState, character) => {
  const taskGroup = newState.taskGroups.find(
    e => e.id === character.id);
  if (!taskGroup) return;

  // add series of tasks as needed
  let newTasks = [];
  if (character.ascension.target !== character.ascension.current)
    newTasks = newTasks.concat(getTasks(character, "ASCENSION"));
  if (character.level.target !== character.level.current)
    newTasks = newTasks.concat(getTasks(character, "LEVEL"));
  if (character.attack.target !== character.attack.current)
    newTasks = newTasks.concat(getTasks(character, "ATTACK"));
  if (character.skill.target !== character.skill.current)
    newTasks = newTasks.concat(getTasks(character, "SKILL"));
  if (character.burst.target !== character.burst.current)
    newTasks = newTasks.concat(getTasks(character, "BURST"));

  // add parent references to all task items
  newTasks.forEach(task => {
    task.items.forEach(item => {
      item.taskID = task.id;
      item.taskGroupID = character.id;
      item.taskGroupOwner = "SELF";
      item.taskGroupType = taskGroup.type;
    });
  });

  // assign newly created tasks to task group
  taskGroup.tasks = newTasks;
}

const getTasks = (character, taskType) => {
  // context setup
  let currentLevel = -1;
  let targetLevel = -1;
  let isException = false;
  switch (taskType) {
    case "ASCENSION":
      currentLevel = character.ascension.current;
      targetLevel  = character.ascension.target;
      isException = character.id in CHARACTER_ASCENSION_EXCEPTIONS;
      break;
    case "LEVEL":
      currentLevel = character.level.current;
      targetLevel  = character.level.target;
      break;
    case "ATTACK":
      currentLevel = character.attack.current;
      targetLevel  = character.attack.target;
      isException = character.id in CHARACTER_TALENT_EXCEPTIONS;
      break;
    case "SKILL":
      currentLevel = character.skill.current;
      targetLevel  = character.skill.target;
      isException = character.id in CHARACTER_TALENT_EXCEPTIONS;
      break;
    case "BURST":
      currentLevel = character.burst.current;
      targetLevel  = character.burst.target;
      isException = character.id in CHARACTER_TALENT_EXCEPTIONS;
      break;
    default:
      break;
  }

  // make combined task
  const tasks = [];
  tasks.push(getTask(
    character, taskType, currentLevel, targetLevel, ["COMBINED"], isException));

  // make individual tasks
  let i = currentLevel;
  while (i < targetLevel) {
    const flags = ["CONSECUTIVE"];
    if (i === currentLevel) flags.push("PRIORITY");
    tasks.push(getTask(
      character, taskType, i, NEXT_LEVEL[taskType][i], flags, isException));
    i = NEXT_LEVEL[taskType][i];
  }

  return tasks;
}

const getTask = (
  character,
  taskType,
  currentLevel,
  targetLevel,
  displayFlags,
  isException
) => {
  // context setup
  let dataFile = undefined;
  let talentDays = ["ANY_DAY"];
  switch (taskType) {
    case "ASCENSION":
      isException ?
      dataFile = CHARACTER_ASCENSION_EXCEPTIONS :
      dataFile = CHARACTER_ASCENSION_COSTS;
      break;
    case "LEVEL":
      dataFile = CHARACTER_LEVEL_COSTS;
      break;
    case "ATTACK":
    case "SKILL":
    case "BURST":
      isException ?
      dataFile = CHARACTER_TALENT_EXCEPTIONS :
      dataFile = CHARACTER_TALENT_COSTS;
      isException ?
      talentDays = ["ANY_DAY"] :
      talentDays = TALENT_DAYS[CHARACTER_DATA[character.id].talentBook];
      break;
    default:
      break;
  }

  // compile material entries from currentLevel to targetLevel
  let i = currentLevel;
  let adventureRank = 1;
  let mora = 0;
  const materialEntries = {};
  while (i < targetLevel) {
    const nextLevel = NEXT_LEVEL[taskType][i];
    if (isException) {
      adventureRank = dataFile[character.id][taskType][nextLevel].ADVENTURE_RANK;
      mora += dataFile[character.id][taskType][nextLevel].MORA;
      dataFile[character.id][taskType][nextLevel].MATERIALS.forEach(e => {
        const materialName = e.MATERIAL;
        materialName in materialEntries ?
        materialEntries[materialName] += e.QUANTITY :
        materialEntries[materialName] = e.QUANTITY;
      });
    }
    else {
      adventureRank = dataFile[nextLevel].ADVENTURE_RANK;
      mora += dataFile[nextLevel].MORA;
      dataFile[nextLevel].MATERIALS.forEach(e => {
        const materialName = translateAlias(character, "CHARACTER", e.ALIAS);
        materialName in materialEntries ?
        materialEntries[materialName] += e.QUANTITY :
        materialEntries[materialName] = e.QUANTITY;
      });
    }
    i = nextLevel;
  }

  // convert material entries to material list
  const materials = getMaterialListFromEntries(materialEntries);

  // create task
  const task = createTask(
    `${character.id}_${taskType}_${currentLevel}_TO_${targetLevel}`,
    ENUM_TO_DISPLAY[taskType],
    `Level ${currentLevel} to ${targetLevel}`,
    adventureRank,
    mora,
    materials,
    talentDays,
    displayFlags,
    {
      stat: taskType,
      field: "CURRENT",
      value: targetLevel,
    }
  );

  return task;
}
