import primordial_jade_cutter from '../assets/img/weapons/swords/primordial_jade_cutter.png';
import aquila_favonia from '../assets/img/weapons/swords/aquila_favonia.png';
import summit_shaper from '../assets/img/weapons/swords/summit_shaper.png';
import skyward_blade from '../assets/img/weapons/swords/skyward_blade.png';
import blackcliff_longsword from '../assets/img/weapons/swords/blackcliff_longsword.png';
import favonius_sword from '../assets/img/weapons/swords/favonius_sword.png';
import iron_sting from '../assets/img/weapons/swords/iron_sting.png';
import lions_roar from '../assets/img/weapons/swords/lions_roar.png';
import prototype_rancour from '../assets/img/weapons/swords/prototype_rancour.png';
import royal_longsword from '../assets/img/weapons/swords/royal_longsword.png';
import sacrificial_sword from '../assets/img/weapons/swords/sacrificial_sword.png';
import the_flute from '../assets/img/weapons/swords/the_flute.png';
import festering_desire from '../assets/img/weapons/swords/festering_desire.png';
import sword_of_descension from '../assets/img/weapons/swords/sword_of_descension.png';
import the_black_sword from '../assets/img/weapons/swords/the_black_sword.png';
import cool_steel from '../assets/img/weapons/swords/cool_steel.png';
import dark_iron_sword from '../assets/img/weapons/swords/dark_iron_sword.png';
import travelers_handy_sword from '../assets/img/weapons/swords/travelers_handy_sword.png';
import harbinger_of_dawn from '../assets/img/weapons/swords/harbinger_of_dawn.png';
import skyrider_sword from '../assets/img/weapons/swords/skyrider_sword.png';
import fillet_blade from '../assets/img/weapons/swords/fillet_blade.png';
import silver_sword from '../assets/img/weapons/swords/silver_sword.png';
import dull_blade from '../assets/img/weapons/swords/dull_blade.png';
import skyward_pride from '../assets/img/weapons/claymores/skyward_pride.png';
import the_unforged from '../assets/img/weapons/claymores/the_unforged.png';
import wolfs_gravestone from '../assets/img/weapons/claymores/wolfs_gravestone.png';
import whiteblind from '../assets/img/weapons/claymores/whiteblind.png';
import serpent_spine from '../assets/img/weapons/claymores/serpent_spine.png';
import blackcliff_slasher from '../assets/img/weapons/claymores/blackcliff_slasher.png';
import snow_tombed_starsilver from '../assets/img/weapons/claymores/snow_tombed_starsilver.png';
import sacrificial_greatsword from '../assets/img/weapons/claymores/sacrificial_greatsword.png';
import favonius_greatsword from '../assets/img/weapons/claymores/favonius_greatsword.png';
import prototype_archaic from '../assets/img/weapons/claymores/prototype_archaic.png';
import rainslasher from '../assets/img/weapons/claymores/rainslasher.png';
import royal_greatsword from '../assets/img/weapons/claymores/royal_greatsword.png';
import the_bell from '../assets/img/weapons/claymores/the_bell.png';
import lithic_blade from '../assets/img/weapons/claymores/lithic_blade.png';
import debate_club from '../assets/img/weapons/claymores/debate_club.png';
import white_iron_greatsword from '../assets/img/weapons/claymores/white_iron_greatsword.png';
import skyrider_greatsword from '../assets/img/weapons/claymores/skyrider_greatsword.png';
import ferrous_shadow from '../assets/img/weapons/claymores/ferrous_shadow.png';
import bloodtainted_greatsword from '../assets/img/weapons/claymores/bloodtainted_greatsword.png';
import old_mercs_pal from '../assets/img/weapons/claymores/old_mercs_pal.png';
import waster_greatsword from '../assets/img/weapons/claymores/waster_greatsword.png';
import staff_of_homa from '../assets/img/weapons/polearms/staff_of_homa.png';
import vortex_vanquisher from '../assets/img/weapons/polearms/vortex_vanquisher.png';
import skyward_spine from '../assets/img/weapons/polearms/skyward_spine.png';
import primordial_jade_winged_spear from '../assets/img/weapons/polearms/primordial_jade_winged_spear.png';
import lithic_spear from '../assets/img/weapons/polearms/lithic_spear.png';
import dragonspine_spear from '../assets/img/weapons/polearms/dragonspine_spear.png';
import royal_spear from '../assets/img/weapons/polearms/royal_spear.png';
import favonius_lance from '../assets/img/weapons/polearms/favonius_lance.png';
import crescent_pike from '../assets/img/weapons/polearms/crescent_pike.png';
import prototype_starglitter from '../assets/img/weapons/polearms/prototype_starglitter.png';
import dragons_bane from '../assets/img/weapons/polearms/dragons_bane.png';
import blackcliff_pole from '../assets/img/weapons/polearms/blackcliff_pole.png';
import deathmatch from '../assets/img/weapons/polearms/deathmatch.png';
import white_tassel from '../assets/img/weapons/polearms/white_tassel.png';
import halberd from '../assets/img/weapons/polearms/halberd.png';
import black_tassel from '../assets/img/weapons/polearms/black_tassel.png';
import iron_point from '../assets/img/weapons/polearms/iron_point.png';
import beginners_protector from '../assets/img/weapons/polearms/beginners_protector.png';
import lost_prayer_to_the_sacred_winds from '../assets/img/weapons/catalysts/lost_prayer_to_the_sacred_winds.png';
import memory_of_dust from '../assets/img/weapons/catalysts/memory_of_dust.png';
import skyward_atlas from '../assets/img/weapons/catalysts/skyward_atlas.png';
import solar_pearl from '../assets/img/weapons/catalysts/solar_pearl.png';
import eye_of_perception from '../assets/img/weapons/catalysts/eye_of_perception.png';
import the_widsith from '../assets/img/weapons/catalysts/the_widsith.png';
import frostbearer from '../assets/img/weapons/catalysts/frostbearer.png';
import sacrificial_fragments from '../assets/img/weapons/catalysts/sacrificial_fragments.png';
import royal_grimoire from '../assets/img/weapons/catalysts/royal_grimoire.png';
import prototype_amber from '../assets/img/weapons/catalysts/prototype_amber.png';
import mappa_mare from '../assets/img/weapons/catalysts/mappa_mare.png';
import favonius_codex from '../assets/img/weapons/catalysts/favonius_codex.png';
import blackcliff_agate from '../assets/img/weapons/catalysts/blackcliff_agate.png';
import magic_guide from '../assets/img/weapons/catalysts/magic_guide.png';
import thrilling_tales_of_dragon_slayers from '../assets/img/weapons/catalysts/thrilling_tales_of_dragon_slayers.png';
import twin_nephrite from '../assets/img/weapons/catalysts/twin_nephrite.png';
import otherworldly_story from '../assets/img/weapons/catalysts/otherworldly_story.png';
import emerald_orb from '../assets/img/weapons/catalysts/emerald_orb.png';
import pocket_grimoire from '../assets/img/weapons/catalysts/pocket_grimoire.png';
import apprentices_notes from '../assets/img/weapons/catalysts/apprentices_notes.png';
import amos_bow from '../assets/img/weapons/bows/amos_bow.png';
import skyward_harp from '../assets/img/weapons/bows/skyward_harp.png';
import compound_bow from '../assets/img/weapons/bows/compound_bow.png';
import blackcliff_warbow from '../assets/img/weapons/bows/blackcliff_warbow.png';
import the_viridescent_hunt from '../assets/img/weapons/bows/the_viridescent_hunt.png';
import prototype_crescent from '../assets/img/weapons/bows/prototype_crescent.png';
import sacrificial_bow from '../assets/img/weapons/bows/sacrificial_bow.png';
import rust from '../assets/img/weapons/bows/rust.png';
import the_stringless from '../assets/img/weapons/bows/the_stringless.png';
import royal_bow from '../assets/img/weapons/bows/royal_bow.png';
import favonius_warbow from '../assets/img/weapons/bows/favonius_warbow.png';
import sharpshooters_oath from '../assets/img/weapons/bows/sharpshooters_oath.png';
import slingshot from '../assets/img/weapons/bows/slingshot.png';
import raven_bow from '../assets/img/weapons/bows/raven_bow.png';
import messenger from '../assets/img/weapons/bows/messenger.png';
import recurve_bow from '../assets/img/weapons/bows/recurve_bow.png';
import seasoned_hunters_bow from '../assets/img/weapons/bows/seasoned_hunters_bow.png';
import hunters_bow from '../assets/img/weapons/bows/hunters_bow.png';

const WEAPON_DATA = {
  PRIMORDIAL_JADE_CUTTER: {
    ID: 'PRIMORDIAL_JADE_CUTTER',
    NAME: 'Primordial Jade Cutter',
    SHORT_NAME: 'P.J.C.',
    RARITY: 5,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: primordial_jade_cutter,
  },
  AQUILA_FAVONIA: {
    ID: 'AQUILA_FAVONIA',
    NAME: 'Aquila Favonia',
    SHORT_NAME: 'A.F.',
    RARITY: 5,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: aquila_favonia,
  },
  SUMMIT_SHAPER: {
    ID: 'SUMMIT_SHAPER',
    NAME: 'Summit Shaper',
    SHORT_NAME: 'S.S.',
    RARITY: 5,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: summit_shaper,
  },
  SKYWARD_BLADE: {
    ID: 'SKYWARD_BLADE',
    NAME: 'Skyward Blade',
    SHORT_NAME: 'S.B.',
    RARITY: 5,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: skyward_blade,
  },
  BLACKCLIFF_LONGSWORD: {
    ID: 'BLACKCLIFF_LONGSWORD',
    NAME: 'Blackcliff Longsword',
    SHORT_NAME: 'B.L.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: blackcliff_longsword,
  },
  FAVONIUS_SWORD: {
    ID: 'FAVONIUS_SWORD',
    NAME: 'Favonius Sword',
    SHORT_NAME: 'F.S.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: favonius_sword,
  },
  IRON_STING: {
    ID: 'IRON_STING',
    NAME: 'Iron Sting',
    SHORT_NAME: 'I.S.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: iron_sting,
  },
  LIONS_ROAR: {
    ID: 'LIONS_ROAR',
    NAME: 'Lion\'s Roar',
    SHORT_NAME: 'L.R.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: lions_roar,
  },
  PROTOTYPE_RANCOUR: {
    ID: 'PROTOTYPE_RANCOUR',
    NAME: 'Prototype Rancour',
    SHORT_NAME: 'P.R.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: prototype_rancour,
  },
  ROYAL_LONGSWORD: {
    ID: 'ROYAL_LONGSWORD',
    NAME: 'Royal Longsword',
    SHORT_NAME: 'R.L.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: royal_longsword,
  },
  SACRIFICIAL_SWORD: {
    ID: 'SACRIFICIAL_SWORD',
    NAME: 'Sacrificial Sword',
    SHORT_NAME: 'S.S.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: sacrificial_sword,
  },
  THE_FLUTE: {
    ID: 'THE_FLUTE',
    NAME: 'The Flute',
    SHORT_NAME: 'T.F.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: the_flute,
  },
  FESTERING_DESIRE: {
    ID: 'FESTERING_DESIRE',
    NAME: 'Festering Desire',
    SHORT_NAME: 'F.D.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: festering_desire,
  },
  SWORD_OF_DESCENSION: {
    ID: 'SWORD_OF_DESCENSION',
    NAME: 'Sword of Descension',
    SHORT_NAME: 'S.o.D.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: sword_of_descension,
  },
  THE_BLACK_SWORD: {
    ID: 'THE_BLACK_SWORD',
    NAME: 'The Black Sword',
    SHORT_NAME: 'T.B.S.',
    RARITY: 4,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: the_black_sword,
  },
  COOL_STEEL: {
    ID: 'COOL_STEEL',
    NAME: 'Cool Steel',
    SHORT_NAME: 'C.S.',
    RARITY: 3,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: cool_steel,
  },
  DARK_IRON_SWORD: {
    ID: 'DARK_IRON_SWORD',
    NAME: 'Dark Iron Sword',
    SHORT_NAME: 'D.I.S.',
    RARITY: 3,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: dark_iron_sword,
  },
  TRAVELERS_HANDY_SWORD: {
    ID: 'TRAVELERS_HANDY_SWORD',
    NAME: 'Traveler\'s Handy Sword',
    SHORT_NAME: 'T.H.S.',
    RARITY: 3,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: travelers_handy_sword,
  },
  HARBINGER_OF_DAWN: {
    ID: 'HARBINGER_OF_DAWN',
    NAME: 'Harbinger of Dawn',
    SHORT_NAME: 'H.o.D.',
    RARITY: 3,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: harbinger_of_dawn,
  },
  SKYRIDER_SWORD: {
    ID: 'SKYRIDER_SWORD',
    NAME: 'Skyrider Sword',
    SHORT_NAME: 'S.S.',
    RARITY: 3,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: skyrider_sword,
  },
  FILLET_BLADE: {
    ID: 'FILLET_BLADE',
    NAME: 'Fillet Blade',
    SHORT_NAME: 'F.B.',
    RARITY: 3,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: fillet_blade,
  },
  SILVER_SWORD: {
    ID: 'SILVER_SWORD',
    NAME: 'Silver Sword',
    SHORT_NAME: 'S.S.',
    RARITY: 2,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: silver_sword,
  },
  DULL_BLADE: {
    ID: 'DULL_BLADE',
    NAME: 'Dull Blade',
    SHORT_NAME: 'D.B.',
    RARITY: 1,
    TYPE: 'SWORD',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: dull_blade,
  },
  SKYWARD_PRIDE: {
    ID: 'SKYWARD_PRIDE',
    NAME: 'Skyward Pride',
    SHORT_NAME: 'S.P.',
    RARITY: 5,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: skyward_pride,
  },
  THE_UNFORGED: {
    ID: 'THE_UNFORGED',
    NAME: 'The Unforged',
    SHORT_NAME: 'T.U.',
    RARITY: 5,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: the_unforged,
  },
  WOLFS_GRAVESTONE: {
    ID: 'WOLFS_GRAVESTONE',
    NAME: 'Wolf\'s Gravestone',
    SHORT_NAME: 'W.G.',
    RARITY: 5,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: wolfs_gravestone,
  },
  WHITEBLIND: {
    ID: 'WHITEBLIND',
    NAME: 'Whiteblind',
    SHORT_NAME: 'W.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: whiteblind,
  },
  SERPENT_SPINE: {
    ID: 'SERPENT_SPINE',
    NAME: 'Serpent Spine',
    SHORT_NAME: 'S.S.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: serpent_spine,
  },
  BLACKCLIFF_SLASHER: {
    ID: 'BLACKCLIFF_SLASHER',
    NAME: 'Blackcliff Slasher',
    SHORT_NAME: 'B.S.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: blackcliff_slasher,
  },
  SNOW_TOMBED_STARSILVER: {
    ID: 'SNOW_TOMBED_STARSILVER',
    NAME: 'Snow-Tombed Starsilver',
    SHORT_NAME: 'S.T.S.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: snow_tombed_starsilver,
  },
  SACRIFICIAL_GREATSWORD: {
    ID: 'SACRIFICIAL_GREATSWORD',
    NAME: 'Sacrificial Greatsword',
    SHORT_NAME: 'S.G.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: sacrificial_greatsword,
  },
  FAVONIUS_GREATSWORD: {
    ID: 'FAVONIUS_GREATSWORD',
    NAME: 'Favonius Greatsword',
    SHORT_NAME: 'F.G.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: favonius_greatsword,
  },
  PROTOTYPE_ARCHAIC: {
    ID: 'PROTOTYPE_ARCHAIC',
    NAME: 'Prototype Archaic',
    SHORT_NAME: 'P.A.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: prototype_archaic,
  },
  RAINSLASHER: {
    ID: 'RAINSLASHER',
    NAME: 'Rainslasher',
    SHORT_NAME: 'R.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: rainslasher,
  },
  ROYAL_GREATSWORD: {
    ID: 'ROYAL_GREATSWORD',
    NAME: 'Royal Greatsword',
    SHORT_NAME: 'R.G.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: royal_greatsword,
  },
  THE_BELL: {
    ID: 'THE_BELL',
    NAME: 'The Bell',
    SHORT_NAME: 'T.B.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: the_bell,
  },
  LITHIC_BLADE: {
    ID: 'LITHIC_BLADE',
    NAME: 'Lithic Blade',
    SHORT_NAME: 'L.B.',
    RARITY: 4,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: lithic_blade,
  },
  DEBATE_CLUB: {
    ID: 'DEBATE_CLUB',
    NAME: 'Debate Club',
    SHORT_NAME: 'D.C.',
    RARITY: 3,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: debate_club,
  },
  WHITE_IRON_GREATSWORD: {
    ID: 'WHITE_IRON_GREATSWORD',
    NAME: 'White Iron Greatsword',
    SHORT_NAME: 'W.I.G.',
    RARITY: 3,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: white_iron_greatsword,
  },
  SKYRIDER_GREATSWORD: {
    ID: 'SKYRIDER_GREATSWORD',
    NAME: 'Skyrider Greatsword',
    SHORT_NAME: 'S.G.',
    RARITY: 3,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: skyrider_greatsword,
  },
  FERROUS_SHADOW: {
    ID: 'FERROUS_SHADOW',
    NAME: 'Ferrous Shadow',
    SHORT_NAME: 'F.S.',
    RARITY: 3,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: ferrous_shadow,
  },
  BLOODTAINTED_GREATSWORD: {
    ID: 'BLOODTAINTED_GREATSWORD',
    NAME: 'Bloodtainted Greatsword',
    SHORT_NAME: 'B.G.',
    RARITY: 3,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: bloodtainted_greatsword,
  },
  OLD_MERCS_PAL: {
    ID: 'OLD_MERCS_PAL',
    NAME: 'Old Merc\'s Pal',
    SHORT_NAME: 'O.M.P.',
    RARITY: 2,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: old_mercs_pal,
  },
  WASTER_GREATSWORD: {
    ID: 'WASTER_GREATSWORD',
    NAME: 'Waster Greatsword',
    SHORT_NAME: 'W.G.',
    RARITY: 1,
    TYPE: 'CLAYMORE',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: waster_greatsword,
  },
  STAFF_OF_HOMA: {
    ID: 'STAFF_OF_HOMA',
    NAME: 'Staff of Homa',
    SHORT_NAME: 'S.o.H.',
    RARITY: 5,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: staff_of_homa,
  },
  VORTEX_VANQUISHER: {
    ID: 'VORTEX_VANQUISHER',
    NAME: 'Vortex Vanquisher',
    SHORT_NAME: 'V.V.',
    RARITY: 5,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: vortex_vanquisher,
  },
  SKYWARD_SPINE: {
    ID: 'SKYWARD_SPINE',
    NAME: 'Skyward Spine',
    SHORT_NAME: 'S.S.',
    RARITY: 5,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: skyward_spine,
  },
  PRIMORDIAL_JADE_WINGED_SPEAR: {
    ID: 'PRIMORDIAL_JADE_WINGED_SPEAR',
    NAME: 'Primordial Jade Winged-Spear',
    SHORT_NAME: 'P.J.W.S.',
    RARITY: 5,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: primordial_jade_winged_spear,
  },
  LITHIC_SPEAR: {
    ID: 'LITHIC_SPEAR',
    NAME: 'Lithic Spear',
    SHORT_NAME: 'L.S.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: lithic_spear,
  },
  DRAGONSPINE_SPEAR: {
    ID: 'DRAGONSPINE_SPEAR',
    NAME: 'Dragonspine Spear',
    SHORT_NAME: 'D.S.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: dragonspine_spear,
  },
  ROYAL_SPEAR: {
    ID: 'ROYAL_SPEAR',
    NAME: 'Royal Spear',
    SHORT_NAME: 'R.S.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: royal_spear,
  },
  FAVONIUS_LANCE: {
    ID: 'FAVONIUS_LANCE',
    NAME: 'Favonius Lance',
    SHORT_NAME: 'F.L.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: favonius_lance,
  },
  CRESCENT_PIKE: {
    ID: 'CRESCENT_PIKE',
    NAME: 'Crescent Pike',
    SHORT_NAME: 'C.P.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: crescent_pike,
  },
  PROTOTYPE_STARGLITTER: {
    ID: 'PROTOTYPE_STARGLITTER',
    NAME: 'Prototype Starglitter',
    SHORT_NAME: 'P.S.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: prototype_starglitter,
  },
  DRAGONS_BANE: {
    ID: 'DRAGONS_BANE',
    NAME: 'Dragon\'s Bane',
    SHORT_NAME: 'D.B.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: dragons_bane,
  },
  BLACKCLIFF_POLE: {
    ID: 'BLACKCLIFF_POLE',
    NAME: 'Blackcliff Pole',
    SHORT_NAME: 'B.P.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: blackcliff_pole,
  },
  DEATHMATCH: {
    ID: 'DEATHMATCH',
    NAME: 'Deathmatch',
    SHORT_NAME: 'D.',
    RARITY: 4,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: deathmatch,
  },
  WHITE_TASSEL: {
    ID: 'WHITE_TASSEL',
    NAME: 'White Tassel',
    SHORT_NAME: 'W.T.',
    RARITY: 3,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: white_tassel,
  },
  HALBERD: {
    ID: 'HALBERD',
    NAME: 'Halberd',
    SHORT_NAME: 'H.',
    RARITY: 3,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: halberd,
  },
  BLACK_TASSEL: {
    ID: 'BLACK_TASSEL',
    NAME: 'Black Tassel',
    SHORT_NAME: 'B.T.',
    RARITY: 3,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: black_tassel,
  },
  IRON_POINT: {
    ID: 'IRON_POINT',
    NAME: 'Iron Point',
    SHORT_NAME: 'I.P.',
    RARITY: 2,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: iron_point,
  },
  BEGINNERS_PROTECTOR: {
    ID: 'BEGINNERS_PROTECTOR',
    NAME: 'Beginner\'s Protector',
    SHORT_NAME: 'B.P.',
    RARITY: 1,
    TYPE: 'POLEARM',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: beginners_protector,
  },
  LOST_PRAYER_TO_THE_SACRED_WINDS: {
    ID: 'LOST_PRAYER_TO_THE_SACRED_WINDS',
    NAME: 'Lost Prayer to the Sacred Winds',
    SHORT_NAME: 'L.P.S.W',
    RARITY: 5,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: lost_prayer_to_the_sacred_winds,
  },
  MEMORY_OF_DUST: {
    ID: 'MEMORY_OF_DUST',
    NAME: 'Memory of Dust',
    SHORT_NAME: 'M.o.D.',
    RARITY: 5,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: memory_of_dust,
  },
  SKYWARD_ATLAS: {
    ID: 'SKYWARD_ATLAS',
    NAME: 'Skyward Atlas',
    SHORT_NAME: 'S.A.',
    RARITY: 5,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: skyward_atlas,
  },
  SOLAR_PEARL: {
    ID: 'SOLAR_PEARL',
    NAME: 'Solar Pearl',
    SHORT_NAME: 'S.P.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: solar_pearl,
  },
  EYE_OF_PERCEPTION: {
    ID: 'EYE_OF_PERCEPTION',
    NAME: 'Eye of Perception',
    SHORT_NAME: 'E.o.P.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: eye_of_perception,
  },
  THE_WIDSITH: {
    ID: 'THE_WIDSITH',
    NAME: 'The Widsith',
    SHORT_NAME: 'T.W.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: the_widsith,
  },
  FROSTBEARER: {
    ID: 'FROSTBEARER',
    NAME: 'Frostbearer',
    SHORT_NAME: 'F.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: frostbearer,
  },
  SACRIFICIAL_FRAGMENTS: {
    ID: 'SACRIFICIAL_FRAGMENTS',
    NAME: 'Sacrificial Fragments',
    SHORT_NAME: 'S.F.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: sacrificial_fragments,
  },
  ROYAL_GRIMOIRE: {
    ID: 'ROYAL_GRIMOIRE',
    NAME: 'Royal Grimoire',
    SHORT_NAME: 'R.G.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: royal_grimoire,
  },
  PROTOTYPE_AMBER: {
    ID: 'PROTOTYPE_AMBER',
    NAME: 'Prototype Amber',
    SHORT_NAME: 'P.A.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: prototype_amber,
  },
  MAPPA_MARE: {
    ID: 'MAPPA_MARE',
    NAME: 'Mappa Mare',
    SHORT_NAME: 'M.M.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: mappa_mare,
  },
  FAVONIUS_CODEX: {
    ID: 'FAVONIUS_CODEX',
    NAME: 'Favonius Codex',
    SHORT_NAME: 'F.C.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: favonius_codex,
  },
  BLACKCLIFF_AGATE: {
    ID: 'BLACKCLIFF_AGATE',
    NAME: 'Blackcliff Agate',
    SHORT_NAME: 'B.A.',
    RARITY: 4,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: blackcliff_agate,
  },
  MAGIC_GUIDE: {
    ID: 'MAGIC_GUIDE',
    NAME: 'Magic Guide',
    SHORT_NAME: 'M.G.',
    RARITY: 3,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: magic_guide,
  },
  THRILLING_TALES_OF_DRAGON_SLAYERS: {
    ID: 'THRILLING_TALES_OF_DRAGON_SLAYERS',
    NAME: 'Thrilling Tales of Dragon Slayers',
    SHORT_NAME: 'T.T.o.D.S.',
    RARITY: 3,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: thrilling_tales_of_dragon_slayers,
  },
  TWIN_NEPHRITE: {
    ID: 'TWIN_NEPHRITE',
    NAME: 'Twin Nephrite',
    SHORT_NAME: 'T.N.',
    RARITY: 3,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: twin_nephrite,
  },
  OTHERWORLDLY_STORY: {
    ID: 'OTHERWORLDLY_STORY',
    NAME: 'Otherworldly Story',
    SHORT_NAME: 'O.S.',
    RARITY: 3,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: otherworldly_story,
  },
  EMERALD_ORB: {
    ID: 'EMERALD_ORB',
    NAME: 'Emerald Orb',
    SHORT_NAME: 'E.O.',
    RARITY: 3,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: emerald_orb,
  },
  POCKET_GRIMOIRE: {
    ID: 'POCKET_GRIMOIRE',
    NAME: 'Pocket Grimoire',
    SHORT_NAME: 'P.G.',
    RARITY: 2,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: pocket_grimoire,
  },
  APPRENTICES_NOTES: {
    ID: 'APPRENTICES_NOTES',
    NAME: 'Apprentice\'s Notes',
    SHORT_NAME: 'A.N.',
    RARITY: 1,
    TYPE: 'CATALYST',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: apprentices_notes,
  },
  AMOS_BOW: {
    ID: 'AMOS_BOW',
    NAME: 'Amos\' Bow',
    SHORT_NAME: 'A.B.',
    RARITY: 5,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: amos_bow,
  },
  SKYWARD_HARP: {
    ID: 'SKYWARD_HARP',
    NAME: 'Skyward Harp',
    SHORT_NAME: 'S.H.',
    RARITY: 5,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: skyward_harp,
  },
  COMPOUND_BOW: {
    ID: 'COMPOUND_BOW',
    NAME: 'Compound Bow',
    SHORT_NAME: 'C.B.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'AEROSIDERITE',
    ELITE_MAT_TYPE: 'BONE_SHARD',
    COMMON_MAT_TYPE: 'FATUI',
    IMAGE: compound_bow,
  },
  BLACKCLIFF_WARBOW: {
    ID: 'BLACKCLIFF_WARBOW',
    NAME: 'Blackcliff Warbow',
    SHORT_NAME: 'B.W.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: blackcliff_warbow,
  },
  THE_VIRIDESCENT_HUNT: {
    ID: 'THE_VIRIDESCENT_HUNT',
    NAME: 'The Viridescent Hunt',
    SHORT_NAME: 'T.V.H.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: the_viridescent_hunt,
  },
  PROTOTYPE_CRESCENT: {
    ID: 'PROTOTYPE_CRESCENT',
    NAME: 'Prototype Crescent',
    SHORT_NAME: 'P.C.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: prototype_crescent,
  },
  SACRIFICIAL_BOW: {
    ID: 'SACRIFICIAL_BOW',
    NAME: 'Sacrificial Bow',
    SHORT_NAME: 'S.B.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: sacrificial_bow,
  },
  RUST: {
    ID: 'RUST',
    NAME: 'Rust',
    SHORT_NAME: 'R.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: rust,
  },
  THE_STRINGLESS: {
    ID: 'THE_STRINGLESS',
    NAME: 'The Stringless',
    SHORT_NAME: 'T.S.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: the_stringless,
  },
  ROYAL_BOW: {
    ID: 'ROYAL_BOW',
    NAME: 'Royal Bow',
    SHORT_NAME: 'R.B.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: royal_bow,
  },
  FAVONIUS_WARBOW: {
    ID: 'FAVONIUS_WARBOW',
    NAME: 'Favonius Warbow',
    SHORT_NAME: 'F.W.',
    RARITY: 4,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'NECTAR',
    IMAGE: favonius_warbow,
  },
  SHARPSHOOTERS_OATH: {
    ID: 'SHARPSHOOTERS_OATH',
    NAME: 'Sharpshooter\'s Oath',
    SHORT_NAME: 'S.O.',
    RARITY: 3,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'SLIME',
    IMAGE: sharpshooters_oath,
  },
  SLINGSHOT: {
    ID: 'SLINGSHOT',
    NAME: 'Slingshot',
    SHORT_NAME: 'S.',
    RARITY: 3,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'GUYUN',
    ELITE_MAT_TYPE: 'KNIFE',
    COMMON_MAT_TYPE: 'MASK',
    IMAGE: slingshot,
  },
  RAVEN_BOW: {
    ID: 'RAVEN_BOW',
    NAME: 'Raven Bow',
    SHORT_NAME: 'R.B.',
    RARITY: 3,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'DECARABIAN',
    ELITE_MAT_TYPE: 'HORN',
    COMMON_MAT_TYPE: 'ARROWHEAD',
    IMAGE: raven_bow,
  },
  MESSENGER: {
    ID: 'MESSENGER',
    NAME: 'Messsenger',
    SHORT_NAME: 'M.',
    RARITY: 3,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'ELIXIR',
    ELITE_MAT_TYPE: 'MIST_GRASS',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: messenger,
  },
  RECURVE_BOW: {
    ID: 'RECURVE_BOW',
    NAME: 'Recurve Bow',
    SHORT_NAME: 'R.B.',
    RARITY: 3,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'GLADIATOR',
    ELITE_MAT_TYPE: 'CHAOS',
    COMMON_MAT_TYPE: 'SCROLL',
    IMAGE: recurve_bow,
  },
  SEASONED_HUNTERS_BOW: {
    ID: 'SEASONED_HUNTERS_BOW',
    NAME: 'Seasoned Hunter\'s Bow',
    SHORT_NAME: 'S.H.B.',
    RARITY: 2,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: seasoned_hunters_bow,
  },
  HUNTERS_BOW: {
    ID: 'HUNTERS_BOW',
    NAME: 'Hunter\'s Bow',
    SHORT_NAME: 'H.B.',
    RARITY: 1,
    TYPE: 'BOW',
    DOMAIN_MAT_TYPE: 'TOOTH',
    ELITE_MAT_TYPE: 'LEY_LINE',
    COMMON_MAT_TYPE: 'TREASURE',
    IMAGE: hunters_bow,
  },
}

export default WEAPON_DATA
