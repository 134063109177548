import '../common/CardCommon.css';
import './CharacterCard.css';

/* Assets */
import cabbage from '../../assets/img/cabbage.png';

/* Data */
import CHARACTER_DATA from '../../data/characterData.js';

/* Contexts */
import { useCharacterContext } from '../../contexts/characterContext';

/* Components */
import Card from '../common/Card';
import AscensionInput from '../cardComponent/AscensionInput.js';
import LevelInput from '../cardComponent/LevelInput.js';
import TalentInput from '../cardComponent/TalentInput.js';

const CharacterCard = ({character}) => {
  const { characterDispatch } = useCharacterContext();

  const updateSelf = (stat, field, value) => {
    characterDispatch({type: "UPDATE_CHARACTER", payload: {
      id: character.id,
      stat: stat,
      field: field,
      value: value,
    }});
  }

  const removeSelf = () => {
    characterDispatch({type: "REMOVE_CHARACTER", payload: {
      id: character.id,
      owner: character.owner,
    }});
  }

  return (
    <Card classNames={["character-card"]}>
      <div className="input-card-header">
        <img
          src={CHARACTER_DATA[character.id]?.portrait || cabbage}
          alt={CHARACTER_DATA[character.id]?.name || "Cabbage"}
          loading="lazy"
        />
        <div><h2>{CHARACTER_DATA[character.id]?.name || "Character"}</h2></div>
        <button onClick={removeSelf}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <hr />
      <h3>Ascension</h3>
      <AscensionInput
        currentAscension={character.ascension.current}
        setCurrentAscension={(value) => {updateSelf("ascension", "current", value);}}
        targetAscension={character.ascension.target}
        setTargetAscension={(value) => {updateSelf("ascension", "target", value);}}
      />
      <h3>Level</h3>
      <LevelInput
        currentLevel={character.level.current}
        setCurrentLevel={(value) => {updateSelf("level", "current", value);}}
        targetLevel={character.level.target}
        setTargetLevel={(value) => {updateSelf("level", "target", value);}}
      />
      <h3>Talents</h3>
      <TalentInput
        currentAttack={character.attack.current}
        setCurrentAttack={(value) => {updateSelf("attack", "current", value);}}
        targetAttack={character.attack.target}
        setTargetAttack={(value) => {updateSelf("attack", "target", value);}}
        currentSkill={character.skill.current}
        setCurrentSkill={(value) => {updateSelf("skill", "current", value);}}
        targetSkill={character.skill.target}
        setTargetSkill={(value) => {updateSelf("skill", "target", value);}}
        currentBurst={character.burst.current}
        setCurrentBurst={(value) => {updateSelf("burst", "current", value);}}
        targetBurst={character.burst.target}
        setTargetBurst={(value) => {updateSelf("burst", "target", value);}}
      />
    </Card>
  );
}

export default CharacterCard;
