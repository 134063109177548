import './SummaryFilter.css';

/* Context */
import { useSummaryContext } from '../../contexts/summaryContext.js';

/* Components */
import DayPicker from './DayPicker';
import Button from '../input/Button';
import ToggleSwitch from '../input/ToggleSwitch';

const SummaryFilter = () => {
  const {summaryState, summaryDispatch} = useSummaryContext();

  const toggleDayVisibility = (day) => {
    summaryDispatch({type: "TOGGLE_DAY_VISIBILITY", payload: day});
  }

  const showAllDays = () => {
    summaryDispatch({type: "SHOW_ALL_DAYS"});
    return;
  }

  const hideAllDays = () => {
    summaryDispatch({type: "HIDE_ALL_DAYS"});
  }

  const showTodayOnly = () => {
    summaryDispatch({type: "SHOW_TODAY_ONLY"});
  }

  const toggleTypeVisibility = (type) => {
    summaryDispatch({type: "TOGGLE_TYPE_VISIBILITY", payload: type});
  }

  return (
    <div className="summary-filter column">
      <DayPicker
        days={summaryState.visibleDays}
        toggleDay={toggleDayVisibility}
      />
      <div className="row">
        <Button onClick={showAllDays}>show all days</Button>
        <Button onClick={hideAllDays}>hide all days</Button>
        <Button onClick={showTodayOnly}>show today only</Button>
      </div>
      <ToggleSwitch
        text="Show Characters"
        state={summaryState.visibleTypes.includes("CHARACTER")}
        setState={() => toggleTypeVisibility("CHARACTER")}
      />
      <ToggleSwitch
        text="Show Weapons"
        state={summaryState.visibleTypes.includes("WEAPON")}
        setState={() => toggleTypeVisibility("WEAPON")}
      />
    </div>
  );
}

export default SummaryFilter;
