import './SummaryGroup.css';
import React from 'react';

/* Contexts */
import { useCharacterContext } from '../../contexts/characterContext.js';
import { useWeaponContext } from '../../contexts/weaponContext.js';
import { useSummaryContext } from '../../contexts/summaryContext';

/* Components */
import SummaryGroupHeader from './SummaryGroupHeader.js';
import SummaryCard from './SummaryCard.js';

const SummaryGroup = ({taskGroup}) => {
  const { characterDispatch } = useCharacterContext();
  const { weaponDispatch } = useWeaponContext();
  const { summaryState } = useSummaryContext();
  const [allowCompletion, setAllowCompletion] = React.useState(true);

  const displayCards = () => {
    if (taskGroup.displayFlag === "CONSECUTIVE") {
      return displayCardsSpaciously();
    }
    else {
      return displayCardsCompactly();
    }
  }

  const displayCardsCompactly = () => {
    const cards = [];

    taskGroup.tasks.forEach(task => {
      let visible = false;
      task.days.forEach(day => {
        if (summaryState.visibleDays.includes(day)) visible = true;
      });
      if (task.days.includes("ANY_DAY")) visible = true;
      if (!visible) return;

      if (task.displayFlags.includes(taskGroup.displayFlag))
        cards.push(<SummaryCard key={task.id} task={task} allowCompletion={allowCompletion} completeTask={completeTask}/>)
    });

    return cards.length > 0 ? (
      <div className="summary-card-container">
        {cards}
      </div>
    ) : null;
  }

  const displayCardsSpaciously = () => {
    const cardCategories = [];
    let lastCategory = "";
    let cards = [];

    if (taskGroup.tasks.length > 0)
      lastCategory = taskGroup.tasks[0].title;

    taskGroup.tasks.forEach(task => {
      let visible = false;
      task.days.forEach(day => {
        if (summaryState.visibleDays.includes(day)) visible = true;
      });
      if (task.days.includes("ANY_DAY")) visible = true;
      if (!visible) return;

      if (task.title !== lastCategory) {
        cardCategories.push(
          <div key={lastCategory} className="summary-card-container">
            {cards}
          </div>
        );
        cardCategories.push(<hr key={`${lastCategory} hr`} className="hr-invisible" />);
        cards = [];
      }
      if (task.displayFlags.includes(taskGroup.displayFlag)) {
        if (cards.length > 0) {
          cards.push(<div key={cards.length.toString()} className="summary-progression-arrow">
            <i className="fas fa-angle-double-right"></i>
          </div>);
          cards.push(<div key={cards.length.toString()} className="summary-progression-arrow-mobile">
            <i className="fas fa-angle-double-down"></i>
          </div>);
        }
        cards.push(<SummaryCard key={task.id} task={task} allowCompletion={allowCompletion} completeTask={completeTask}/>)
      }
      lastCategory = task.title;
    });

    if (cards.length > 0) {
      cardCategories.push(
        <div key={lastCategory} className="summary-card-container">
          {cards}
        </div>
      );
    }

    return cards.length > 0 ? cardCategories : null;
  }

  const completeTask = (task) => {
    const action = {
      type: "COMPLETE_TASK",
      payload: {
        id: taskGroup.id,
        owner: taskGroup.owner,
        updateSignal: task.updateSignal,
      },
    };
    switch (taskGroup.type) {
      case "CHARACTER":
        characterDispatch(action);
        break;
      case "WEAPON":
        weaponDispatch(action);
        break;
      default:
        break;
    }
  }

  return (
    <div className="summary-group">
      <SummaryGroupHeader
        taskGroup={taskGroup}
        allowCompletion={allowCompletion}
        setAllowCompletion={setAllowCompletion}
      />
      { displayCards() }
    </div>
  );
}

export default SummaryGroup;
