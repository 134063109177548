import albedo from '../assets/img/characterPortraits/albedo.png';
import amber from '../assets/img/characterPortraits/amber.png';
import barbara from '../assets/img/characterPortraits/barbara.png';
import beidou from '../assets/img/characterPortraits/beidou.png';
import bennett from '../assets/img/characterPortraits/bennett.png';
import chongyun from '../assets/img/characterPortraits/chongyun.png';
import diluc from '../assets/img/characterPortraits/diluc.png';
import diona from '../assets/img/characterPortraits/diona.png';
import eula from '../assets/img/characterPortraits/eula.png';
import fischl from '../assets/img/characterPortraits/fischl.png';
import ganyu from '../assets/img/characterPortraits/ganyu.png';
import huTao from '../assets/img/characterPortraits/huTao.png';
import jean from '../assets/img/characterPortraits/jean.png';
import kaeya from '../assets/img/characterPortraits/kaeya.png';
import keqing from '../assets/img/characterPortraits/keqing.png';
import klee from '../assets/img/characterPortraits/klee.png';
import lisa from '../assets/img/characterPortraits/lisa.png';
import mona from '../assets/img/characterPortraits/mona.png';
import ningguang from '../assets/img/characterPortraits/ningguang.png';
import noelle from '../assets/img/characterPortraits/noelle.png';
import qiqi from '../assets/img/characterPortraits/qiqi.png';
import razor from '../assets/img/characterPortraits/razor.png';
import rosaria from '../assets/img/characterPortraits/rosaria.png';
import sucrose from '../assets/img/characterPortraits/sucrose.png';
import tartaglia from '../assets/img/characterPortraits/tartaglia.png';
import travelerAnemo from '../assets/img/characterPortraits/travelerAnemo.png';
import travelerGeo from '../assets/img/characterPortraits/travelerGeo.png';
import venti from '../assets/img/characterPortraits/venti.png';
import xiangling from '../assets/img/characterPortraits/xiangling.png';
import xiao from '../assets/img/characterPortraits/xiao.png';
import xingqiu from '../assets/img/characterPortraits/xingqiu.png';
import xinyan from '../assets/img/characterPortraits/xinyan.png';
import yanfei from '../assets/img/characterPortraits/yanfei.png';
import zhongli from '../assets/img/characterPortraits/zhongli.png';

const CHARACTER_DATA = {
  albedo: {
    id: 'albedo',
    name: 'Albedo',
    rarity: 5,
    element: 'geo',
    weaponType: 'SWORD',
    localSpecialty: 'cecilia',
    COMMON_MAT: 'SCROLL',
    talentBook: 'ballad',
    weeklyBossMat: 'tusk_of_monoceros_caeli',
    portrait: albedo,
  },
  amber: {
    id: 'amber',
    name: 'Amber',
    rarity: 4,
    element: 'pyro',
    weaponType: 'BOW',
    localSpecialty: 'small_lamp_grass',
    COMMON_MAT: 'ARROWHEAD',
    talentBook: 'freedom',
    weeklyBossMat: 'dvalins_sigh',
    portrait: amber,
  },
  barbara: {
    id: 'barbara',
    name: 'Barbara',
    rarity: 4,
    element: 'hydro',
    weaponType: 'CATALYST',
    localSpecialty: 'philanemo_mushroom',
    COMMON_MAT: 'SCROLL',
    talentBook: 'freedom',
    weeklyBossMat: 'ring_of_boreas',
    portrait: barbara,
  },
  beidou: {
    id: 'beidou',
    name: 'Beidou',
    rarity: 4,
    element: 'electro',
    weaponType: 'CLAYMORE',
    localSpecialty: 'noctilucous_jade',
    COMMON_MAT: 'TREASURE',
    talentBook: 'gold',
    weeklyBossMat: 'dvalins_sigh',
    portrait: beidou,
  },
  bennett: {
    id: 'bennett',
    name: 'Bennett',
    rarity: 4,
    element: 'pyro',
    weaponType: 'SWORD',
    localSpecialty: 'windwheel_aster',
    COMMON_MAT: 'TREASURE',
    talentBook: 'resistance',
    weeklyBossMat: 'dvalins_plume',
    portrait: bennett,
  },
  chongyun: {
    id: 'chongyun',
    name: 'Chongyun',
    rarity: 4,
    element: 'cryo',
    weaponType: 'CLAYMORE',
    localSpecialty: 'cor_lapis',
    COMMON_MAT: 'MASK',
    talentBook: 'diligence',
    weeklyBossMat: 'dvalins_sigh',
    portrait: chongyun,
  },
  diluc: {
    id: 'diluc',
    name: 'Diluc',
    rarity: 5,
    element: 'pyro',
    weaponType: 'CLAYMORE',
    localSpecialty: 'small_lamp_grass',
    COMMON_MAT: 'FATUI',
    talentBook: 'resistance',
    weeklyBossMat: 'dvalins_plume',
    portrait: diluc,
  },
  diona: {
    id: 'diona',
    name: 'Diona',
    rarity: 4,
    element: 'cryo',
    weaponType: 'BOW',
    localSpecialty: 'calla_lily',
    COMMON_MAT: 'ARROWHEAD',
    talentBook: 'freedom',
    weeklyBossMat: 'shard_of_a_foul_legacy',
    portrait: diona,
  },
  eula: {
    id: 'eula',
    name: 'Eula',
    rarity: 5,
    element: 'cryo',
    weaponType: 'CLAYMORE',
    localSpecialty: 'dandelion_seed',
    COMMON_MAT: 'MASK',
    talentBook: 'resistance',
    weeklyBossMat: 'dragon_lords_crown',
    portrait: eula,
  },
  fischl: {
    id: 'fischl',
    name: 'Fischl',
    rarity: 4,
    element: 'electro',
    weaponType: 'BOW',
    localSpecialty: 'small_lamp_grass',
    COMMON_MAT: 'ARROWHEAD',
    talentBook: 'ballad',
    weeklyBossMat: 'spirit_locket_of_boreas',
    portrait: fischl,
  },
  ganyu: {
    id: 'ganyu',
    name: 'Ganyu',
    rarity: 5,
    element: 'cryo',
    weaponType: 'BOW',
    localSpecialty: 'qingxin',
    COMMON_MAT: 'NECTAR',
    talentBook: 'diligence',
    weeklyBossMat: 'shadow_of_the_warrior',
    portrait: ganyu,
  },
  huTao: {
    id: 'huTao',
    name: 'Hu Tao',
    rarity: 5,
    element: 'pyro',
    weaponType: 'POLEARM',
    localSpecialty: 'silk_flower',
    COMMON_MAT: 'NECTAR',
    talentBook: 'diligence',
    weeklyBossMat: 'shard_of_a_foul_legacy',
    portrait: huTao,
  },
  jean: {
    id: 'jean',
    name: 'Jean',
    rarity: 5,
    element: 'anemo',
    weaponType: 'SWORD',
    localSpecialty: 'dandelion_seed',
    COMMON_MAT: 'MASK',
    talentBook: 'resistance',
    weeklyBossMat: 'dvalins_plume',
    portrait: jean,
  },
  kaeya: {
    id: 'kaeya',
    name: 'Kaeya',
    rarity: 4,
    element: 'cryo',
    weaponType: 'SWORD',
    localSpecialty: 'calla_lily',
    COMMON_MAT: 'TREASURE',
    talentBook: 'ballad',
    weeklyBossMat: 'spirit_locket_of_boreas',
    portrait: kaeya,
  },
  keqing: {
    id: 'keqing',
    name: 'Keqing',
    rarity: 5,
    element: 'electro',
    weaponType: 'SWORD',
    localSpecialty: 'cor_lapis',
    COMMON_MAT: 'NECTAR',
    talentBook: 'prosperity',
    weeklyBossMat: 'ring_of_boreas',
    portrait: keqing,
  },
  klee: {
    id: 'klee',
    name: 'Klee',
    rarity: 5,
    element: 'pyro',
    weaponType: 'CATALYST',
    localSpecialty: 'philanemo_mushroom',
    COMMON_MAT: 'SCROLL',
    talentBook: 'freedom',
    weeklyBossMat: 'ring_of_boreas',
    portrait: klee,
  },
  lisa: {
    id: 'lisa',
    name: 'Lisa',
    rarity: 4,
    element: 'electro',
    weaponType: 'CATALYST',
    localSpecialty: 'valberry',
    COMMON_MAT: 'SLIME',
    talentBook: 'ballad',
    weeklyBossMat: 'dvalins_claw',
    portrait: lisa,
  },
  mona: {
    id: 'mona',
    name: 'Mona',
    rarity: 5,
    element: 'hydro',
    weaponType: 'CATALYST',
    localSpecialty: 'philanemo_mushroom',
    COMMON_MAT: 'NECTAR',
    talentBook: 'resistance',
    weeklyBossMat: 'ring_of_boreas',
    portrait: mona,
  },
  ningguang: {
    id: 'ningguang',
    name: 'Ningguang',
    rarity: 4,
    element: 'geo',
    weaponType: 'CATALYST',
    localSpecialty: 'glaze_lily',
    COMMON_MAT: 'FATUI',
    talentBook: 'prosperity',
    weeklyBossMat: 'spirit_locket_of_boreas',
    portrait: ningguang,
  },
  noelle: {
    id: 'noelle',
    name: 'Noelle',
    rarity: 4,
    element: 'geo',
    weaponType: 'CLAYMORE',
    localSpecialty: 'valberry',
    COMMON_MAT: 'MASK',
    talentBook: 'resistance',
    weeklyBossMat: 'dvalins_claw',
    portrait: noelle,
  },
  qiqi: {
    id: 'qiqi',
    name: 'Qiqi',
    rarity: 5,
    element: 'cryo',
    weaponType: 'SWORD',
    localSpecialty: 'violetgrass',
    COMMON_MAT: 'SCROLL',
    talentBook: 'prosperity',
    weeklyBossMat: 'tail_of_boreas',
    portrait: qiqi,
  },
  razor: {
    id: 'razor',
    name: 'Razor',
    rarity: 4,
    element: 'electro',
    weaponType: 'CLAYMORE',
    localSpecialty: 'wolfhook',
    COMMON_MAT: 'MASK',
    talentBook: 'resistance',
    weeklyBossMat: 'dvalins_claw',
    portrait: razor,
  },
  rosaria: {
    id: 'rosaria',
    name: 'Rosaria',
    rarity: 4,
    element: 'cryo',
    weaponType: 'POLEARM',
    localSpecialty: 'valberry',
    COMMON_MAT: 'FATUI',
    talentBook: 'ballad',
    weeklyBossMat: 'shadow_of_the_warrior',
    portrait: rosaria,
  },
  sucrose: {
    id: 'sucrose',
    name: 'Sucrose',
    rarity: 4,
    element: 'anemo',
    weaponType: 'CATALYST',
    localSpecialty: 'windwheel_aster',
    COMMON_MAT: 'NECTAR',
    talentBook: 'freedom',
    weeklyBossMat: 'spirit_locket_of_boreas',
    portrait: sucrose,
  },
  tartaglia: {
    id: 'tartaglia',
    name: 'Tartaglia',
    rarity: 5,
    element: 'hydro',
    weaponType: 'BOW',
    localSpecialty: 'starconch',
    COMMON_MAT: 'FATUI',
    talentBook: 'freedom',
    weeklyBossMat: 'shard_of_a_foul_legacy',
    portrait: tartaglia,
  },
  travelerAnemo: {
    id: 'travelerAnemo',
    name: 'Traveler (A)',
    rarity: 5,
    element: 'anemo',
    weaponType: 'SWORD',
    localSpecialty: 'windwheel_aster',
    COMMON_MAT: 'MASK',
    talentBook: 'EXCEPTION',
    weeklyBossMat: 'EXCEPTION',
    portrait: travelerAnemo,
  },
  travelerGeo: {
    id: 'travelerGeo',
    name: 'Traveler (G)',
    rarity: 5,
    element: 'geo',
    weaponType: 'SWORD',
    localSpecialty: 'windwheel_aster',
    COMMON_MAT: 'MASK',
    talentBook: 'EXCEPTION',
    weeklyBossMat: 'EXCEPTION',
    portrait: travelerGeo,
  },
  venti: {
    id: 'venti',
    name: 'Venti',
    rarity: 5,
    element: 'anemo',
    weaponType: 'BOW',
    localSpecialty: 'cecilia',
    COMMON_MAT: 'SLIME',
    talentBook: 'ballad',
    weeklyBossMat: 'tail_of_boreas',
    portrait: venti,
  },
  xiangling: {
    id: 'xiangling',
    name: 'Xiangling',
    rarity: 4,
    element: 'pyro',
    weaponType: 'POLEARM',
    localSpecialty: 'jueyun_chili',
    COMMON_MAT: 'SLIME',
    talentBook: 'diligence',
    weeklyBossMat: 'dvalins_claw',
    portrait: xiangling,
  },
  xiao: {
    id: 'xiao',
    name: 'Xiao',
    rarity: 5,
    element: 'anemo',
    weaponType: 'POLEARM',
    localSpecialty: 'qingxin',
    COMMON_MAT: 'SLIME',
    talentBook: 'prosperity',
    weeklyBossMat: 'shadow_of_the_warrior',
    portrait: xiao,
  },
  xingqiu: {
    id: 'xingqiu',
    name: 'Xingqiu',
    rarity: 4,
    element: 'hydro',
    weaponType: 'SWORD',
    localSpecialty: 'silk_flower',
    COMMON_MAT: 'MASK',
    talentBook: 'gold',
    weeklyBossMat: 'tail_of_boreas',
    portrait: xingqiu,
  },
  xinyan: {
    id: 'xinyan',
    name: 'Xinyan',
    rarity: 4,
    element: 'pyro',
    weaponType: 'CLAYMORE',
    localSpecialty: 'violetgrass',
    COMMON_MAT: 'TREASURE',
    talentBook: 'gold',
    weeklyBossMat: 'tusk_of_monoceros_caeli',
    portrait: xinyan,
  },
  yanfei: {
    id: 'yanfei',
    name: 'Yanfei',
    rarity: 4,
    element: 'pyro',
    weaponType: 'CATALYST',
    localSpecialty: 'noctilucous_jade',
    COMMON_MAT: 'TREASURE',
    talentBook: 'gold',
    weeklyBossMat: 'bloodjade_branch',
    portrait: yanfei,
  },
  zhongli: {
    id: 'zhongli',
    name: 'Zhongli',
    rarity: 5,
    element: 'geo',
    weaponType: 'POLEARM',
    localSpecialty: 'cor_lapis',
    COMMON_MAT: 'SLIME',
    talentBook: 'gold',
    weeklyBossMat: 'tusk_of_monoceros_caeli',
    portrait: zhongli,
  },
}

export default CHARACTER_DATA
