import '../common/CardCommon.css';
import './CharacterCardAdder.css';
import React from 'react';

/* Data */
import CHARACTER_DATA from '../../data/characterData.js';

/* Context */
import { useCharacterContext } from '../../contexts/characterContext';

/* Components */
import Card from '../common/Card';
import Select from '../input/Select';

const CharacterCardAdder = () => {
  const { characterState, characterDispatch } = useCharacterContext();
  const [selectedCharacter, setSelectedCharacter] = React.useState("SELECT_CHARACTER");
  const addedCharacters = characterState.characterData.map(e => e.id);
  const characterList = Object.keys(CHARACTER_DATA).filter(e => 
    !addedCharacters.includes(e));

  const handleCharacterChange = (e) => {
    setSelectedCharacter(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCharacter === "SELECT_CHARACTER") return;
    setSelectedCharacter("SELECT_CHARACTER");
    characterDispatch({type: "ADD_CHARACTER", payload: {
      id: selectedCharacter,
    }});
  }

  return (
    <Card classNames={["weapon-card-adder character-card"]}>
      <h2>Add Character</h2>
      <hr />
      <form onSubmit={handleSubmit}>
        <Select
          value={selectedCharacter}
          onChange={handleCharacterChange}
        >
          <option value="SELECT_CHARACTER">Select Character</option>
          { characterList.map(id => (
            <option key={id} value={id}>
              {CHARACTER_DATA[id].name}
            </option>
          )) }
        </Select>
        <button className="clickable" type="submit">
          <i className="fas fa-plus"></i>
        </button>
        <div></div>
        <div></div>
      </form>
    </Card>
  );
}

export default CharacterCardAdder;
