import './AscensionInput.css';

/* Components */
import AscensionIndicator from './AscensionIndicator.js';

const AscensionInput = (props) => {
  return (
    <div className="ascension-input">
      <AscensionIndicator
        ascension={props.currentAscension}
        setAscension={props.setCurrentAscension}
      />
      <i className="fas fa-arrow-down"></i>
      <AscensionIndicator
        ascension={props.targetAscension}
        setAscension={props.setTargetAscension}
      />
    </div>
  );
}

export default AscensionInput;
