import './DayPicker.css';

/* Components */
import Checkbox from '../input/Checkbox';

const DayPicker = ({days, toggleDay}) => {
  return (
    <form className="day-picker">
      <div className="day-picker-day">
        <p>S</p>
        <Checkbox
          state={days.includes("SUNDAY") || false}
          setState={() => toggleDay("SUNDAY")}
        />
      </div>
      <div className="day-picker-day">
        <p>M</p>
        <Checkbox
          type="checkbox"
          state={days.includes("MONDAY") || false}
          setState={() => {toggleDay("MONDAY")}}
        />
      </div>
      <div className="day-picker-day">
        <p>T</p>
        <Checkbox
          type="checkbox"
          state={days.includes("TUESDAY") || false}
          setState={() => {toggleDay("TUESDAY")}}
        />
      </div>
      <div className="day-picker-day">
        <p>W</p>
        <Checkbox
          type="checkbox"
          state={days.includes("WEDNESDAY") || false}
          setState={() => {toggleDay("WEDNESDAY")}}
        />
      </div>
      <div className="day-picker-day">
        <p>T</p>
        <Checkbox
          type="checkbox"
          state={days.includes("THURSDAY") || false}
          setState={() => {toggleDay("THURSDAY")}}
        />
      </div>
      <div className="day-picker-day">
        <p>F</p>
        <Checkbox
          type="checkbox"
          state={days.includes("FRIDAY") || false}
          setState={() => {toggleDay("FRIDAY")}}
        />
      </div>
      <div className="day-picker-day">
        <p>S</p>
        <Checkbox
          type="checkbox"
          state={days.includes("SATURDAY") || false}
          setState={() => {toggleDay("SATURDAY")}}
        />
      </div>
    </form>
  );
}

export default DayPicker;
