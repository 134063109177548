const WEAPON_LEVEL_COSTS = {
  5: {
    20: {
      ADVENTURE_RANK: 1,
      MORA: 12160,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 12,
        },
      ],
    },
    40: {
      ADVENTURE_RANK: 15,
      MORA: 62280,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 62,
        },
      ],
    },
    50: {
      ADVENTURE_RANK: 25,
      MORA: 62840,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 62,
        },
      ],
    },
    60: {
      ADVENTURE_RANK: 30,
      MORA: 92800,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 5,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 92,
        },
      ],
    },
    70: {
      ADVENTURE_RANK: 35,
      MORA: 129920,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 129,
        },
      ],
    },
    80: {
      ADVENTURE_RANK: 40,
      MORA: 175040,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 175,
        },
      ],
    },
    90: {
      ADVENTURE_RANK: 50,
      MORA: 371480,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 371,
        },
      ],
    },
  },
  4: {
    20: {
      ADVENTURE_RANK: 1,
      MORA: 8120,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 8,
        },
      ],
    },
    40: {
      ADVENTURE_RANK: 15,
      MORA: 41520,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 41,
        },
      ],
    },
    50: {
      ADVENTURE_RANK: 25,
      MORA: 41880,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 41,
        },
      ],
    },
    60: {
      ADVENTURE_RANK: 30,
      MORA: 61840,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 61,
        },
      ],
    },
    70: {
      ADVENTURE_RANK: 35,
      MORA: 86640,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 86,
        },
      ],
    },
    80: {
      ADVENTURE_RANK: 40,
      MORA: 116720,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 116,
        },
      ],
    },
    90: {
      ADVENTURE_RANK: 50,
      MORA: 247680,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 247,
        },
      ],
    },
  },
  3: {
    20: {
      ADVENTURE_RANK: 1,
      MORA: 5360,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 5,
        },
      ],
    },
    40: {
      ADVENTURE_RANK: 15,
      MORA: 27400,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 27,
        },
      ],
    },
    50: {
      ADVENTURE_RANK: 25,
      MORA: 27640,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 27,
        },
      ],
    },
    60: {
      ADVENTURE_RANK: 30,
      MORA: 40840,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 40,
        },
      ],
    },
    70: {
      ADVENTURE_RANK: 35,
      MORA: 57200,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 5,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 57,
        },
      ],
    },
    80: {
      ADVENTURE_RANK: 40,
      MORA: 77040,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 77,
        },
      ],
    },
    90: {
      ADVENTURE_RANK: 50,
      MORA: 163480,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 163,
        },
      ],
    },
  },
  2: {
    20: {
      ADVENTURE_RANK: 1,
      MORA: 3640,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 3,
        },
      ],
    },
    40: {
      ADVENTURE_RANK: 15,
      MORA: 18720,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 18,
        },
      ],
    },
    50: {
      ADVENTURE_RANK: 25,
      MORA: 18880,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 18,
        },
      ],
    },
    60: {
      ADVENTURE_RANK: 30,
      MORA: 27840,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 27,
        },
      ],
    },
    70: {
      ADVENTURE_RANK: 35,
      MORA: 39000,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 5,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 38,
        },
      ],
    },
  },
  1: {
    20: {
      ADVENTURE_RANK: 1,
      MORA: 2440,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 2,
        },
      ],
    },
    40: {
      ADVENTURE_RANK: 15,
      MORA: 12480,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 12,
        },
      ],
    },
    50: {
      ADVENTURE_RANK: 25,
      MORA: 12600,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 5,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 12,
        },
      ],
    },
    60: {
      ADVENTURE_RANK: 30,
      MORA: 18560,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 18,
        },
      ],
    },
    70: {
      ADVENTURE_RANK: 35,
      MORA: 26000,
      MATERIALS: [
        {
          ALIAS: 'ENHANCEMENT_ORE_1',
          QUANTITY: 5,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ENHANCEMENT_ORE_3',
          QUANTITY: 25,
        },
      ],
    },
  },
}

export default WEAPON_LEVEL_COSTS;
