const COMMON_MATERIAL_MAP = {
  SLIME: {
    COMMON_MAT_1: 'slime_condensate',
    COMMON_MAT_2: 'slime_secretions',
    COMMON_MAT_3: 'slime_concentrate',
  },
  MASK: {
    COMMON_MAT_1: 'damaged_mask',
    COMMON_MAT_2: 'stained_mask',
    COMMON_MAT_3: 'ominous_mask',
  },
  ARROWHEAD: {
    COMMON_MAT_1: 'firm_arrowhead',
    COMMON_MAT_2: 'sharp_arrowhead',
    COMMON_MAT_3: 'weathered_arrowhead',
  },
  SCROLL: {
    COMMON_MAT_1: 'divining_scroll',
    COMMON_MAT_2: 'sealed_scroll',
    COMMON_MAT_3: 'forbidden_curse_scroll',
  },
  TREASURE: {
    COMMON_MAT_1: 'treasure_hoarder_insignia',
    COMMON_MAT_2: 'silver_raven_insignia',
    COMMON_MAT_3: 'golden_raven_insignia',
  },
  FATUI: {
    COMMON_MAT_1: 'recruits_insignia',
    COMMON_MAT_2: 'sergeants_insignia',
    COMMON_MAT_3: 'lieutenants_insignia',
  },
  NECTAR: {
    COMMON_MAT_1: 'whopperflower_nectar',
    COMMON_MAT_2: 'shimmering_nectar',
    COMMON_MAT_3: 'energy_nectar',
  },
}

export default COMMON_MATERIAL_MAP
