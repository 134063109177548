const WEAPON_ASCENSION_COSTS = {
  5: {
    1: {
      ADVENTURE_RANK: 15,
      MORA: 10000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_1',
          QUANTITY: 5,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 5,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 3,
        },
      ],
    },
    2: {
      ADVENTURE_RANK: 25,
      MORA: 20000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 5,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 18,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 12,
        },
      ],
    },
    3: {
      ADVENTURE_RANK: 30,
      MORA: 30000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 9,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 9,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 9,
        },
      ],
    },
    4: {
      ADVENTURE_RANK: 35,
      MORA: 45000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_3',
          QUANTITY: 5,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 18,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 14,
        },
      ],
    },
    5: {
      ADVENTURE_RANK: 40,
      MORA: 55000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_3',
          QUANTITY: 9,
        },
        {
          ALIAS: 'ELITE_MAT_3',
          QUANTITY: 14,
        },
        {
          ALIAS: 'COMMON_MAT_3',
          QUANTITY: 9,
        },
      ],
    },
    6: {
      ADVENTURE_RANK: 50,
      MORA: 65000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_4',
          QUANTITY: 6,
        },
        {
          ALIAS: 'ELITE_MAT_3',
          QUANTITY: 27,
        },
        {
          ALIAS: 'COMMON_MAT_3',
          QUANTITY: 18,
        },
      ],
    },
  },
  4: {
    1: {
      ADVENTURE_RANK: 15,
      MORA: 5000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_1',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 3,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 2,
        },
      ],
    },
    2: {
      ADVENTURE_RANK: 25,
      MORA: 15000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 12,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 8,
        },
      ],
    },
    3: {
      ADVENTURE_RANK: 30,
      MORA: 20000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 6,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 6,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 6,
        },
      ],
    },
    4: {
      ADVENTURE_RANK: 35,
      MORA: 30000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_3',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 12,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 9,
        },
      ],
    },
    5: {
      ADVENTURE_RANK: 40,
      MORA: 35000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_3',
          QUANTITY: 6,
        },
        {
          ALIAS: 'ELITE_MAT_3',
          QUANTITY: 9,
        },
        {
          ALIAS: 'COMMON_MAT_3',
          QUANTITY: 6,
        },
      ],
    },
    6: {
      ADVENTURE_RANK: 50,
      MORA: 45000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_4',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ELITE_MAT_3',
          QUANTITY: 18,
        },
        {
          ALIAS: 'COMMON_MAT_3',
          QUANTITY: 12,
        },
      ],
    },
  },
  3: {
    1: {
      ADVENTURE_RANK: 15,
      MORA: 5000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 2,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 1,
        },
      ],
    },
    2: {
      ADVENTURE_RANK: 25,
      MORA: 10000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 8,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 5,
        },
      ],
    },
    3: {
      ADVENTURE_RANK: 30,
      MORA: 15000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 4,
        },
      ],
    },
    4: {
      ADVENTURE_RANK: 35,
      MORA: 20000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_3',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 8,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 6,
        },
      ],
    },
    5: {
      ADVENTURE_RANK: 40,
      MORA: 25000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_3',
          QUANTITY: 4,
        },
        {
          ALIAS: 'ELITE_MAT_3',
          QUANTITY: 6,
        },
        {
          ALIAS: 'COMMON_MAT_3',
          QUANTITY: 4,
        },
      ],
    },
    6: {
      ADVENTURE_RANK: 50,
      MORA: 30000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_4',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ELITE_MAT_3',
          QUANTITY: 12,
        },
        {
          ALIAS: 'COMMON_MAT_3',
          QUANTITY: 8,
        },
      ],
    },
  },
  2: {
    1: {
      ADVENTURE_RANK: 15,
      MORA: 5000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 1,
        },
      ],
    },
    2: {
      ADVENTURE_RANK: 25,
      MORA: 5000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 5,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 4,
        },
      ],
    },
    3: {
      ADVENTURE_RANK: 30,
      MORA: 10000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 3,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 3,
        },
      ],
    },
    4: {
      ADVENTURE_RANK: 35,
      MORA: 15000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_3',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 5,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 4,
        },
      ],
    },
  },
  1: {
    1: {
      ADVENTURE_RANK: 15,
      MORA: 0,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 1,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 1,
        },
      ],
    },
    2: {
      ADVENTURE_RANK: 25,
      MORA: 5000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ELITE_MAT_1',
          QUANTITY: 4,
        },
        {
          ALIAS: 'COMMON_MAT_1',
          QUANTITY: 2,
        },
      ],
    },
    3: {
      ADVENTURE_RANK: 30,
      MORA: 5000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 2,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 2,
        },
      ],
    },
    4: {
      ADVENTURE_RANK: 35,
      MORA: 10000,
      MATERIALS: [
        {
          ALIAS: 'DOMAIN_MAT_3',
          QUANTITY: 1,
        },
        {
          ALIAS: 'ELITE_MAT_2',
          QUANTITY: 4,
        },
        {
          ALIAS: 'COMMON_MAT_2',
          QUANTITY: 3,
        },
      ],
    },
  },
}

export default WEAPON_ASCENSION_COSTS;
