import '../common/CardCommon.css';
import './WeaponCard.css';

/* Assets */
import cabbage from '../../assets/img/cabbage.png';

/* Data */
import WEAPON_DATA from '../../data/weaponData';
import CHARACTER_DATA from '../../data/characterData';

/* Contexts */
import { useWeaponContext } from '../../contexts/weaponContext';

/* Components */
import Card from '../common/Card';
import AscensionInput from '../cardComponent/AscensionInput';
import LevelInput from '../cardComponent/LevelInput';

const WeaponCard = ({weapon}) => {
  const { weaponDispatch } = useWeaponContext();

  const updateSelf = (stat, field, value) => {
    weaponDispatch({type: "UPDATE_WEAPON", payload: {
      id: weapon.id,
      owner: weapon.owner,
      stat: stat,
      field: field,
      value: value,
    }});
  }

  const removeSelf = () => {
    weaponDispatch({type: "REMOVE_WEAPON", payload: {
      id: weapon.id,
      owner: weapon.owner,
    }});
  }

  return (
    <Card classNames={["weapon-card"]}>
      <div className="input-card-header">
        <img
          src={WEAPON_DATA[weapon.id]?.IMAGE || cabbage}
          alt={WEAPON_DATA[weapon.id]?.NAME || "Cabbage"}
          loading="lazy"
        />
        {
          weapon.owner === "NONE" ?
          <div><h2>{WEAPON_DATA[weapon.id]?.NAME || "Weapon"}</h2></div> :
          <div><h2>{`${CHARACTER_DATA[weapon.owner].name}'s ${WEAPON_DATA[weapon.id].SHORT_NAME}`}</h2></div>
        }
        <button onClick={removeSelf}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <hr />
      <h3>Ascension</h3>
      <AscensionInput
        currentAscension={weapon.ascension.current}
        setCurrentAscension={(value) => {updateSelf("ascension", "current", value);}}
        targetAscension={weapon.ascension.target}
        setTargetAscension={(value) => {updateSelf("ascension", "target", value);}}
      />
      <h3>Level</h3>
      <LevelInput
        currentLevel={weapon.level.current}
        setCurrentLevel={(value) => {updateSelf("level", "current", value);}}
        targetLevel={weapon.level.target}
        setTargetLevel={(value) => {updateSelf("level", "target", value);}}
      />
    </Card>
  );
}

export default WeaponCard;
