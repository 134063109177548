import ITEM_DATA from '../data/itemData.js';

const createItem = (id, quantity) => {
  if (!(id in ITEM_DATA)) {
    return {
      id: id,
      taskID: undefined,
      taskGroupID: undefined,
      taskGroupOwner: undefined,
      taskGroupType: undefined,

      quantity: -1,
      complete: false,
    };
  }
  return {
    id: id,
    taskID: undefined,
    taskGroupID: undefined,
    taskGroupOwner: undefined,
    taskGroupType: undefined,

    quantity: quantity,
    complete: false,
  };
}

export default createItem;
