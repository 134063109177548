import React from 'react';

/* Context */
import { useWeaponContext } from '../../contexts/weaponContext';

/* Components */
import Board from '../common/Board';
import WeaponCard from './WeaponCard';
import WeaponCardAdder from './WeaponCardAdder';

const WeaponBoard = () => {
  const {weaponState, weaponDispatch} = useWeaponContext();

  const removeWeapon = (id) => {
    weaponDispatch({type: "REMOVE_WEAPON", payload: id});
  }

  const updateWeapon = (newData) => {
    weaponDispatch({type: "UPDATE_WEAPON", payload: newData});
  }

  return (
    <Board>
      { weaponState.weaponData.map(weapon => (
        <WeaponCard
          key={`${weapon.id}_FOR_${weapon.owner}`}
          weapon={weapon}
          updateWeapon={updateWeapon}
          removeWeapon={removeWeapon}
        />
      )) }
      <WeaponCardAdder />
    </Board>
  );
}

export default WeaponBoard;
