const createTaskGroup = (id, owner, type, title, tasks) => {
  return {
    id: id || "Task Group ID",
    owner: owner,
    type: type,

    title: title || "Task Group Title",
    tasks: tasks || [],
    displayFlag: "COMBINED",
  };
}

export default createTaskGroup;
