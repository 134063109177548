import './SummaryBoard.css';

/* Contexts */
import { useCharacterContext } from '../../contexts/characterContext';
import { useWeaponContext } from '../../contexts/weaponContext';
import { useSummaryContext } from '../../contexts/summaryContext';

/* Components */
import SummaryGroup from '../summary/SummaryGroup.js';

const SummaryBoard = () => {
  const { characterState } = useCharacterContext();
  const { weaponState } = useWeaponContext();
  const { summaryState } = useSummaryContext();

  const noTasksNotice = (
    <div className="summary-board-no-content">
      <p><b>Start by adding characters or weapons in their respective tabs.</b></p>
    </div>
  );
  const filterNotice = (
    <div className="summary-board-no-content">
      <p>There are no tasks available to view under the current filters.</p>
    </div>
  );

  const displaySummaryGroups = () => {
    const summaryGroups = [];
    let noContent = true;
    let characterTaskGroups = [...characterState.taskGroups];
    let weaponTaskGroups = [...weaponState.taskGroups];

    characterTaskGroups.forEach(taskGroup => {
      noContent = false;
      if (!summaryState.visibleTypes.includes(taskGroup.type)) return;
      summaryGroups.push(<SummaryGroup key={taskGroup.id} taskGroup={taskGroup}/>);
      while (weaponTaskGroups.some(e => e.owner === taskGroup.id)) {
        const weapon = weaponTaskGroups.find(e => e.owner === taskGroup.id);
        weaponTaskGroups = weaponTaskGroups.filter(e => e.id !== weapon.id || e.owner !== weapon.owner);
        summaryGroups.push(<SummaryGroup key={weapon.id} taskGroup={weapon}/>)
      }
    });

    weaponTaskGroups.forEach(taskGroup => {
      noContent = false;
      if (!summaryState.visibleTypes.includes(taskGroup.type)) return;
      summaryGroups.push(<SummaryGroup key={taskGroup.id} taskGroup={taskGroup}/>);
    });

    return noContent ? noTasksNotice : (summaryGroups.length > 0 ? summaryGroups : filterNotice);
  }

  return (
    <div className="summary-board">
      { displaySummaryGroups() }
    </div>
  )
}

export default SummaryBoard;
 