import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './weaponState.js';

/* Reducer */
import weaponReducer from './weaponReducer';

/* Helpers */
import { refreshWeaponTaskGroups } from './weaponReducers';

const WeaponContext = createContext();

export const WeaponProvider = (props) => {
  // const savedState = undefined;
  const savedState = JSON.parse(localStorage.getItem("weaponState"));
  refreshWeaponTaskGroups(savedState);
  const [weaponState, weaponDispatch] = useReducer(weaponReducer, savedState || initialState);

  return (
    <WeaponContext.Provider value={{weaponState, weaponDispatch}}>
      {props.children}
    </WeaponContext.Provider>
  )
}

export const useWeaponContext = () => useContext(WeaponContext);
