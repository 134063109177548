import './Card.css';

const Card = ({children, classNames}) => {
  let finalClassName = "card"
  classNames?.forEach(className => {
    finalClassName += ` ${className}`;
  });

  return (
    <div className={finalClassName}>
      { children }
    </div>
  );
}

export default Card;
