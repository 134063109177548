import './talentInput.css';

/* Components */
import LevelIndicator from './LevelIndicator.js';

const TalentInput = (props) => {
  const levelOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="talent-input">
      <p>Attack</p><LevelIndicator
        levelOptions={levelOptions}
        currentLevel={props.currentAttack}
        setCurrentLevel={props.setCurrentAttack}
        targetLevel={props.targetAttack}
        setTargetLevel={props.setTargetAttack}
      />
      <p>Skill</p><LevelIndicator
        levelOptions={levelOptions}
        currentLevel={props.currentSkill}
        setCurrentLevel={props.setCurrentSkill}
        targetLevel={props.targetSkill}
        setTargetLevel={props.setTargetSkill}
      />
      <p>Burst</p><LevelIndicator
        levelOptions={levelOptions}
        currentLevel={props.currentBurst}
        setCurrentLevel={props.setCurrentBurst}
        targetLevel={props.targetBurst}
        setTargetLevel={props.setTargetBurst}
      />
    </div>
  );
}

export default TalentInput;
