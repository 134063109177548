import './SummaryPage.css';

/* Components */
import { Link } from 'react-router-dom';
import Page from '../common/Page';
import SummaryFilter from './SummaryFilter';
import SummaryBoard from './SummaryBoard';
import Button from '../input/Button';

const SummaryPage = () => {
  return (
    <Page>
      <div className="summary-page">
        <h1>Summary</h1>
        <hr className="hr-fade" />
        <SummaryFilter />
        <div className="summary-nav-buttons row">
          <Link to="/characters">
            <Button>add characters</Button>
          </Link>
          <Link to="/weapons">
            <Button>add weapons</Button>
          </Link>
        </div>
      <SummaryBoard />
      </div>
    </Page>
  );
}

export default SummaryPage
