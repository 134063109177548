import './Button.css';

const Button = ({onClick, children}) => {
  return (
    <button className="button clickable capitalize" type="button" onClick={onClick}>
      {children}
    </button>
  );
}

export default Button;
