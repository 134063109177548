import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './characterState.js';

/* Reducer */
import characterReducer from './characterReducer';

/* Helpers */
import { refreshCharacterTaskGroups } from './characterReducers';

const CharacterContext = createContext();

export const CharacterProvider = (props) => {
  // const savedState = undefined;
  const savedState = JSON.parse(localStorage.getItem("characterState"));
  refreshCharacterTaskGroups(savedState);
  const [characterState, characterDispatch] = useReducer(characterReducer, savedState || initialState);

  return (
    <CharacterContext.Provider value={{characterState, characterDispatch}}>
      {props.children}
    </CharacterContext.Provider>
  )
}

export const useCharacterContext = () => useContext(CharacterContext);
