/* Contexts */
import { useThemeContext } from '../../contexts/themeContext';

/* Components */
import ToggleSwitch from '../input/ToggleSwitch';

const ThemeSettings = () => {
  const {themeState, themeDispatch} = useThemeContext();

  const setTheme = (theme) => {
    themeDispatch({type: "SET_THEME", payload: theme})
  }

  return (
    <div className="theme-settings">
      <h2>Themes</h2>
      <div className="column">
        <ToggleSwitch
          text="Dark"
          state={themeState.currentTheme === "DARK"}
          setState={() => setTheme("DARK")}
        />
        <ToggleSwitch
          text="Light"
          state={themeState.currentTheme === "LIGHT"}
          setState={() => setTheme("LIGHT")}
        />
        <ToggleSwitch
          text="Paper"
          state={themeState.currentTheme === "PAPER"}
          setState={() => setTheme("PAPER")}
        />
      </div>
    </div>
  );
}

export default ThemeSettings;
