/* Data */
import CHARACTER_DATA from '../data/characterData';
import WEAPON_DATA from '../data/weaponData';
import ITEM_DATA from '../data/itemData';

// character material maps
import WORLD_BOSS_MATERIAL_MAP from '../data/maps/worldBossMateralMap';
import LEVEL_MATERIAL_MAP from '../data/maps/levelMaterialMap';
import TALENT_BOOK_MATERIAL_MAP from '../data/maps/talentBookMateralMap';

// weapon material maps
import WEAPON_ASCENSION_MATERIAL_MAP from '../data/maps/weaponAscensionMateralMap';
import WEAPON_LEVEL_MATERIAL_MAP from '../data/maps/weaponLevelMaterialMap';

// common material maps
import COMMON_MATERIAL_MAP from '../data/maps/commonMateralMap';
import ELITE_MATERIAL_MAP from '../data/maps/eliteMateralMap';

// helpers
import createItem from '../dataStructures/item';

export const ENUM_TO_DISPLAY = {
  ASCENSION: "Ascension",
  LEVEL: "Level",
  ATTACK: "Attack",
  SKILL: "Skill",
  BURST: "Burst",
}

export const ENUM_TO_PROPERTY = {
  ASCENSION: "ascension",
  LEVEL: "level",
  ATTACK: "attack",
  SKILL: "skill",
  BURST: "burst",
}

export const NEXT_LEVEL = {
  ASCENSION: {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 7,
  },
  LEVEL: {
    1: 20,
    20: 40,
    40: 50,
    50: 60,
    60: 70,
    70: 80,
    80: 90,
  },
  ATTACK: {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 7,
    7: 8,
    8: 9,
    9: 10,
  },
  SKILL: {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 7,
    7: 8,
    8: 9,
    9: 10,
  },
  BURST: {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 7,
    7: 8,
    8: 9,
    9: 10,
  },
}

export const TALENT_DAYS = {
  freedom: ["SUNDAY", "MONDAY", "THURSDAY"],
  resistance: ["SUNDAY", "TUESDAY", "FRIDAY"],
  ballad: ["SUNDAY", "WEDNESDAY", "SATURDAY"],
  prosperity: ["SUNDAY", "MONDAY", "THURSDAY"],
  diligence: ["SUNDAY", "TUESDAY", "FRIDAY"],
  gold: ["SUNDAY", "WEDNESDAY", "SATURDAY"],
}

export const DOMAIN_DAYS = {
  TALENT: {
    freedom: ["SUNDAY", "MONDAY", "THURSDAY"],
    resistance: ["SUNDAY", "TUESDAY", "FRIDAY"],
    ballad: ["SUNDAY", "WEDNESDAY", "SATURDAY"],
    prosperity: ["SUNDAY", "MONDAY", "THURSDAY"],
    diligence: ["SUNDAY", "TUESDAY", "FRIDAY"],
    gold: ["SUNDAY", "WEDNESDAY", "SATURDAY"],
  },
  WEAPON: {
    DECARABIAN: ["SUNDAY", "MONDAY", "THURSDAY"],
    TOOTH: ["SUNDAY", "TUESDAY", "FRIDAY"],
    GLADIATOR: ["SUNDAY", "WEDNESDAY", "SATURDAY"],
    GUYUN: ["SUNDAY", "MONDAY", "THURSDAY"],
    ELIXIR: ["SUNDAY", "TUESDAY", "FRIDAY"],
    AEROSIDERITE: ["SUNDAY", "WEDNESDAY", "SATURDAY"],
  }
}

export const translateAlias = (context, contextType, alias) => {
  let material = undefined;
  switch (contextType) {
    case "CHARACTER":
      material = translateCharacterAlias(context, alias);
      break;
    case "WEAPON":
      material = translateWeaponAlias(context, alias); 
      break;
    default:
      break;
  }
  return material;
}

const translateCharacterAlias = (character, alias) => {
  character = CHARACTER_DATA[character.id];
  if (alias in (CHARACTER_DATA[character.id] || {})) {
    return CHARACTER_DATA[character.id][alias];
  }
  if (alias in (WORLD_BOSS_MATERIAL_MAP[character.element] || {})) {
    return WORLD_BOSS_MATERIAL_MAP[character.element][alias];
  }
  if (alias in (TALENT_BOOK_MATERIAL_MAP[character.talentBook] || {})) {
    return TALENT_BOOK_MATERIAL_MAP[character.talentBook][alias];
  }
  if (alias in (LEVEL_MATERIAL_MAP || {})) {
    return LEVEL_MATERIAL_MAP[alias];
  }
  if (alias in (COMMON_MATERIAL_MAP[character.COMMON_MAT] || {})) {
    return COMMON_MATERIAL_MAP[character.COMMON_MAT][alias];
  }
  if (alias === "crown") {
    return "crown_of_insight";
  }
  return alias;
}

const translateWeaponAlias = (weapon, alias) => {
  weapon = WEAPON_DATA[weapon.id];
  if (alias in WEAPON_ASCENSION_MATERIAL_MAP[weapon.DOMAIN_MAT_TYPE])
    return WEAPON_ASCENSION_MATERIAL_MAP[weapon.DOMAIN_MAT_TYPE][alias];
  if (alias in WEAPON_LEVEL_MATERIAL_MAP)
    return WEAPON_LEVEL_MATERIAL_MAP[alias];
  if (alias in COMMON_MATERIAL_MAP[weapon.COMMON_MAT_TYPE])
    return COMMON_MATERIAL_MAP[weapon.COMMON_MAT_TYPE][alias];
  if (alias in ELITE_MATERIAL_MAP[weapon.ELITE_MAT_TYPE])
    return ELITE_MATERIAL_MAP[weapon.ELITE_MAT_TYPE][alias];
  return alias;
}

export const getMaterialListFromEntries = (entries) => {
  const materials = [];
  Object.keys(entries).forEach(material => {
    materials.push(createItem(
      material,
      entries[material]
    ));
  });
  materials.sort((a, b) => (
    ITEM_DATA[a.id].sortingPriority - ITEM_DATA[b.id].sortingPriority
  ));
  return materials;
}
