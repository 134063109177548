import './SummaryCard.css';

/* Assets */
import moraImage from '../../assets/img/mora.png';

/* Utils */
import { SHORT_DAY } from '../../contexts/summaryUtils';

/* Components */
import CostDisplay from '../cardComponent/CostDisplay.js';
import ItemDisplay from '../cardComponent/ItemDisplay.js';

const SummaryCard = ({task, allowCompletion, completeTask}) => {
  let days = "";
  task.days.forEach(day => {
    days += `${SHORT_DAY[day].toLowerCase()}/`
  });
  days = days.slice(0, -1);

  return (
    <div className="summary-card">
      {
        (allowCompletion && !task.displayFlags.includes("COMBINED")) ?
        <button type="button" className="summary-card-complete" onClick={() => completeTask(task)}>
          <i className="fas fa-check"></i>
        </button> : null
      }
      {
        (allowCompletion && task.displayFlags.includes("COMBINED")) ?
        <button type="button" className="summary-card-complete" onClick={() => completeTask(task)}>
          <i className="fas fa-check-double"></i>
        </button> : null
      }
      <div className="summary-card-header">
        <div>
          <h1>{task.title || "Title"}</h1>
          <h2>{task.subtitle || "Subtitle"}</h2>
        </div>
        <div className="summary-card-costs">
          <CostDisplay name="day" amount={days} />
          <CostDisplay name="mora" image={moraImage} amount={task.mora} />
          { task.adventureRank && <CostDisplay name="adventureRank" amount={`AR ${task.adventureRank}`} /> }
        </div>
      </div>
      <div className="summary-card-body">
        { task.items.map(item => (
          <ItemDisplay key={`${task.title} ${task.subtitle} ${item.id}`} item={item} />
        )) }
      </div>
    </div>
  )
}

export default SummaryCard;
