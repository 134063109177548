export const THEMES = {
  DARK: {
    '--main-color': '#d3bc8e',
    '--main-color-filter': 'invert(81%) sepia(40%) saturate(337%) hue-rotate(344deg) brightness(91%) contrast(90%)',

    '--bg-color': '#1c1c22',
    '--bg-color-dark': '#17171c',
    '--bg-color-dark-transparent': '#17171cbf',
    '--bg-color-dark-filter': 'invert(7%) sepia(12%) saturate(753%) hue-rotate(201deg) brightness(96%)',
    '--bg-color-light': '#202027',
  },
  LIGHT: {
    '--main-color': '#8599a8',
    '--main-color-filter': 'invert(59%) sepia(17%) saturate(364%) hue-rotate(163deg) brightness(98%) contrast(87%)',

    '--bg-color': '#f6f6f6',

    '--bg-color-dark': '#eaeaea',
    '--bg-color-dark-transparent': '#eaeaeabf',
    '--bg-color-dark-filter': 'invert(100%) sepia(3%) saturate(1%) hue-rotate(160deg) brightness(112%) contrast(84%)',
    '--bg-color-light': '#ffffff',
  },
  PAPER: {
    '--main-color': '#5f7586',
    '--main-color-filter': 'invert(47%) sepia(18%) saturate(545%) hue-rotate(164deg) brightness(89%) contrast(87%)',
  
    '--bg-color': '#e1e1d6',
    '--bg-color-dark': '#d8d8ca',
    '--bg-color-dark-transparent': '#d8d8cabf',
    '--bg-color-dark-filter': 'invert(100%) sepia(4%) saturate(2835%) hue-rotate(345deg) brightness(85%) contrast(98%)',
    '--bg-color-light': '#e7e7df',
  },
}