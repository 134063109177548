import './Footer.css';

/* Data */
import {
  VERSION_NAME,
  MY_EMAIL,
} from '../data/constants';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>Genshin Planner {VERSION_NAME}</p>
        <p>Made by James Zhao</p>
        <p>Contact me at <a href={`mailto:${MY_EMAIL}`}>{MY_EMAIL}</a></p>
      </div>
    </footer>
  );
}

export default Footer;
