import './Page.css';

const Page = ({children}) => {
  return (
    <div className="page-content-container">
      { children }
    </div>
  )
}

export default Page;
