import './ToggleSwitch.css';

const ToggleSwitch = ({text, state, setState}) => {
  return (
    <label className="toggle-switch clickable">
      <input type="checkbox" checked={state} onChange={() => setState?.(!state)} name={text}></input>
      <span className="slider"></span>
      { text && <p>{text}</p>}
    </label>
  );
}

export default ToggleSwitch;
