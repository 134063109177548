/* Components */
import LevelIndicator from './LevelIndicator.js';

const LevelInput = (props) => {
  const levelOptions = [1, 20, 40, 50, 60, 70, 80, 90];

  return (
    <div className="level-input">
      <LevelIndicator
        levelOptions={levelOptions}
        currentLevel={props.currentLevel}
        setCurrentLevel={props.setCurrentLevel}
        targetLevel={props.targetLevel}
        setTargetLevel={props.setTargetLevel}
      />
    </div>
  );
}

export default LevelInput;
