const createTask = (id, title, subtitle, adventureRank, mora, items, days, displayFlags, updateSignal) => {
  return {
    id: id || "Task ID",

    title: title || "Task Title",
    subtitle: subtitle || "Task Subtitle",

    adventureRank: adventureRank || 1,
    mora: mora || -1,
    items: items || [],
    days: days || [],
    displayFlags: displayFlags || [],

    updateSignal: updateSignal || {
      stat: "STAT",
      field: "FIELD",
      value: -1,
    },
  };
}

export default createTask;
