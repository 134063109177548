import './Select.css';

const Select = ({children, value, onChange}) => {
  return (
    <div className="select clickable">
      <select value={value} onChange={onChange}>
        { children }
      </select>
      <i className="fas fa-angle-down"></i>
    </div>
  );
}

export default Select
