import { THEMES } from './themeData';

//#region top level reducers

export const setTheme = (newState, payload) => {
  if (!(payload in THEMES)) return;
  for (const [key, value] of Object.entries(THEMES[payload]))
    document.documentElement.style.setProperty(key, value);
  newState.currentTheme = payload;
}

//#endregion
