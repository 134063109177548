import './LevelIndicator.css';

/* Components */
import Select from '../input/Select';

const LevelIndicator = (props) => {
  const handleCurrentLevelChange = (e) => {
    props.setCurrentLevel(parseInt(e.target.value))
  }

  const handleTargetLevelChange = (e) => {
    props.setTargetLevel(parseInt(e.target.value))
  }

  return (
    <div className="level-indicator">
      <Select
        value={props.currentLevel}
        onChange={handleCurrentLevelChange}
      >
        { props.levelOptions.map(option => (
          <option key={option.toString()}>{option}</option>
        )) }
      </Select>
      <i className="fas fa-arrow-right"></i>
      <Select
        value={props.targetLevel}
        onChange={handleTargetLevelChange}
      >
        { props.levelOptions.map(option => (
          <option key={option.toString()}>{option}</option>
        )) }
      </Select>
    </div>
  );
}

export default LevelIndicator;
