import './SettingsPage.css';

/* Components */
import Page from '../common/Page';
import ThemeSettings from './ThemeSettings';

const SettingsPage = () => {
  return (
    <Page>
      <div className="settings-page">
        <h1>Settings</h1>
        <hr className="hr-fade" />
        <ThemeSettings />
      </div>
    </Page>
  );
}

export default SettingsPage;
