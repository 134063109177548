import './AscensionIndicator.css';

import genshinStar from '../../assets/img/genshin_star.svg';

const AscensionIndicator = ({ascension, setAscension}) => {
  const LEVEL_RANGE = {
    1: "20-40",
    2: "40-50",
    3: "50-60",
    4: "60-70",
    5: "70-80",
    6: "80-90",
  }

  const setAscensionHelper = (i) => {
    if (i === ascension) {
      setAscension(i - 1);
    }
    else {
      setAscension(i);
    }
  }

  const displayStars = () => {
    const stars = [];
    for (let i = 1; i <= 6; i++) {
      if (i <= ascension) {
        stars.push(<img
          key={i.toString()}
          src={genshinStar}
          alt="*"
          title={`Level ${LEVEL_RANGE[i]}`}
          className="ascension-indicator-star ascension-indicator-star-active clickable"
          onClick={() => setAscensionHelper(i)}
        />);
      }
      else {
        stars.push(<img
          key={i.toString()}
          src={genshinStar}
          alt="*"
          title={`Level ${LEVEL_RANGE[i]}`}
          className="ascension-indicator-star ascension-indicator-star-inactive clickable"
          onClick={() => setAscensionHelper(i)}
        />);
      }
    }
    return stars;
  }

  return (
    <div className="ascension-indicator">
      { displayStars() }
    </div>
  );
}

export default AscensionIndicator;
