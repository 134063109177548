import c_exp from '../assets/img/item/characterLevelMats/c_exp.png';
import heros_wit from '../assets/img/item/characterLevelMats/heros_wit.png';
import adventurers_experience from '../assets/img/item/characterLevelMats/adventurers_experience.png';
import wanderers_advice from '../assets/img/item/characterLevelMats/wanderers_advice.png';
import hurricane_seed from '../assets/img/item/characterAscensionMats/hurricane_seed.png';
import hoarfrost_core from '../assets/img/item/characterAscensionMats/hoarfrost_core.png';
import lightning_prism from '../assets/img/item/characterAscensionMats/lightning_prism.png';
import basalt_pillar from '../assets/img/item/characterAscensionMats/basalt_pillar.png';
import cleansing_heart from '../assets/img/item/characterAscensionMats/cleansing_heart.png';
import everflame_seed from '../assets/img/item/characterAscensionMats/everflame_seed.png';
import juvenile_jade from '../assets/img/item/characterAscensionMats/juvenile_jade.png';
import crystalline_bloom from '../assets/img/item/characterAscensionMats/crystalline_bloom.png';
import brilliant_diamond_sliver from '../assets/img/item/characterAscensionMats/brilliant_diamond_sliver.png';
import brilliant_diamond_fragment from '../assets/img/item/characterAscensionMats/brilliant_diamond_fragment.png';
import brilliant_diamond_chunk from '../assets/img/item/characterAscensionMats/brilliant_diamond_chunk.png';
import brilliant_diamond_gemstone from '../assets/img/item/characterAscensionMats/brilliant_diamond_gemstone.png';
import vayuda_turquoise_sliver from '../assets/img/item/characterAscensionMats/vayuda_turquoise_sliver.png';
import vayuda_turquoise_fragment from '../assets/img/item/characterAscensionMats/vayuda_turquoise_fragment.png';
import vayuda_turquoise_chunk from '../assets/img/item/characterAscensionMats/vayuda_turquoise_chunk.png';
import vayuda_turquoise_gemstone from '../assets/img/item/characterAscensionMats/vayuda_turquoise_gemstone.png';
import shivada_jade_sliver from '../assets/img/item/characterAscensionMats/shivada_jade_sliver.png';
import shivada_jade_fragment from '../assets/img/item/characterAscensionMats/shivada_jade_fragment.png';
import shivada_jade_chunk from '../assets/img/item/characterAscensionMats/shivada_jade_chunk.png';
import shivada_jade_gemstone from '../assets/img/item/characterAscensionMats/shivada_jade_gemstone.png';
import vajrada_amethyst_sliver from '../assets/img/item/characterAscensionMats/vajrada_amethyst_sliver.png';
import vajrada_amethyst_fragment from '../assets/img/item/characterAscensionMats/vajrada_amethyst_fragment.png';
import vajrada_amethyst_chunk from '../assets/img/item/characterAscensionMats/vajrada_amethyst_chunk.png';
import vajrada_amethyst_gemstone from '../assets/img/item/characterAscensionMats/vajrada_amethyst_gemstone.png';
import prithiva_topaz_sliver from '../assets/img/item/characterAscensionMats/prithiva_topaz_sliver.png';
import prithiva_topaz_fragment from '../assets/img/item/characterAscensionMats/prithiva_topaz_fragment.png';
import prithiva_topaz_chunk from '../assets/img/item/characterAscensionMats/prithiva_topaz_chunk.png';
import prithiva_topaz_gemstone from '../assets/img/item/characterAscensionMats/prithiva_topaz_gemstone.png';
import varunada_lazurite_sliver from '../assets/img/item/characterAscensionMats/varunada_lazurite_sliver.png';
import varunada_lazurite_fragment from '../assets/img/item/characterAscensionMats/varunada_lazurite_fragment.png';
import varunada_lazurite_chunk from '../assets/img/item/characterAscensionMats/varunada_lazurite_chunk.png';
import varunada_lazurite_gemstone from '../assets/img/item/characterAscensionMats/varunada_lazurite_gemstone.png';
import agnidus_agate_sliver from '../assets/img/item/characterAscensionMats/agnidus_agate_sliver.png';
import agnidus_agate_fragment from '../assets/img/item/characterAscensionMats/agnidus_agate_fragment.png';
import agnidus_agate_chunk from '../assets/img/item/characterAscensionMats/agnidus_agate_chunk.png';
import agnidus_agate_gemstone from '../assets/img/item/characterAscensionMats/agnidus_agate_gemstone.png';
import teachings_of_freedom from '../assets/img/item/characterTalentMats/teachings_of_freedom.png';
import guide_to_freedom from '../assets/img/item/characterTalentMats/guide_to_freedom.png';
import philosophies_of_freedom from '../assets/img/item/characterTalentMats/philosophies_of_freedom.png';
import teachings_of_resistance from '../assets/img/item/characterTalentMats/teachings_of_resistance.png';
import guide_to_resistance from '../assets/img/item/characterTalentMats/guide_to_resistance.png';
import philosophies_of_resistance from '../assets/img/item/characterTalentMats/philosophies_of_resistance.png';
import teachings_of_ballad from '../assets/img/item/characterTalentMats/teachings_of_ballad.png';
import guide_to_ballad from '../assets/img/item/characterTalentMats/guide_to_ballad.png';
import philosophies_of_ballad from '../assets/img/item/characterTalentMats/philosophies_of_ballad.png';
import teachings_of_prosperity from '../assets/img/item/characterTalentMats/teachings_of_prosperity.png';
import guide_to_prosperity from '../assets/img/item/characterTalentMats/guide_to_prosperity.png';
import philosophies_of_prosperity from '../assets/img/item/characterTalentMats/philosophies_of_prosperity.png';
import teachings_of_diligence from '../assets/img/item/characterTalentMats/teachings_of_diligence.png';
import guide_to_diligence from '../assets/img/item/characterTalentMats/guide_to_diligence.png';
import philosophies_of_diligence from '../assets/img/item/characterTalentMats/philosophies_of_diligence.png';
import teachings_of_gold from '../assets/img/item/characterTalentMats/teachings_of_gold.png';
import guide_to_gold from '../assets/img/item/characterTalentMats/guide_to_gold.png';
import philosophies_of_gold from '../assets/img/item/characterTalentMats/philosophies_of_gold.png';
import crown_of_insight from '../assets/img/item/characterTalentMats/crown_of_insight.png';
import tail_of_boreas from '../assets/img/item/characterTalentMats/tail_of_boreas.png';
import ring_of_boreas from '../assets/img/item/characterTalentMats/ring_of_boreas.png';
import spirit_locket_of_boreas from '../assets/img/item/characterTalentMats/spirit_locket_of_boreas.png';
import dvalins_plume from '../assets/img/item/characterTalentMats/dvalins_plume.png';
import dvalins_claw from '../assets/img/item/characterTalentMats/dvalins_claw.png';
import dvalins_sigh from '../assets/img/item/characterTalentMats/dvalins_sigh.png';
import tusk_of_monoceros_caeli from '../assets/img/item/characterTalentMats/tusk_of_monoceros_caeli.png';
import shard_of_a_foul_legacy from '../assets/img/item/characterTalentMats/shard_of_a_foul_legacy.png';
import shadow_of_the_warrior from '../assets/img/item/characterTalentMats/shadow_of_the_warrior.png';
import dragon_lords_crown from '../assets/img/item/characterTalentMats/dragon_lords_crown.png';
import bloodjade_branch from '../assets/img/item/characterTalentMats/bloodjade_branch.png';
import enhancement_ore from '../assets/img/item/weaponLevelMats/enhancement_ore.png';
import fine_enhancement_ore from '../assets/img/item/weaponLevelMats/fine_enhancement_ore.png';
import mystic_enhancement_ore from '../assets/img/item/weaponLevelMats/mystic_enhancement_ore.png';
import tile_of_decarabians_tower from '../assets/img/item/weaponAscensionMats/tile_of_decarabians_tower.png';
import debris_of_decarabians_city from '../assets/img/item/weaponAscensionMats/debris_of_decarabians_city.png';
import fragment_of_decarabians_epic from '../assets/img/item/weaponAscensionMats/fragment_of_decarabians_epic.png';
import scattered_piece_of_decarabians_dream from '../assets/img/item/weaponAscensionMats/scattered_piece_of_decarabians_dream.png';
import boreal_wolfs_milk_tooth from '../assets/img/item/weaponAscensionMats/boreal_wolfs_milk_tooth.png';
import boreal_wolfs_cracked_tooth from '../assets/img/item/weaponAscensionMats/boreal_wolfs_cracked_tooth.png';
import boreal_wolfs_broken_fang from '../assets/img/item/weaponAscensionMats/boreal_wolfs_broken_fang.png';
import boreal_wolfs_nostalgia from '../assets/img/item/weaponAscensionMats/boreal_wolfs_nostalgia.png';
import fetters_of_the_dandelion_gladiator from '../assets/img/item/weaponAscensionMats/fetters_of_the_dandelion_gladiator.png';
import chains_of_the_dandelion_gladiator from '../assets/img/item/weaponAscensionMats/chains_of_the_dandelion_gladiator.png';
import shackles_of_the_dandelion_gladiator from '../assets/img/item/weaponAscensionMats/shackles_of_the_dandelion_gladiator.png';
import dream_of_the_dandelion_gladiator from '../assets/img/item/weaponAscensionMats/dream_of_the_dandelion_gladiator.png';
import luminous_sands_from_guyun from '../assets/img/item/weaponAscensionMats/luminous_sands_from_guyun.png';
import lustrous_stone_from_guyun from '../assets/img/item/weaponAscensionMats/lustrous_stone_from_guyun.png';
import relic_from_guyun from '../assets/img/item/weaponAscensionMats/relic_from_guyun.png';
import divine_body_from_guyun from '../assets/img/item/weaponAscensionMats/divine_body_from_guyun.png';
import mist_veiled_lead_elixir from '../assets/img/item/weaponAscensionMats/mist_veiled_lead_elixir.png';
import mist_veiled_mercury_elixir from '../assets/img/item/weaponAscensionMats/mist_veiled_mercury_elixir.png';
import mist_veiled_gold_elixir from '../assets/img/item/weaponAscensionMats/mist_veiled_gold_elixir.png';
import mist_veiled_primo_elixir from '../assets/img/item/weaponAscensionMats/mist_veiled_primo_elixir.png';
import grain_of_aerosiderite from '../assets/img/item/weaponAscensionMats/grain_of_aerosiderite.png';
import piece_of_aerosiderite from '../assets/img/item/weaponAscensionMats/piece_of_aerosiderite.png';
import bit_of_aerosiderite from '../assets/img/item/weaponAscensionMats/bit_of_aerosiderite.png';
import chunk_of_aerosiderite from '../assets/img/item/weaponAscensionMats/chunk_of_aerosiderite.png';
import slime_condensate from '../assets/img/item/commonMobMats/slime_condensate.png';
import slime_secretions from '../assets/img/item/commonMobMats/slime_secretions.png';
import slime_concentrate from '../assets/img/item/commonMobMats/slime_concentrate.png';
import damaged_mask from '../assets/img/item/commonMobMats/damaged_mask.png';
import stained_mask from '../assets/img/item/commonMobMats/stained_mask.png';
import ominous_mask from '../assets/img/item/commonMobMats/ominous_mask.png';
import firm_arrowhead from '../assets/img/item/commonMobMats/firm_arrowhead.png';
import sharp_arrowhead from '../assets/img/item/commonMobMats/sharp_arrowhead.png';
import weathered_arrowhead from '../assets/img/item/commonMobMats/weathered_arrowhead.png';
import divining_scroll from '../assets/img/item/commonMobMats/divining_scroll.png';
import sealed_scroll from '../assets/img/item/commonMobMats/sealed_scroll.png';
import forbidden_curse_scroll from '../assets/img/item/commonMobMats/forbidden_curse_scroll.png';
import treasure_hoarder_insignia from '../assets/img/item/commonMobMats/treasure_hoarder_insignia.png';
import silver_raven_insignia from '../assets/img/item/commonMobMats/silver_raven_insignia.png';
import golden_raven_insignia from '../assets/img/item/commonMobMats/golden_raven_insignia.png';
import recruits_insignia from '../assets/img/item/commonMobMats/recruits_insignia.png';
import sergeants_insignia from '../assets/img/item/commonMobMats/sergeants_insignia.png';
import lieutenants_insignia from '../assets/img/item/commonMobMats/lieutenants_insignia.png';
import whopperflower_nectar from '../assets/img/item/commonMobMats/whopperflower_nectar.png';
import shimmering_nectar from '../assets/img/item/commonMobMats/shimmering_nectar.png';
import energy_nectar from '../assets/img/item/commonMobMats/energy_nectar.png';
import heavy_horn from '../assets/img/item/eliteMobMats/heavy_horn.png';
import black_bronze_horn from '../assets/img/item/eliteMobMats/black_bronze_horn.png';
import black_crystal_horn from '../assets/img/item/eliteMobMats/black_crystal_horn.png';
import dead_ley_line_branch from '../assets/img/item/eliteMobMats/dead_ley_line_branch.png';
import dead_ley_line_leaves from '../assets/img/item/eliteMobMats/dead_ley_line_leaves.png';
import ley_line_sprouts from '../assets/img/item/eliteMobMats/ley_line_sprouts.png';
import fragile_bone_shard from '../assets/img/item/eliteMobMats/fragile_bone_shard.png';
import sturdy_bone_shard from '../assets/img/item/eliteMobMats/sturdy_bone_shard.png';
import fossilized_bone_shard from '../assets/img/item/eliteMobMats/fossilized_bone_shard.png';
import mist_grass_pollen from '../assets/img/item/eliteMobMats/mist_grass_pollen.png';
import mist_grass from '../assets/img/item/eliteMobMats/mist_grass.png';
import mist_grass_wick from '../assets/img/item/eliteMobMats/mist_grass_wick.png';
import hunters_sacrificial_knife from '../assets/img/item/eliteMobMats/hunters_sacrificial_knife.png';
import agents_sacrificial_knife from '../assets/img/item/eliteMobMats/agents_sacrificial_knife.png';
import inspectors_sacrificial_knife from '../assets/img/item/eliteMobMats/inspectors_sacrificial_knife.png';
import chaos_device from '../assets/img/item/eliteMobMats/chaos_device.png';
import chaos_circuit from '../assets/img/item/eliteMobMats/chaos_circuit.png';
import chaos_core from '../assets/img/item/eliteMobMats/chaos_core.png';
import calla_lily from '../assets/img/item/localSpecialty/calla_lily.png';
import cecilia from '../assets/img/item/localSpecialty/cecilia.png';
import cor_lapis from '../assets/img/item/localSpecialty/cor_lapis.png';
import dandelion_seed from '../assets/img/item/localSpecialty/dandelion_seed.png';
import glaze_lily from '../assets/img/item/localSpecialty/glaze_lily.png';
import jueyun_chili from '../assets/img/item/localSpecialty/jueyun_chili.png';
import noctilucous_jade from '../assets/img/item/localSpecialty/noctilucous_jade.png';
import philanemo_mushroom from '../assets/img/item/localSpecialty/philanemo_mushroom.png';
import qingxin from '../assets/img/item/localSpecialty/qingxin.png';
import silk_flower from '../assets/img/item/localSpecialty/silk_flower.png';
import small_lamp_grass from '../assets/img/item/localSpecialty/small_lamp_grass.png';
import starconch from '../assets/img/item/localSpecialty/starconch.png';
import valberry from '../assets/img/item/localSpecialty/valberry.png';
import violetgrass from '../assets/img/item/localSpecialty/violetgrass.png';
import windwheel_aster from '../assets/img/item/localSpecialty/windwheel_aster.png';
import wolfhook from '../assets/img/item/localSpecialty/wolfhook.png';

const ITEM_DATA = {
  c_exp: {
    id: 'c_exp',
    name: 'Character EXP',
    sortingPriority: 4,
    image: c_exp,
    rarity: -1
  },
  heros_wit: {
    id: 'heros_wit',
    name: 'Hero\'s Wit',
    sortingPriority: 3,
    image: heros_wit,
    rarity: 4
  },
  adventurers_experience: {
    id: 'adventurers_experience',
    name: 'Adventurer\'s Experience',
    sortingPriority: 2,
    image: adventurers_experience,
    rarity: 3
  },
  wanderers_advice: {
    id: 'wanderers_advice',
    name: 'Wanderer\'s Advice',
    sortingPriority: 1,
    image: wanderers_advice,
    rarity: 2
  },
  hurricane_seed: {
    id: 'hurricane_seed',
    name: 'Hurricane Seed',
    sortingPriority: 50,
    image: hurricane_seed,
    rarity: 4
  },
  hoarfrost_core: {
    id: 'hoarfrost_core',
    name: 'Hoarfrost Core',
    sortingPriority: 50,
    image: hoarfrost_core,
    rarity: 4
  },
  lightning_prism: {
    id: 'lightning_prism',
    name: 'Lightning Prism',
    sortingPriority: 50,
    image: lightning_prism,
    rarity: 4
  },
  basalt_pillar: {
    id: 'basalt_pillar',
    name: 'Basalt Pillar',
    sortingPriority: 50,
    image: basalt_pillar,
    rarity: 4
  },
  cleansing_heart: {
    id: 'cleansing_heart',
    name: 'Cleansing Heart',
    sortingPriority: 50,
    image: cleansing_heart,
    rarity: 4
  },
  everflame_seed: {
    id: 'everflame_seed',
    name: 'Everflame Seed',
    sortingPriority: 50,
    image: everflame_seed,
    rarity: 4
  },
  juvenile_jade: {
    id: 'juvenile_jade',
    name: 'Juvenile Jade',
    sortingPriority: 50,
    image: juvenile_jade,
    rarity: 4
  },
  crystalline_bloom: {
    id: 'crystalline_bloom',
    name: 'Crystalline Bloom',
    sortingPriority: 50,
    image: crystalline_bloom,
    rarity: 4
  },
  brilliant_diamond_sliver: {
    id: 'brilliant_diamond_sliver',
    name: 'Brilliant Diamond Sliver',
    sortingPriority: 1,
    image: brilliant_diamond_sliver,
    rarity: 2
  },
  brilliant_diamond_fragment: {
    id: 'brilliant_diamond_fragment',
    name: 'Brilliant Diamond Fragment',
    sortingPriority: 2,
    image: brilliant_diamond_fragment,
    rarity: 3
  },
  brilliant_diamond_chunk: {
    id: 'brilliant_diamond_chunk',
    name: 'Brilliant Diamond Chunk',
    sortingPriority: 3,
    image: brilliant_diamond_chunk,
    rarity: 4
  },
  brilliant_diamond_gemstone: {
    id: 'brilliant_diamond_gemstone',
    name: 'Brilliant Diamond Gemstone',
    sortingPriority: 4,
    image: brilliant_diamond_gemstone,
    rarity: 5
  },
  vayuda_turquoise_sliver: {
    id: 'vayuda_turquoise_sliver',
    name: 'Vayuda Turquoise Sliver',
    sortingPriority: 1,
    image: vayuda_turquoise_sliver,
    rarity: 2
  },
  vayuda_turquoise_fragment: {
    id: 'vayuda_turquoise_fragment',
    name: 'Vayuda Turquoise Fragment',
    sortingPriority: 2,
    image: vayuda_turquoise_fragment,
    rarity: 3
  },
  vayuda_turquoise_chunk: {
    id: 'vayuda_turquoise_chunk',
    name: 'Vayuda Turquoise Chunk',
    sortingPriority: 3,
    image: vayuda_turquoise_chunk,
    rarity: 4
  },
  vayuda_turquoise_gemstone: {
    id: 'vayuda_turquoise_gemstone',
    name: 'Vayuda Turquoise Gemstone',
    sortingPriority: 4,
    image: vayuda_turquoise_gemstone,
    rarity: 5
  },
  shivada_jade_sliver: {
    id: 'shivada_jade_sliver',
    name: 'Shivada Jade Sliver',
    sortingPriority: 1,
    image: shivada_jade_sliver,
    rarity: 2
  },
  shivada_jade_fragment: {
    id: 'shivada_jade_fragment',
    name: 'Shivada Jade Fragment',
    sortingPriority: 2,
    image: shivada_jade_fragment,
    rarity: 3
  },
  shivada_jade_chunk: {
    id: 'shivada_jade_chunk',
    name: 'Shivada Jade Chunk',
    sortingPriority: 3,
    image: shivada_jade_chunk,
    rarity: 4
  },
  shivada_jade_gemstone: {
    id: 'shivada_jade_gemstone',
    name: 'Shivada Jade Gemstone',
    sortingPriority: 4,
    image: shivada_jade_gemstone,
    rarity: 5
  },
  vajrada_amethyst_sliver: {
    id: 'vajrada_amethyst_sliver',
    name: 'Vajrada Amethyst Sliver',
    sortingPriority: 1,
    image: vajrada_amethyst_sliver,
    rarity: 2
  },
  vajrada_amethyst_fragment: {
    id: 'vajrada_amethyst_fragment',
    name: 'Vajrada Amethyst Fragment',
    sortingPriority: 2,
    image: vajrada_amethyst_fragment,
    rarity: 3
  },
  vajrada_amethyst_chunk: {
    id: 'vajrada_amethyst_chunk',
    name: 'Vajrada Amethyst Chunk',
    sortingPriority: 3,
    image: vajrada_amethyst_chunk,
    rarity: 4
  },
  vajrada_amethyst_gemstone: {
    id: 'vajrada_amethyst_gemstone',
    name: 'Vajrada Amethyst Gemstone',
    sortingPriority: 4,
    image: vajrada_amethyst_gemstone,
    rarity: 5
  },
  prithiva_topaz_sliver: {
    id: 'prithiva_topaz_sliver',
    name: 'Prithiva Topaz Sliver',
    sortingPriority: 1,
    image: prithiva_topaz_sliver,
    rarity: 2
  },
  prithiva_topaz_fragment: {
    id: 'prithiva_topaz_fragment',
    name: 'Prithiva Topaz Fragment',
    sortingPriority: 2,
    image: prithiva_topaz_fragment,
    rarity: 3
  },
  prithiva_topaz_chunk: {
    id: 'prithiva_topaz_chunk',
    name: 'Prithiva Topaz Chunk',
    sortingPriority: 3,
    image: prithiva_topaz_chunk,
    rarity: 4
  },
  prithiva_topaz_gemstone: {
    id: 'prithiva_topaz_gemstone',
    name: 'Prithiva Topaz Gemstone',
    sortingPriority: 4,
    image: prithiva_topaz_gemstone,
    rarity: 5
  },
  varunada_lazurite_sliver: {
    id: 'varunada_lazurite_sliver',
    name: 'Varunada Lazurite Sliver',
    sortingPriority: 1,
    image: varunada_lazurite_sliver,
    rarity: 2
  },
  varunada_lazurite_fragment: {
    id: 'varunada_lazurite_fragment',
    name: 'Varunada Lazurite Fragment',
    sortingPriority: 2,
    image: varunada_lazurite_fragment,
    rarity: 3
  },
  varunada_lazurite_chunk: {
    id: 'varunada_lazurite_chunk',
    name: 'Varunada Lazurite Chunk',
    sortingPriority: 3,
    image: varunada_lazurite_chunk,
    rarity: 4
  },
  varunada_lazurite_gemstone: {
    id: 'varunada_lazurite_gemstone',
    name: 'Varunada Lazurite Gemstone',
    sortingPriority: 4,
    image: varunada_lazurite_gemstone,
    rarity: 5
  },
  agnidus_agate_sliver: {
    id: 'agnidus_agate_sliver',
    name: 'Agnidus Agate Sliver',
    sortingPriority: 1,
    image: agnidus_agate_sliver,
    rarity: 2
  },
  agnidus_agate_fragment: {
    id: 'agnidus_agate_fragment',
    name: 'Agnidus Agate Fragment',
    sortingPriority: 2,
    image: agnidus_agate_fragment,
    rarity: 3
  },
  agnidus_agate_chunk: {
    id: 'agnidus_agate_chunk',
    name: 'Agnidus Agate Chunk',
    sortingPriority: 3,
    image: agnidus_agate_chunk,
    rarity: 4
  },
  agnidus_agate_gemstone: {
    id: 'agnidus_agate_gemstone',
    name: 'Agnidus Agate Gemstone',
    sortingPriority: 4,
    image: agnidus_agate_gemstone,
    rarity: 5
  },
  teachings_of_freedom: {
    id: 'teachings_of_freedom',
    name: 'Teachings of Freedom',
    sortingPriority: 1,
    image: teachings_of_freedom,
    rarity: 2
  },
  guide_to_freedom: {
    id: 'guide_to_freedom',
    name: 'Guide to Freedom',
    sortingPriority: 7,
    image: guide_to_freedom,
    rarity: 3
  },
  philosophies_of_freedom: {
    id: 'philosophies_of_freedom',
    name: 'Philosophies of Freedom',
    sortingPriority: 13,
    image: philosophies_of_freedom,
    rarity: 4
  },
  teachings_of_resistance: {
    id: 'teachings_of_resistance',
    name: 'Teachings of Resistance',
    sortingPriority: 2,
    image: teachings_of_resistance,
    rarity: 2
  },
  guide_to_resistance: {
    id: 'guide_to_resistance',
    name: 'Guide to Resistance',
    sortingPriority: 8,
    image: guide_to_resistance,
    rarity: 3
  },
  philosophies_of_resistance: {
    id: 'philosophies_of_resistance',
    name: 'Philosophies of Resistance',
    sortingPriority: 14,
    image: philosophies_of_resistance,
    rarity: 4
  },
  teachings_of_ballad: {
    id: 'teachings_of_ballad',
    name: 'Teachings of Ballad',
    sortingPriority: 3,
    image: teachings_of_ballad,
    rarity: 2
  },
  guide_to_ballad: {
    id: 'guide_to_ballad',
    name: 'Guide to Ballad',
    sortingPriority: 9,
    image: guide_to_ballad,
    rarity: 3
  },
  philosophies_of_ballad: {
    id: 'philosophies_of_ballad',
    name: 'Philosophies of Ballad',
    sortingPriority: 15,
    image: philosophies_of_ballad,
    rarity: 4
  },
  teachings_of_prosperity: {
    id: 'teachings_of_prosperity',
    name: 'Teachings of Prosperity',
    sortingPriority: 4,
    image: teachings_of_prosperity,
    rarity: 2
  },
  guide_to_prosperity: {
    id: 'guide_to_prosperity',
    name: 'Guide to Prosperity',
    sortingPriority: 10,
    image: guide_to_prosperity,
    rarity: 3
  },
  philosophies_of_prosperity: {
    id: 'philosophies_of_prosperity',
    name: 'Philosophies of Prosperity',
    sortingPriority: 16,
    image: philosophies_of_prosperity,
    rarity: 4
  },
  teachings_of_diligence: {
    id: 'teachings_of_diligence',
    name: 'Teachings of Diligence',
    sortingPriority: 5,
    image: teachings_of_diligence,
    rarity: 2
  },
  guide_to_diligence: {
    id: 'guide_to_diligence',
    name: 'Guide to Diligence',
    sortingPriority: 11,
    image: guide_to_diligence,
    rarity: 3
  },
  philosophies_of_diligence: {
    id: 'philosophies_of_diligence',
    name: 'Philosophies of Diligence',
    sortingPriority: 17,
    image: philosophies_of_diligence,
    rarity: 4
  },
  teachings_of_gold: {
    id: 'teachings_of_gold',
    name: 'Teachings of Gold',
    sortingPriority: 6,
    image: teachings_of_gold,
    rarity: 2
  },
  guide_to_gold: {
    id: 'guide_to_gold',
    name: 'Guide to Gold',
    sortingPriority: 12,
    image: guide_to_gold,
    rarity: 3
  },
  philosophies_of_gold: {
    id: 'philosophies_of_gold',
    name: 'Philosophies of Gold',
    sortingPriority: 18,
    image: philosophies_of_gold,
    rarity: 4
  },
  crown_of_insight: {
    id: 'crown_of_insight',
    name: 'Crown of Insight',
    sortingPriority: 401,
    image: crown_of_insight,
    rarity: 5
  },
  tail_of_boreas: {
    id: 'tail_of_boreas',
    name: 'Tail of Boreas',
    sortingPriority: 400,
    image: tail_of_boreas,
    rarity: 5
  },
  ring_of_boreas: {
    id: 'ring_of_boreas',
    name: 'Ring of Boreas',
    sortingPriority: 400,
    image: ring_of_boreas,
    rarity: 5
  },
  spirit_locket_of_boreas: {
    id: 'spirit_locket_of_boreas',
    name: 'Spirit Locket of Boreas',
    sortingPriority: 400,
    image: spirit_locket_of_boreas,
    rarity: 5
  },
  dvalins_plume: {
    id: 'dvalins_plume',
    name: 'Dvalin\'s Plume',
    sortingPriority: 400,
    image: dvalins_plume,
    rarity: 5
  },
  dvalins_claw: {
    id: 'dvalins_claw',
    name: 'Dvalin\'s Claw',
    sortingPriority: 400,
    image: dvalins_claw,
    rarity: 5
  },
  dvalins_sigh: {
    id: 'dvalins_sigh',
    name: 'Dvalin\'s Sigh',
    sortingPriority: 400,
    image: dvalins_sigh,
    rarity: 5
  },
  tusk_of_monoceros_caeli: {
    id: 'tusk_of_monoceros_caeli',
    name: 'Tusk of Monoceros Caeli',
    sortingPriority: 400,
    image: tusk_of_monoceros_caeli,
    rarity: 5
  },
  shard_of_a_foul_legacy: {
    id: 'shard_of_a_foul_legacy',
    name: 'Shard of a Foul Legacy',
    sortingPriority: 400,
    image: shard_of_a_foul_legacy,
    rarity: 5
  },
  shadow_of_the_warrior: {
    id: 'shadow_of_the_warrior',
    name: 'Shadow of the Warrior',
    sortingPriority: 400,
    image: shadow_of_the_warrior,
    rarity: 5
  },
  dragon_lords_crown: {
    id: 'dragon_lords_crown',
    name: 'Dragon Lord\'s Crown',
    sortingPriority: 400,
    image: dragon_lords_crown,
    rarity: 5
  },
  bloodjade_branch: {
    id: 'bloodjade_branch',
    name: 'Bloodjade Branch',
    sortingPriority: 400,
    image: bloodjade_branch,
    rarity: 5
  },
  enhancement_ore: {
    id: 'enhancement_ore',
    name: 'Enhancement Ore',
    sortingPriority: 1,
    image: enhancement_ore,
    rarity: 1
  },
  fine_enhancement_ore: {
    id: 'fine_enhancement_ore',
    name: 'Fine Enhancement Ore',
    sortingPriority: 2,
    image: fine_enhancement_ore,
    rarity: 2
  },
  mystic_enhancement_ore: {
    id: 'mystic_enhancement_ore',
    name: 'Mystic Enhancement Ore',
    sortingPriority: 3,
    image: mystic_enhancement_ore,
    rarity: 3
  },
  tile_of_decarabians_tower: {
    id: 'tile_of_decarabians_tower',
    name: 'Tile of Decarabian\'s Tower',
    sortingPriority: -1,
    image: tile_of_decarabians_tower,
    rarity: 2
  },
  debris_of_decarabians_city: {
    id: 'debris_of_decarabians_city',
    name: 'Debris of Decarabian\'s City',
    sortingPriority: -1,
    image: debris_of_decarabians_city,
    rarity: 3
  },
  fragment_of_decarabians_epic: {
    id: 'fragment_of_decarabians_epic',
    name: 'Fragment of Decarabian\'s Epic',
    sortingPriority: -1,
    image: fragment_of_decarabians_epic,
    rarity: 4
  },
  scattered_piece_of_decarabians_dream: {
    id: 'scattered_piece_of_decarabians_dream',
    name: 'Scattered Piece of Decarabian\'s Dream',
    sortingPriority: -1,
    image: scattered_piece_of_decarabians_dream,
    rarity: 5
  },
  boreal_wolfs_milk_tooth: {
    id: 'boreal_wolfs_milk_tooth',
    name: 'Boreal Wolf\'s Milk Tooth',
    sortingPriority: -1,
    image: boreal_wolfs_milk_tooth,
    rarity: 2
  },
  boreal_wolfs_cracked_tooth: {
    id: 'boreal_wolfs_cracked_tooth',
    name: 'Boreal Wolf\'s Cracked Tooth',
    sortingPriority: -1,
    image: boreal_wolfs_cracked_tooth,
    rarity: 3
  },
  boreal_wolfs_broken_fang: {
    id: 'boreal_wolfs_broken_fang',
    name: 'Boreal Wolf\'s Broken Fang',
    sortingPriority: -1,
    image: boreal_wolfs_broken_fang,
    rarity: 4
  },
  boreal_wolfs_nostalgia: {
    id: 'boreal_wolfs_nostalgia',
    name: 'Boreal Wolf\'s Nostalgia',
    sortingPriority: -1,
    image: boreal_wolfs_nostalgia,
    rarity: 5
  },
  fetters_of_the_dandelion_gladiator: {
    id: 'fetters_of_the_dandelion_gladiator',
    name: 'Fetters of the Dandelion Gladiator',
    sortingPriority: -1,
    image: fetters_of_the_dandelion_gladiator,
    rarity: 2
  },
  chains_of_the_dandelion_gladiator: {
    id: 'chains_of_the_dandelion_gladiator',
    name: 'Chains of the Dandelion Gladiator',
    sortingPriority: -1,
    image: chains_of_the_dandelion_gladiator,
    rarity: 3
  },
  shackles_of_the_dandelion_gladiator: {
    id: 'shackles_of_the_dandelion_gladiator',
    name: 'Shackles of the Dandelion Gladiator',
    sortingPriority: -1,
    image: shackles_of_the_dandelion_gladiator,
    rarity: 4
  },
  dream_of_the_dandelion_gladiator: {
    id: 'dream_of_the_dandelion_gladiator',
    name: 'Dream of the Dandelion Gladiator',
    sortingPriority: -1,
    image: dream_of_the_dandelion_gladiator,
    rarity: 5
  },
  luminous_sands_from_guyun: {
    id: 'luminous_sands_from_guyun',
    name: 'Luminous Sands from Guyun',
    sortingPriority: -1,
    image: luminous_sands_from_guyun,
    rarity: 2
  },
  lustrous_stone_from_guyun: {
    id: 'lustrous_stone_from_guyun',
    name: 'Lustrous Stone from Guyun',
    sortingPriority: -1,
    image: lustrous_stone_from_guyun,
    rarity: 3
  },
  relic_from_guyun: {
    id: 'relic_from_guyun',
    name: 'Relic from Guyun',
    sortingPriority: -1,
    image: relic_from_guyun,
    rarity: 4
  },
  divine_body_from_guyun: {
    id: 'divine_body_from_guyun',
    name: 'Divine Body From Guyun',
    sortingPriority: -1,
    image: divine_body_from_guyun,
    rarity: 5
  },
  mist_veiled_lead_elixir: {
    id: 'mist_veiled_lead_elixir',
    name: 'Mist Veiled Lead Elixir',
    sortingPriority: -1,
    image: mist_veiled_lead_elixir,
    rarity: 2
  },
  mist_veiled_mercury_elixir: {
    id: 'mist_veiled_mercury_elixir',
    name: 'Mist Veiled Mercury Elixir',
    sortingPriority: -1,
    image: mist_veiled_mercury_elixir,
    rarity: 3
  },
  mist_veiled_gold_elixir: {
    id: 'mist_veiled_gold_elixir',
    name: 'Mist Veiled Gold Elixir',
    sortingPriority: -1,
    image: mist_veiled_gold_elixir,
    rarity: 4
  },
  mist_veiled_primo_elixir: {
    id: 'mist_veiled_primo_elixir',
    name: 'Mist Veiled Primo Elixir',
    sortingPriority: -1,
    image: mist_veiled_primo_elixir,
    rarity: 5
  },
  grain_of_aerosiderite: {
    id: 'grain_of_aerosiderite',
    name: 'Grain of Aerosiderite',
    sortingPriority: -1,
    image: grain_of_aerosiderite,
    rarity: 2
  },
  piece_of_aerosiderite: {
    id: 'piece_of_aerosiderite',
    name: 'Piece of Aerosiderite',
    sortingPriority: -1,
    image: piece_of_aerosiderite,
    rarity: 3
  },
  bit_of_aerosiderite: {
    id: 'bit_of_aerosiderite',
    name: 'Bit of Aerosiderite',
    sortingPriority: -1,
    image: bit_of_aerosiderite,
    rarity: 4
  },
  chunk_of_aerosiderite: {
    id: 'chunk_of_aerosiderite',
    name: 'Chunk of Aerosiderite',
    sortingPriority: -1,
    image: chunk_of_aerosiderite,
    rarity: 5
  },
  slime_condensate: {
    id: 'slime_condensate',
    name: 'Slime Condensate',
    sortingPriority: 200,
    image: slime_condensate,
    rarity: 1
  },
  slime_secretions: {
    id: 'slime_secretions',
    name: 'Slime Secretions',
    sortingPriority: 201,
    image: slime_secretions,
    rarity: 2
  },
  slime_concentrate: {
    id: 'slime_concentrate',
    name: 'Slime Concentrate',
    sortingPriority: 202,
    image: slime_concentrate,
    rarity: 3
  },
  damaged_mask: {
    id: 'damaged_mask',
    name: 'Damaged Mask',
    sortingPriority: 200,
    image: damaged_mask,
    rarity: 1
  },
  stained_mask: {
    id: 'stained_mask',
    name: 'Stained Mask',
    sortingPriority: 201,
    image: stained_mask,
    rarity: 2
  },
  ominous_mask: {
    id: 'ominous_mask',
    name: 'Ominous Mask',
    sortingPriority: 202,
    image: ominous_mask,
    rarity: 3
  },
  firm_arrowhead: {
    id: 'firm_arrowhead',
    name: 'Firm Arrowhead',
    sortingPriority: 200,
    image: firm_arrowhead,
    rarity: 1
  },
  sharp_arrowhead: {
    id: 'sharp_arrowhead',
    name: 'Sharp Arrowhead',
    sortingPriority: 201,
    image: sharp_arrowhead,
    rarity: 2
  },
  weathered_arrowhead: {
    id: 'weathered_arrowhead',
    name: 'Weathered Arrowhead',
    sortingPriority: 202,
    image: weathered_arrowhead,
    rarity: 3
  },
  divining_scroll: {
    id: 'divining_scroll',
    name: 'Divining Scroll',
    sortingPriority: 200,
    image: divining_scroll,
    rarity: 1
  },
  sealed_scroll: {
    id: 'sealed_scroll',
    name: 'Sealed Scroll',
    sortingPriority: 201,
    image: sealed_scroll,
    rarity: 2
  },
  forbidden_curse_scroll: {
    id: 'forbidden_curse_scroll',
    name: 'Forbidden Curse Scroll',
    sortingPriority: 202,
    image: forbidden_curse_scroll,
    rarity: 3
  },
  treasure_hoarder_insignia: {
    id: 'treasure_hoarder_insignia',
    name: 'Treasure Hoarder Insignia',
    sortingPriority: 200,
    image: treasure_hoarder_insignia,
    rarity: 1
  },
  silver_raven_insignia: {
    id: 'silver_raven_insignia',
    name: 'Silver Raven Insignia',
    sortingPriority: 201,
    image: silver_raven_insignia,
    rarity: 2
  },
  golden_raven_insignia: {
    id: 'golden_raven_insignia',
    name: 'Golden Raven Insignia',
    sortingPriority: 202,
    image: golden_raven_insignia,
    rarity: 3
  },
  recruits_insignia: {
    id: 'recruits_insignia',
    name: 'Recruit\'s Insignia',
    sortingPriority: 200,
    image: recruits_insignia,
    rarity: 1
  },
  sergeants_insignia: {
    id: 'sergeants_insignia',
    name: 'Sergeant\'s Insignia',
    sortingPriority: 201,
    image: sergeants_insignia,
    rarity: 2
  },
  lieutenants_insignia: {
    id: 'lieutenants_insignia',
    name: 'Lieutenant\'s Insignia',
    sortingPriority: 202,
    image: lieutenants_insignia,
    rarity: 3
  },
  whopperflower_nectar: {
    id: 'whopperflower_nectar',
    name: 'Whopperflower Nectar',
    sortingPriority: 200,
    image: whopperflower_nectar,
    rarity: 1
  },
  shimmering_nectar: {
    id: 'shimmering_nectar',
    name: 'Shimmering Nectar',
    sortingPriority: 201,
    image: shimmering_nectar,
    rarity: 2
  },
  energy_nectar: {
    id: 'energy_nectar',
    name: 'Energy Nectar',
    sortingPriority: 202,
    image: energy_nectar,
    rarity: 3
  },
  heavy_horn: {
    id: 'heavy_horn',
    name: 'Heavy Horn',
    sortingPriority: 300,
    image: heavy_horn,
    rarity: 2
  },
  black_bronze_horn: {
    id: 'black_bronze_horn',
    name: 'Black Bronze Horn',
    sortingPriority: 301,
    image: black_bronze_horn,
    rarity: 3
  },
  black_crystal_horn: {
    id: 'black_crystal_horn',
    name: 'Black Crystal Horn',
    sortingPriority: 302,
    image: black_crystal_horn,
    rarity: 4
  },
  dead_ley_line_branch: {
    id: 'dead_ley_line_branch',
    name: 'Dead Ley Line Branch',
    sortingPriority: 300,
    image: dead_ley_line_branch,
    rarity: 2
  },
  dead_ley_line_leaves: {
    id: 'dead_ley_line_leaves',
    name: 'Dead Ley Line Leaves',
    sortingPriority: 301,
    image: dead_ley_line_leaves,
    rarity: 3
  },
  ley_line_sprouts: {
    id: 'ley_line_sprouts',
    name: 'Ley Line Sprouts',
    sortingPriority: 302,
    image: ley_line_sprouts,
    rarity: 4
  },
  fragile_bone_shard: {
    id: 'fragile_bone_shard',
    name: 'Fragile Bone Shard',
    sortingPriority: 300,
    image: fragile_bone_shard,
    rarity: 2
  },
  sturdy_bone_shard: {
    id: 'sturdy_bone_shard',
    name: 'Sturdy Bone Shard',
    sortingPriority: 301,
    image: sturdy_bone_shard,
    rarity: 3
  },
  fossilized_bone_shard: {
    id: 'fossilized_bone_shard',
    name: 'Fossilized Bone Shard',
    sortingPriority: 302,
    image: fossilized_bone_shard,
    rarity: 4
  },
  mist_grass_pollen: {
    id: 'mist_grass_pollen',
    name: 'Mist Grass Pollen',
    sortingPriority: 300,
    image: mist_grass_pollen,
    rarity: 2
  },
  mist_grass: {
    id: 'mist_grass',
    name: 'Mist Grass',
    sortingPriority: 301,
    image: mist_grass,
    rarity: 3
  },
  mist_grass_wick: {
    id: 'mist_grass_wick',
    name: 'Mist Grass Wick',
    sortingPriority: 302,
    image: mist_grass_wick,
    rarity: 4
  },
  hunters_sacrificial_knife: {
    id: 'hunters_sacrificial_knife',
    name: 'Hunter\'s Sacrificial Knife',
    sortingPriority: 300,
    image: hunters_sacrificial_knife,
    rarity: 2
  },
  agents_sacrificial_knife: {
    id: 'agents_sacrificial_knife',
    name: 'Agent\'s Sacrificial Knife',
    sortingPriority: 301,
    image: agents_sacrificial_knife,
    rarity: 3
  },
  inspectors_sacrificial_knife: {
    id: 'inspectors_sacrificial_knife',
    name: 'Inspector\'s Sacrificial Knife',
    sortingPriority: 302,
    image: inspectors_sacrificial_knife,
    rarity: 4
  },
  chaos_device: {
    id: 'chaos_device',
    name: 'Chaos Device',
    sortingPriority: 300,
    image: chaos_device,
    rarity: 2
  },
  chaos_circuit: {
    id: 'chaos_circuit',
    name: 'Chaos Circuit',
    sortingPriority: 301,
    image: chaos_circuit,
    rarity: 3
  },
  chaos_core: {
    id: 'chaos_core',
    name: 'Chaos Core',
    sortingPriority: 302,
    image: chaos_core,
    rarity: 4
  },
  calla_lily: {
    id: 'calla_lily',
    name: 'Calla Lily',
    sortingPriority: 100,
    image: calla_lily,
    rarity: -1
  },
  cecilia: {
    id: 'cecilia',
    name: 'Cecilia',
    sortingPriority: 100,
    image: cecilia,
    rarity: -1
  },
  cor_lapis: {
    id: 'cor_lapis',
    name: 'Cor Lapis',
    sortingPriority: 100,
    image: cor_lapis,
    rarity: -1
  },
  dandelion_seed: {
    id: 'dandelion_seed',
    name: 'Dandelion Seed',
    sortingPriority: 100,
    image: dandelion_seed,
    rarity: -1
  },
  glaze_lily: {
    id: 'glaze_lily',
    name: 'Glaze Lily',
    sortingPriority: 100,
    image: glaze_lily,
    rarity: -1
  },
  jueyun_chili: {
    id: 'jueyun_chili',
    name: 'Jueyun Chili',
    sortingPriority: 100,
    image: jueyun_chili,
    rarity: -1
  },
  noctilucous_jade: {
    id: 'noctilucous_jade',
    name: 'Noctilucous Jade',
    sortingPriority: 100,
    image: noctilucous_jade,
    rarity: -1
  },
  philanemo_mushroom: {
    id: 'philanemo_mushroom',
    name: 'Philanemo Mushroom',
    sortingPriority: 100,
    image: philanemo_mushroom,
    rarity: -1
  },
  qingxin: {
    id: 'qingxin',
    name: 'Qingxin',
    sortingPriority: 100,
    image: qingxin,
    rarity: -1
  },
  silk_flower: {
    id: 'silk_flower',
    name: 'Silk Flower',
    sortingPriority: 100,
    image: silk_flower,
    rarity: -1
  },
  small_lamp_grass: {
    id: 'small_lamp_grass',
    name: 'Small Lamp Grass',
    sortingPriority: 100,
    image: small_lamp_grass,
    rarity: -1
  },
  starconch: {
    id: 'starconch',
    name: 'Starconch',
    sortingPriority: 100,
    image: starconch,
    rarity: -1
  },
  valberry: {
    id: 'valberry',
    name: 'Valberry',
    sortingPriority: 100,
    image: valberry,
    rarity: -1
  },
  violetgrass: {
    id: 'violetgrass',
    name: 'Violetgrass',
    sortingPriority: 100,
    image: violetgrass,
    rarity: -1
  },
  windwheel_aster: {
    id: 'windwheel_aster',
    name: 'Windwheel Aster',
    sortingPriority: 100,
    image: windwheel_aster,
    rarity: -1
  },
  wolfhook: {
    id: 'wolfhook',
    name: 'Wolfhook',
    sortingPriority: 100,
    image: wolfhook,
    rarity: -1
  },
}

export default ITEM_DATA
