const WEAPON_ASCENSION_MATERIAL_MAP = {
  DECARABIAN: {
    DOMAIN_MAT_1: 'tile_of_decarabians_tower',
    DOMAIN_MAT_2: 'debris_of_decarabians_city',
    DOMAIN_MAT_3: 'fragment_of_decarabians_epic',
    DOMAIN_MAT_4: 'scattered_piece_of_decarabians_dream',
  },
  TOOTH: {
    DOMAIN_MAT_1: 'boreal_wolfs_milk_tooth',
    DOMAIN_MAT_2: 'boreal_wolfs_cracked_tooth',
    DOMAIN_MAT_3: 'boreal_wolfs_broken_fang',
    DOMAIN_MAT_4: 'boreal_wolfs_nostalgia',
  },
  GLADIATOR: {
    DOMAIN_MAT_1: 'fetters_of_the_dandelion_gladiator',
    DOMAIN_MAT_2: 'chains_of_the_dandelion_gladiator',
    DOMAIN_MAT_3: 'shackles_of_the_dandelion_gladiator',
    DOMAIN_MAT_4: 'dream_of_the_dandelion_gladiator',
  },
  GUYUN: {
    DOMAIN_MAT_1: 'luminous_sands_from_guyun',
    DOMAIN_MAT_2: 'lustrous_stone_from_guyun',
    DOMAIN_MAT_3: 'relic_from_guyun',
    DOMAIN_MAT_4: 'divine_body_from_guyun',
  },
  ELIXIR: {
    DOMAIN_MAT_1: 'mist_veiled_lead_elixir',
    DOMAIN_MAT_2: 'mist_veiled_mercury_elixir',
    DOMAIN_MAT_3: 'mist_veiled_gold_elixir',
    DOMAIN_MAT_4: 'mist_veiled_primo_elixir',
  },
  AEROSIDERITE: {
    DOMAIN_MAT_1: 'grain_of_aerosiderite',
    DOMAIN_MAT_2: 'piece_of_aerosiderite',
    DOMAIN_MAT_3: 'bit_of_aerosiderite',
    DOMAIN_MAT_4: 'chunk_of_aerosiderite',
  },
}

export default WEAPON_ASCENSION_MATERIAL_MAP
